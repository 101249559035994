<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item active" aria-current="page" v-if="RatingData.Rating">{{ RatingData.Rating.RatingName
          }}</li>
      </ol>
    </div>

    <div class="wrapper restaurant_rating" v-if="RatingData.Rating">
      <section class="main">
        <div class="container">
          <div class="box">
            <h1>{{ RatingData.Rating.RatingName }}</h1>
            <div class="text_box show">
              <h2>評分期間</h2>
              <div class="period" v-html="RatingData.LimitTime">
              </div>
              <!-- <hr> -->
              <h2>評分方式</h2>
              <div class="way" v-if="RatingData.Rating.RatingType == 5">
                <p><span class="circle">1分</span>(極度不推薦)</p>
                <p><span class="circle">2分</span>(不推薦)</p>
                <p><span class="circle">3分</span>(推薦)</p>
                <p><span class="circle">4分</span>(非常推薦)</p>
                <p><span class="circle">5分</span>(極度推薦)</p>
              </div>
              <div class="way" v-if="RatingData.Rating.RatingType == 100">
                <p><span class="circle">0-100分</span>(評分制)</p>
              </div>
              <!-- <hr> -->
              <h2>評分重點</h2>
              <div class="text" v-html="RatingData.Rating.RatingRule">
              </div>
              <!-- <hr> -->
              <div class="qa" v-if="RatingData.Rating.ContactInfo">
                <span>？</span>
                <p>諮詢專線</p>{{ RatingData.Rating.ContactInfo }}
              </div>
              <hr>
              <div class="score_box">
                <h2>參加徵選業者</h2>
                <!-- 20240227 1-100分評分 -->
                <ul class="score_number" v-if="RatingData.Rating.RatingType == 100">
                  <li>
                    <p class="circle">未評分</p>
                    <p><span>{{ RatingData.Source_N }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">已評分</p>
                    <p><span>{{ RatingData.Source_Y }}</span>家</p>
                  </li>
                </ul>
                <!-- 1-5分評分 -->
                <ul class="score_number" v-if="RatingData.Rating.RatingType == 5">
                  <li>
                    <p class="circle">未評分</p>
                    <p><span>{{ RatingData.Source_N }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">1分</p>
                    <p><span>{{ RatingData.Source_1 }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">2分</p>
                    <p><span>{{ RatingData.Source_2 }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">3分</p>
                    <p><span>{{ RatingData.Source_3 }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">4分</p>
                    <p><span>{{ RatingData.Source_4 }}</span>家</p>
                  </li>
                  <li>
                    <p class="circle">5分</p>
                    <p><span>{{ RatingData.Source_5 }}</span>家</p>
                  </li>
                </ul>
                <div class="btn_box">
                  <button :class="SortType == 1 ? 'active' : ''" @click="SortType = 1; loadList(1)">依筆劃排序</button>
                  <button :class="SortType == 2 ? 'active' : ''" @click="SortType = 2; loadList(1)">依分數高低排序</button>
                  <button :class="SortType == 3 ? 'active' : ''" @click="SortType = 3; loadList(1)">依尚未評分排序</button>
                </div>
                <div class="store_main" style="display: block;">
                  <ul class="row list">
                    <li class="col-12 col-md-6 col-lg-4" v-for="(User, index) in Users">
                      <a href="javascript:void(0)" @click="gotoRatingDetail(RatingData.Rating.RId, User.URId)">
                        <div class="title">
                          <p>{{ User.Name }}</p>
                          <div class="tag"
                            v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource && User.RatingUserSource.Score != 0">
                            <p v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource.Score == 1">
                              極度不推薦<span>{{ User.RatingUserSource.Score }}</span></p>
                            <p v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource.Score == 2">不推薦<span>{{
                              User.RatingUserSource.Score }}</span></p>
                            <p v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource.Score == 3">推薦<span>{{
                              User.RatingUserSource.Score }}</span></p>
                            <p v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource.Score == 4">非常推薦<span>{{
                              User.RatingUserSource.Score }}</span></p>
                            <p v-if="RatingData.Rating.RatingType == 5 && User.RatingUserSource.Score == 5">極度推薦<span>{{
                              User.RatingUserSource.Score }}</span></p>
                          </div>
                          <div class="tag" v-if="RatingData.Rating.RatingType == 100 && User.RatingUserSource">
                            <p v-if="RatingData.Rating.RatingType == 100">分數<span>{{ User.RatingUserSource.Score
                                }}</span></p>
                          </div>
                        </div>
                        <div class="carousel">
                          <div class="">
                            <div class="img_box">
                              <img v-if="!User.UserInfo || !User.UserInfo.P1_FacadePhoto" src="/img/sample-2.png"
                                alt="">
                              <img v-else :src="RatingData.SiteUrl + User.UserInfo.P1_FacadePhoto" alt="">
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                    :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                    :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                    :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
                  </paginate>
                  <!-- <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                        ＜
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        ＞
                      </a>
                    </li>
                  </ul> -->
                </div>
              </div>
              <hr>
              <div class="btn_box" v-if="!RatingData.RatingReviewerRelated.IsSended">
                <button @click="sendRating()">送出評分，不再修改</button>
              </div>
              <p>{{ RatingData.BottomShowTime }}</p>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Rating",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      RatingData: {},
      Users: null,
      pageAll: 0,
      perPage: 9,
      SortType: 1,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Rating",
        {
          params: { RId: to.params.RId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.RatingData = response.data;
        if (!this.RatingData.User || !this.RatingData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Rating",
        {
          params: { RId: this.$route.params.RId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.RatingData = response.data;
        if (!this.RatingData.User || !this.RatingData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/RatingList",
        type: "post",
        data: {
          RId: this.$route.params.RId,
          SortType: this.SortType,
          token: $cookies.get("is_login_token"),
          page: page,
          perPage: that.perPage
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.Users = res.Users;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoRatingDetail: function (RId, URId) {
      // if (this.RatingData.RatingReviewerRelated.IsSended) {
      //   Swal.fire({
      //     title: "錯誤",
      //     text: "評分已送出，無法再進行修改",
      //     icon: "error",
      //     allowOutsideClick: false,
      //     confirmButtonText: "確定",
      //     heightAuto: false,
      //   });
      //   return;
      // }
      // this.$router.push({
      //   name: "RatingDetail",
      //   params: { RId: RId, URId: URId },
      // });
      var url = this.$router.resolve({
        name: "RatingDetail",
        params: { RId: RId, URId: URId },
      });
      window.open(url.href, '_blank');
    },
    sendRating() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出評分後將無法再進行修改，是否確認送出評分結果?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendRating",
              type: "post",
              data: {
                RId: this.RatingData.Rating.RId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
