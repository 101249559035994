<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb" v-if="EventItemDetailData">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="EventItemDetailData.Event">{{ EventItemDetailData.Event.Name }}</li>
        <li class="breadcrumb-item active" v-if="EventItemDetailData.EventItem" aria-current="page">{{
          EventItemDetailData.EventItem.Name }}</li>
      </ol>
    </div>
    <div
      class="wrapper member_information member_information_1_main member_information_1_main_2 member_information_1_main_4"
      v-if="EventItemDetailData.EventItemDetail">
      <section class="main">
        <div class="container">
          <div class="step_box">
            <div class="" @click="goto('BaseInfo')" style="cursor: pointer;">基本資料</div>
            <div class="step">品項管理</div>
            <div class="" @click="goto('ReqDoc')" style="cursor: pointer;">應備文件</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 0" class="" @click="sendReview()"
              style="cursor: pointer;">送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 3" class="" @click="sendReview()"
              style="cursor: pointer;">重新送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <div id="stet_1" class="box show">
            <h1>
              鍋物甄選
              <div class="btn_home">
                <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                  tag="button">返回列表</router-link>
              </div>
            </h1>
            <div class="bg">
              <div class="form-group project full">
                <label for="">報名組別(請選擇1項)</label>
                <p class="">請依本次參選鍋物形式自行勾選1項：不限於經營型態</p>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_Group_1" v-bind:value="1"
                      v-model="EventItemDetailData.EventItemDetail.T4_Group">
                    <label class="form-check-label" for="T4_Group_1">
                      單店經營店
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_Group_2" v-bind:value="2"
                      v-model="EventItemDetailData.EventItemDetail.T4_Group">
                    <label class="form-check-label" for="T4_Group_2">
                      連鎖品牌組
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <h1>參選鍋物</h1>
            <div class="form-group full content">
              <h2 class="form-group full">報名品項，須有於店面販售</h2>
              <div class="form-group">
                <label for=""><span>*</span>鍋物名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SoupName"
                  data-required="1" data-required-msg="請填寫鍋物名稱" aria-describedby="" placeholder="請填寫鍋物名稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>價格</label>
                <div class="flex_box">
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_SoupPrice">
                  <p>元</p>
                </div>
              </div>
              <div class="form-group project full">
                <label for="">湯頭種類</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_SoupType_1" v-bind:value="1"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupType">
                    <label class="form-check-label" for="T4_SoupType_1">
                      清湯(如：昆布湯等，可見鍋底之湯頭)
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_SoupType_2" v-bind:value="2"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupType">
                    <label class="form-check-label" for="T4_SoupType_2">
                      白湯(如：大骨湯、牛奶鍋等)
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_SoupType_3" v-bind:value="3"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupType">
                    <label class="form-check-label" for="T4_SoupType_3">
                      紅湯(如：麻辣火鍋等)
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_SoupType_5" v-bind:value="5"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupType">
                    <label class="form-check-label" for="T4_SoupType_5">
                      乾鍋
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T4_SoupType_4" v-bind:value="4"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupType">
                    <label class="form-check-label" for="T4_SoupType_4">
                      其他
                    </label>
                    <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_SoupType == 4"
                      v-model="EventItemDetailData.EventItemDetail.T4_SoupTypeContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_SoupType == 4 ? '1' : '0'"
                      data-required-msg="請填寫其他湯頭種類" aria-describedby="" placeholder="請填寫其他湯頭種類">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for=""><span>*</span>湯頭種類名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SoupTypeName"
                  data-required="1" data-required-msg="請填寫湯頭種類名稱" aria-describedby="" placeholder="請填寫湯頭種類名稱">
              </div>
              <div class="form-group full pass">
                <label for="">鍋物型態</label>
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_PersonalPotType"
                      v-model="EventItemDetailData.EventItemDetail.T4_PersonalPotType">
                    <label class="form-check-label" for="T4_PersonalPotType">個人鍋</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_SharedPotType"
                      v-model="EventItemDetailData.EventItemDetail.T4_SharedPotType">
                    <label class="form-check-label" for="T4_SharedPotType">多人共鍋</label>
                  </div>
                </div>
              </div>
              <div class="form-group full pass"
                v-show="EventItemDetailData.EventItemDetail.T4_PersonalPotType || EventItemDetailData.EventItemDetail.T4_SharedPotType">
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_AllYouCanEatPotType"
                      v-model="EventItemDetailData.EventItemDetail.T4_AllYouCanEatPotType">
                    <label class="form-check-label" for="T4_AllYouCanEatPotType">吃到飽</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_AlaCartePotType"
                      v-model="EventItemDetailData.EventItemDetail.T4_AlaCartePotType">
                    <label class="form-check-label" for="T4_AlaCartePotType">單點</label>
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>鍋物特色說明，100字以內(說明鍋物創意或特色、主要食材、湯底及鍋底料內容介紹等)</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T4_PotFeaturesDesc" data-required="1"
                  data-required-msg="請填寫鍋物特色說明" aria-describedby="" placeholder="請填寫鍋物特色說明"></textarea>
              </div>
              <div class="form-group full pass">
                <label for="">鍋物湯底及鍋底調味已符合食安法，消費者能明確看到製作標示</label>
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_FitFSLaw"
                      v-model="EventItemDetailData.EventItemDetail.T4_FitFSLaw">
                    <label class="form-check-label" for="T4_FitFSLaw">是</label>
                  </div>
                </div>
              </div>
              <div class="form-group full pass" v-if="EventItemDetailData.EventItemDetail.T4_FitFSLaw">
                <label for="">標示位置</label>
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_FitFSLawMenu"
                      v-model="EventItemDetailData.EventItemDetail.T4_FitFSLawMenu">
                    <label class="form-check-label" for="T4_FitFSLawMenu">菜單</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_FitFSLawBoard"
                      v-model="EventItemDetailData.EventItemDetail.T4_FitFSLawBoard">
                    <label class="form-check-label" for="T4_FitFSLawBoard">看板</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_FitFSLawOther"
                      v-model="EventItemDetailData.EventItemDetail.T4_FitFSLawOther">
                    <label class="form-check-label" for="T4_FitFSLawOther">其他</label>
                    <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_FitFSLawOther"
                      v-model="EventItemDetailData.EventItemDetail.T4_FitFSLawOtherContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_FitFSLawOther ? '1' : '0'"
                      data-required-msg="請填寫其他製作標示位置" aria-describedby="" placeholder="請填寫其他製作標示位置">
                  </div>
                </div>
              </div>
              <div class="form-group file_box full">
                <label for=""><span>*</span>鍋物照片-1</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_PotPhoto.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_PotPhoto"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T4_PotPhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T4_PotPhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_PotPhoto" id="T4_PotPhoto_data"
                    data-required="1" data-required-msg="請上傳鍋物照片-1" />
                  <button>上傳</button>
                </div>
                <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
              </div>
              <div class="form-group file_box full">
                <label for=""><span>*</span>鍋物照片-2</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_PotPhoto2.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_PotPhoto2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T4_PotPhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T4_PotPhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_PotPhoto2" id="T4_PotPhoto2_data"
                    data-required="1" data-required-msg="請上傳鍋物照片-2" />
                  <button>上傳</button>
                </div>
                <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
              </div>
            </div>
            <h1>特色食材</h1>
            <div class="form-group full content">
              <h2 class="form-group full">請依參選鍋物所搭配原型或加工食物填寫，若為組合盤須分開各食材填列；須有於店面販售</h2>
              <h2 class="form-group full">至少1項，至多5項</h2>
              <p class="">例：牛肉：超級好吃霜降牛肉組；1份150g；500元</p>
              <div class="aa form-group full">
                <div class="form-group">
                  <label for="">食材</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_Ingredient"
                    placeholder="請填寫食材">
                </div>
                <div class="form-group">
                  <label for="">販賣名稱</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SellingName"
                    placeholder="請填寫販賣名稱">
                </div>
                <div class="form-group">
                  <label for="">份量</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Amount">
                    <p>份</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Gram">
                    <p>g</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">價格</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Price">
                    <p>元</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">食材產地來源</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_FMAOrigin"
                    placeholder="請填寫食材產地來源">
                </div>
                <div class="form-group">
                  <label for="">在地食材使用占比</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_LocProdRatio">
                </div>
                <div class="form-group full pass">
                  <label for="">若食材為肉品參選，請勾選</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GMFood"
                        v-model="EventItemDetailData.EventItemDetail.T4_GMFood">
                      <label class="form-check-label" for="T4_GMFood">基改食品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ReorgMeat"
                        v-model="EventItemDetailData.EventItemDetail.T4_ReorgMeat">
                      <label class="form-check-label" for="T4_ReorgMeat">重組肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProcessMeat"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProcessMeat">
                      <label class="form-check-label" for="T4_ProcessMeat">加工肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherMeat"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeat">
                      <label class="form-check-label" for="T4_OtherMeat">其他</label>
                      <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_OtherMeat"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeatContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherMeat ? '1' : '0'"
                        data-required-msg="請填寫其他肉品" aria-describedby="" placeholder="請填寫其他肉品">
                    </div>
                  </div>
                </div>
                <div class="form-group full pass">
                  <label for="">是否具有相關檢驗與標章</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_IngredientOrigin"
                        v-model="EventItemDetailData.EventItemDetail.T4_IngredientOrigin">
                      <label class="form-check-label" for="T4_IngredientOrigin">食材產地來源</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueTest"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueTest">
                      <label class="form-check-label" for="T4_AgriResidueTest">農業殘留檢驗</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionHistory"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionHistory">
                      <label class="form-check-label" for="T4_ProductionHistory">生產履歷</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProduct"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProduct">
                      <label class="form-check-label" for="T4_HighQualityAgriProduct">優良農產品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabelAndCert"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelAndCert">
                      <label class="form-check-label" for="T4_FoodLabelAndCert">食品標章及認證</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherCertification"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertification">
                      <label class="form-check-label" for="T4_OtherCertification">其他</label>
                      <input type="text" class="form-control"
                        v-if="EventItemDetailData.EventItemDetail.T4_OtherCertification"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherCertification ? '1' : '0'"
                        data-required-msg="請填寫其他認證" aria-describedby="" placeholder="請填寫其他認證">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_NoneCertification"
                        v-model="EventItemDetailData.EventItemDetail.T4_NoneCertification">
                      <label class="form-check-label" for="T4_NoneCertification">無</label>
                    </div>
                  </div>
                </div>
                <div class="form-group file_box full">
                  <label for="">食材照片</label>
                  <img
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_IngredientPhoto.includes('Upload')"
                    :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_IngredientPhoto"
                    style="width: 300px; height: auto;">
                  <div class="file">
                    <input type="file" id="T4_IngredientPhoto"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="T4_IngredientPhoto_name" data-namgtag="1" />
                    <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_IngredientPhoto"
                      id="T4_IngredientPhoto_data" />
                    <button>上傳</button>
                  </div>
                  <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
                </div>
              </div>
              <hr>
              <div class="aa form-group full">
                <div class="form-group">
                  <label for="">食材</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_Ingredient2"
                    placeholder="請填寫食材">
                </div>
                <div class="form-group">
                  <label for="">販賣名稱</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SellingName2"
                    placeholder="請填寫販賣名稱">
                </div>
                <div class="form-group">
                  <label for="">份量</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Amount2">
                    <p>份</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Gram2">
                    <p>g</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">價格</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Price2">
                    <p>元</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">食材產地來源</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_FMAOrigin2"
                    placeholder="請填寫食材產地來源">
                </div>
                <div class="form-group">
                  <label for="">在地食材使用占比</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_LocProdRatio2">
                </div>
                <div class="form-group full pass">
                  <label for="">若食材為肉品參選，請勾選</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GMFood2"
                        v-model="EventItemDetailData.EventItemDetail.T4_GMFood2">
                      <label class="form-check-label" for="T4_GMFood2">基改食品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ReorgMeat2"
                        v-model="EventItemDetailData.EventItemDetail.T4_ReorgMeat2">
                      <label class="form-check-label" for="T4_ReorgMeat2">重組肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProcessMeat2"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProcessMeat2">
                      <label class="form-check-label" for="T4_ProcessMeat2">加工肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherMeat2"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeat2">
                      <label class="form-check-label" for="T4_OtherMeat2">其他</label>
                      <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_OtherMeat2"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeatContent2"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherMeat2 ? '1' : '0'"
                        data-required-msg="請填寫其他肉品" aria-describedby="" placeholder="請填寫其他肉品">
                    </div>
                  </div>
                </div>
                <div class="form-group full pass">
                  <label for="">是否具有相關檢驗與標章</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_IngredientOrigin2"
                        v-model="EventItemDetailData.EventItemDetail.T4_IngredientOrigin2">
                      <label class="form-check-label" for="T4_IngredientOrigin2">食材產地來源</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueTest2"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueTest2">
                      <label class="form-check-label" for="T4_AgriResidueTest2">農業殘留檢驗</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionHistory2"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionHistory2">
                      <label class="form-check-label" for="T4_ProductionHistory2">生產履歷</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProduct2"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProduct2">
                      <label class="form-check-label" for="T4_HighQualityAgriProduct2">優良農產品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabelAndCert2"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelAndCert2">
                      <label class="form-check-label" for="T4_FoodLabelAndCert2">食品標章及認證</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherCertification2"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertification2">
                      <label class="form-check-label" for="T4_OtherCertification2">其他</label>
                      <input type="text" class="form-control"
                        v-if="EventItemDetailData.EventItemDetail.T4_OtherCertification2"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationContent2"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherCertification2 ? '1' : '0'"
                        data-required-msg="請填寫其他認證" aria-describedby="" placeholder="請填寫其他認證">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_NoneCertification2"
                        v-model="EventItemDetailData.EventItemDetail.T4_NoneCertification2">
                      <label class="form-check-label" for="T4_NoneCertification2">無</label>
                    </div>
                  </div>
                </div>
                <div class="form-group file_box full">
                  <label for="">食材照片</label>
                  <img
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_IngredientPhoto2.includes('Upload')"
                    :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_IngredientPhoto2"
                    style="width: 300px; height: auto;">
                  <div class="file">
                    <input type="file" id="T4_IngredientPhoto2"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="T4_IngredientPhoto2_name" data-namgtag="1" />
                    <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_IngredientPhoto2"
                      id="T4_IngredientPhoto2_data" />
                    <button>上傳</button>
                  </div>
                  <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
                </div>
              </div>
              <hr>
              <div class="aa form-group full">
                <div class="form-group">
                  <label for="">食材</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_Ingredient3"
                    placeholder="請填寫食材">
                </div>
                <div class="form-group">
                  <label for="">販賣名稱</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SellingName3"
                    placeholder="請填寫販賣名稱">
                </div>
                <div class="form-group">
                  <label for="">份量</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Amount3">
                    <p>份</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Gram3">
                    <p>g</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">價格</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Price3">
                    <p>元</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">食材產地來源</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_FMAOrigin3"
                    placeholder="請填寫食材產地來源">
                </div>
                <div class="form-group">
                  <label for="">在地食材使用占比</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_LocProdRatio3">
                </div>
                <div class="form-group full pass">
                  <label for="">若食材為肉品參選，請勾選</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GMFood3"
                        v-model="EventItemDetailData.EventItemDetail.T4_GMFood3">
                      <label class="form-check-label" for="T4_GMFood3">基改食品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ReorgMeat3"
                        v-model="EventItemDetailData.EventItemDetail.T4_ReorgMeat3">
                      <label class="form-check-label" for="T4_ReorgMeat3">重組肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProcessMeat3"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProcessMeat3">
                      <label class="form-check-label" for="T4_ProcessMeat3">加工肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherMeat3"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeat3">
                      <label class="form-check-label" for="T4_OtherMeat3">其他</label>
                      <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_OtherMeat3"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeatContent3"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherMeat3 ? '1' : '0'"
                        data-required-msg="請填寫其他肉品" aria-describedby="" placeholder="請填寫其他肉品">
                    </div>
                  </div>
                </div>
                <div class="form-group full pass">
                  <label for="">是否具有相關檢驗與標章</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_IngredientOrigin3"
                        v-model="EventItemDetailData.EventItemDetail.T4_IngredientOrigin3">
                      <label class="form-check-label" for="T4_IngredientOrigin3">食材產地來源</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueTest3"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueTest3">
                      <label class="form-check-label" for="T4_AgriResidueTest3">農業殘留檢驗</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionHistory3"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionHistory3">
                      <label class="form-check-label" for="T4_ProductionHistory3">生產履歷</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProduct3"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProduct3">
                      <label class="form-check-label" for="T4_HighQualityAgriProduct3">優良農產品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabelAndCert3"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelAndCert3">
                      <label class="form-check-label" for="T4_FoodLabelAndCert3">食品標章及認證</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherCertification3"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertification3">
                      <label class="form-check-label" for="T4_OtherCertification3">其他</label>
                      <input type="text" class="form-control"
                        v-if="EventItemDetailData.EventItemDetail.T4_OtherCertification3"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationContent3"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherCertification3 ? '1' : '0'"
                        data-required-msg="請填寫其他認證" aria-describedby="" placeholder="請填寫其他認證">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_NoneCertification3"
                        v-model="EventItemDetailData.EventItemDetail.T4_NoneCertification3">
                      <label class="form-check-label" for="T4_NoneCertification3">無</label>
                    </div>
                  </div>
                </div>
                <div class="form-group file_box full">
                  <label for="">食材照片</label>
                  <img
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_IngredientPhoto3.includes('Upload')"
                    :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_IngredientPhoto3"
                    style="width: 300px; height: auto;">
                  <div class="file">
                    <input type="file" id="T4_IngredientPhoto3"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="T4_IngredientPhoto3_name" data-namgtag="1" />
                    <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_IngredientPhoto3"
                      id="T4_IngredientPhoto3_data" />
                    <button>上傳</button>
                  </div>
                  <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
                </div>
              </div>
              <hr>
              <div class="aa form-group full">
                <div class="form-group">
                  <label for="">食材</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_Ingredient4"
                    placeholder="請填寫食材">
                </div>
                <div class="form-group">
                  <label for="">販賣名稱</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SellingName4"
                    placeholder="請填寫販賣名稱">
                </div>
                <div class="form-group">
                  <label for="">份量</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Amount4">
                    <p>份</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Gram4">
                    <p>g</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">價格</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Price4">
                    <p>元</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">食材產地來源</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_FMAOrigin4"
                    placeholder="請填寫食材產地來源">
                </div>
                <div class="form-group">
                  <label for="">在地食材使用占比</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_LocProdRatio4">
                </div>
                <div class="form-group full pass">
                  <label for="">若食材為肉品參選，請勾選</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GMFood4"
                        v-model="EventItemDetailData.EventItemDetail.T4_GMFood4">
                      <label class="form-check-label" for="T4_GMFood4">基改食品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ReorgMeat4"
                        v-model="EventItemDetailData.EventItemDetail.T4_ReorgMeat4">
                      <label class="form-check-label" for="T4_ReorgMeat4">重組肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProcessMeat4"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProcessMeat4">
                      <label class="form-check-label" for="T4_ProcessMeat4">加工肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherMeat4"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeat4">
                      <label class="form-check-label" for="T4_OtherMeat4">其他</label>
                      <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_OtherMeat4"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeatContent4"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherMeat4 ? '1' : '0'"
                        data-required-msg="請填寫其他肉品" aria-describedby="" placeholder="請填寫其他肉品">
                    </div>
                  </div>
                </div>
                <div class="form-group full pass">
                  <label for="">是否具有相關檢驗與標章</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_IngredientOrigin4"
                        v-model="EventItemDetailData.EventItemDetail.T4_IngredientOrigin4">
                      <label class="form-check-label" for="T4_IngredientOrigin4">食材產地來源</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueTest4"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueTest4">
                      <label class="form-check-label" for="T4_AgriResidueTest4">農業殘留檢驗</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionHistory4"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionHistory4">
                      <label class="form-check-label" for="T4_ProductionHistory4">生產履歷</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProduct4"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProduct4">
                      <label class="form-check-label" for="T4_HighQualityAgriProduct4">優良農產品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabelAndCert4"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelAndCert4">
                      <label class="form-check-label" for="T4_FoodLabelAndCert4">食品標章及認證</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherCertification4"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertification4">
                      <label class="form-check-label" for="T4_OtherCertification4">其他</label>
                      <input type="text" class="form-control"
                        v-if="EventItemDetailData.EventItemDetail.T4_OtherCertification4"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationContent4"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherCertification4 ? '1' : '0'"
                        data-required-msg="請填寫其他認證" aria-describedby="" placeholder="請填寫其他認證">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_NoneCertification4"
                        v-model="EventItemDetailData.EventItemDetail.T4_NoneCertification4">
                      <label class="form-check-label" for="T4_NoneCertification4">無</label>
                    </div>
                  </div>
                </div>
                <div class="form-group file_box full">
                  <label for="">食材照片</label>
                  <img
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_IngredientPhoto4.includes('Upload')"
                    :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_IngredientPhoto4"
                    style="width: 300px; height: auto;">
                  <div class="file">
                    <input type="file" id="T4_IngredientPhoto4"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="T4_IngredientPhoto4_name" data-namgtag="1" />
                    <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_IngredientPhoto4"
                      id="T4_IngredientPhoto4_data" />
                    <button>上傳</button>
                  </div>
                  <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
                </div>
              </div>
              <hr>
              <div class="aa form-group full">
                <div class="form-group">
                  <label for="">食材</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_Ingredient5"
                    placeholder="請填寫食材">
                </div>
                <div class="form-group">
                  <label for="">販賣名稱</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SellingName5"
                    placeholder="請填寫販賣名稱">
                </div>
                <div class="form-group">
                  <label for="">份量</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Amount5">
                    <p>份</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Gram5">
                    <p>g</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">價格</label>
                  <div class="flex_box">
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="EventItemDetailData.EventItemDetail.T4_Price5">
                    <p>元</p>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">食材產地來源</label>
                  <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_FMAOrigin5"
                    placeholder="請填寫食材產地來源">
                </div>
                <div class="form-group">
                  <label for="">在地食材使用占比</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="EventItemDetailData.EventItemDetail.T4_LocProdRatio5">
                </div>
                <div class="form-group full pass">
                  <label for="">若食材為肉品參選，請勾選</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GMFood5"
                        v-model="EventItemDetailData.EventItemDetail.T4_GMFood5">
                      <label class="form-check-label" for="T4_GMFood5">基改食品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ReorgMeat5"
                        v-model="EventItemDetailData.EventItemDetail.T4_ReorgMeat5">
                      <label class="form-check-label" for="T4_ReorgMeat5">重組肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProcessMeat5"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProcessMeat5">
                      <label class="form-check-label" for="T4_ProcessMeat5">加工肉</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherMeat5"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeat5">
                      <label class="form-check-label" for="T4_OtherMeat5">其他</label>
                      <input type="text" class="form-control" v-if="EventItemDetailData.EventItemDetail.T4_OtherMeat5"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherMeatContent5"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherMeat5 ? '1' : '0'"
                        data-required-msg="請填寫其他肉品" aria-describedby="" placeholder="請填寫其他肉品">
                    </div>
                  </div>
                </div>
                <div class="form-group full pass">
                  <label for="">是否具有相關檢驗與標章</label>
                  <div class="form-group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_IngredientOrigin5"
                        v-model="EventItemDetailData.EventItemDetail.T4_IngredientOrigin5">
                      <label class="form-check-label" for="T4_IngredientOrigin5">食材產地來源</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueTest5"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueTest5">
                      <label class="form-check-label" for="T4_AgriResidueTest5">農業殘留檢驗</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionHistory5"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionHistory5">
                      <label class="form-check-label" for="T4_ProductionHistory5">生產履歷</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProduct5"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProduct5">
                      <label class="form-check-label" for="T4_HighQualityAgriProduct5">優良農產品</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabelAndCert5"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelAndCert5">
                      <label class="form-check-label" for="T4_FoodLabelAndCert5">食品標章及認證</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherCertification5"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertification5">
                      <label class="form-check-label" for="T4_OtherCertification5">其他</label>
                      <input type="text" class="form-control"
                        v-if="EventItemDetailData.EventItemDetail.T4_OtherCertification5"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationContent5"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherCertification5 ? '1' : '0'"
                        data-required-msg="請填寫其他認證" aria-describedby="" placeholder="請填寫其他認證">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_NoneCertification5"
                        v-model="EventItemDetailData.EventItemDetail.T4_NoneCertification5">
                      <label class="form-check-label" for="T4_NoneCertification5">無</label>
                    </div>
                  </div>
                </div>
                <div class="form-group file_box full">
                  <label for="">食材照片</label>
                  <img
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T4_IngredientPhoto5.includes('Upload')"
                    :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T4_IngredientPhoto5"
                    style="width: 300px; height: auto;">
                  <div class="file">
                    <input type="file" id="T4_IngredientPhoto5"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="T4_IngredientPhoto5_name" data-namgtag="1" />
                    <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T4_IngredientPhoto5"
                      id="T4_IngredientPhoto5_data" />
                    <button>上傳</button>
                  </div>
                  <p style="padding-left: 0px;">上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上</p>
                </div>
              </div>
            </div>
            <h1>特色醬料(限1項)</h1>
            <div class="form-group full content">
              <h2 class="form-group full">請依參選鍋物所搭配醬料填寫；須有於店面販售</h2>
              <div class="form-group ">
                <label for="">醬料名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SauceName"
                  placeholder="請填寫醬料名稱">
              </div>
              <div class="form-group">
                <label for="">醬料成分(所包含食材名稱概述)</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SauceContent"
                  placeholder="請填寫醬料成分">
              </div>
              <div class="form-group">
                <label for="">醬料特色說明</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T4_SauceDesc"
                  placeholder="請填寫醬料特色說明">
              </div>
            </div>
            <h1>參選鍋物與臺灣之關聯性說明</h1>
            <div class="form-group full content">
              <h2 class="">請簡述強調與臺灣元素融合部分（如口味調整、在地食材內容等），200字以內。</h2>
              <h2 class="">例：本次參選麻辣湯頭依臺灣顧客喜愛口味調整，不僅減少辛辣比例，其中30%添加屏東本土辣椒所製辣油，並加上嘉義小農栽種可溯源的大豆所製豆腐等….。</h2>
              <div class="form-group full">
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T4_TaiwaneseHotpot"></textarea>
              </div>
            </div>
            <h1>環境安全與衛生</h1>
            <div class="form-group full content business">
              <div class="form-group full">
                <div class="check_group">
                  <div class="form-check" v-if="false">
                    <input type="checkbox" class="form-check-input" id="T4_PrdLiabilityIns"
                      v-model="EventItemDetailData.EventItemDetail.T4_PrdLiabilityIns">
                    <label class="form-check-label" for="T4_PrdLiabilityIns" style="flex-basis: 210px;">產品責任險</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_ProdLiabilityInsurer"
                      :data-required="EventItemDetailData.EventItemDetail.T4_PrdLiabilityIns ? '1' : '0'"
                      data-required-msg="請填寫承保單位" aria-describedby="" placeholder="請填寫承保單位">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_CompFAndBReg"
                      v-model="EventItemDetailData.EventItemDetail.T4_CompFAndBReg">
                    <label class="form-check-label" for="T4_CompFAndBReg"
                      style="flex-basis: 100%;">遵守餐飲業相關法規、場域與人員管理</label>
                  </div>
                  <template v-if="EventItemDetailData.EventItemDetail.T4_CompFAndBReg">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FSANReg"
                        v-model="EventItemDetailData.EventItemDetail.T4_FSANReg">
                      <label class="form-check-label" for="T4_FSANReg" style="flex-basis: 210px;">食品安全衛生管理法</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_FSANRegContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_FSANReg ? '1' : '0'"
                        data-required-msg="請填寫食品安全衛生管理法" aria-describedby="" placeholder="請填寫食品安全衛生管理法">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_GoodHygieneNorm"
                        v-model="EventItemDetailData.EventItemDetail.T4_GoodHygieneNorm">
                      <label class="form-check-label" for="T4_GoodHygieneNorm"
                        style="flex-basis: 210px;">食品良好衛生規範準則</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_GoodHygieneNormContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_GoodHygieneNorm ? '1' : '0'"
                        data-required-msg="請填寫食品良好衛生規範準則" aria-describedby="" placeholder="請填寫食品良好衛生規範準則">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_UtensilWashGuide"
                        v-model="EventItemDetailData.EventItemDetail.T4_UtensilWashGuide">
                      <label class="form-check-label" for="T4_UtensilWashGuide"
                        style="flex-basis: 210px;">餐具清洗良好作業指引</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_UtensilWashGuideContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_UtensilWashGuide ? '1' : '0'"
                        data-required-msg="請填寫餐具清洗良好作業指引" aria-describedby="" placeholder="請填寫餐具清洗良好作業指引">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherReg"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherReg">
                      <label class="form-check-label" for="T4_OtherReg" style="flex-basis: 210px;">其他</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherRegContent"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherReg ? '1' : '0'"
                        data-required-msg="請填寫其他法規" aria-describedby="" placeholder="請填寫其他法規">
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <h1>其他具特色或創新說明</h1>
            <div class="form-group full content">
              <h2 class="">請簡述經營流程、服務等部分，並可充分展現特色或創新性（如參選食材創意、料理流程、鍋物設計、現場營運方式或流程等），200字以內。</h2>
              <div class="form-group full">
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T4_OtherUniqueDesc"></textarea>
              </div>
            </div>
            <template v-if="false">
              <h1>食材來源</h1>
              <div class="form-group full content business">
                <h2 class="">例：優良畜禽產品驗證：牛肉</h2>
                <div class="form-group full">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_AgriResidueCheck"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueCheck">
                      <label class="form-check-label" for="T4_AgriResidueCheck">農業殘留檢驗</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueCheckName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_AgriResidueCheck ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_AgriResidueCheckIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_AgriResidueCheck ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_ProductionRecord"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionRecord">
                      <label class="form-check-label" for="T4_ProductionRecord">生產履歷</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionRecordName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_ProductionRecord ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_ProductionRecordIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_ProductionRecord ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_HighQualityAgriProducts"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProducts">
                      <label class="form-check-label" for="T4_HighQualityAgriProducts">優良農產品</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProductsName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProducts ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProductsIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_HighQualityAgriProducts ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodLabel"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabel">
                      <label class="form-check-label" for="T4_FoodLabel">食品標章</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_FoodLabel ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodLabelIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_FoodLabel ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_FoodCertification"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodCertification">
                      <label class="form-check-label" for="T4_FoodCertification">食品認證</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodCertificationName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_FoodCertification ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_FoodCertificationIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_FoodCertification ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="T4_OtherSource"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherSource">
                      <label class="form-check-label" for="T4_OtherSource">其他</label>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherSourceName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherSource ? '1' : '0'"
                        data-required-msg="請填寫名稱" aria-describedby="" placeholder="請填寫名稱">
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_OtherSourceIngredientName"
                        :data-required="EventItemDetailData.EventItemDetail.T4_OtherSource ? '1' : '0'"
                        data-required-msg="請填寫食材名稱" aria-describedby="" placeholder="請填寫食材名稱">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <h1>服務體驗</h1>
            <div class="form-group full content business">
              <h2 class=""><span>*</span>服務體驗</h2>
              <div class="form-group full">
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_SelfService"
                      v-model="EventItemDetailData.EventItemDetail.T4_SelfService">
                    <label class="form-check-label" for="T4_SelfService">自助吧/<br>餐點種類多項</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_SelfServiceDesc"
                      :data-required="EventItemDetailData.EventItemDetail.T4_SelfService ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_SelfServiceQty"
                      :data-required="EventItemDetailData.EventItemDetail.T4_SelfService ? '1' : '0'"
                      data-required-msg="請填寫總類/數量" aria-describedby="" placeholder="請填寫總類/數量">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_TableService"
                      v-model="EventItemDetailData.EventItemDetail.T4_TableService">
                    <label class="form-check-label" for="T4_TableService">桌邊服務</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_TableServiceContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_TableService ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_AccessibleSpace"
                      v-model="EventItemDetailData.EventItemDetail.T4_AccessibleSpace">
                    <label class="form-check-label" for="T4_AccessibleSpace">無障礙空間</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_AccessibleSpaceContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_AccessibleSpace ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_ChildrenFurniture"
                      v-model="EventItemDetailData.EventItemDetail.T4_ChildrenFurniture">
                    <label class="form-check-label" for="T4_ChildrenFurniture">兒童桌椅/餐具</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_ChildrenFurnitureContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_ChildrenFurniture ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_MemberServices"
                      v-model="EventItemDetailData.EventItemDetail.T4_MemberServices">
                    <label class="form-check-label" for="T4_MemberServices">會員服務/活動</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_MemberServicesContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_MemberServices ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_ReservationNotification"
                      v-model="EventItemDetailData.EventItemDetail.T4_ReservationNotification">
                    <label class="form-check-label" for="T4_ReservationNotification">訂位通知</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_ReservationNotificationContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_ReservationNotification ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_LivePerformance"
                      v-model="EventItemDetailData.EventItemDetail.T4_LivePerformance">
                    <label class="form-check-label" for="T4_LivePerformance">現場表演</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_LivePerformanceContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_LivePerformance ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_DiningAmbiance"
                      v-model="EventItemDetailData.EventItemDetail.T4_DiningAmbiance">
                    <label class="form-check-label" for="T4_DiningAmbiance">用餐氛圍</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_DiningAmbianceContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_DiningAmbiance ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_DiningEnvironment"
                      v-model="EventItemDetailData.EventItemDetail.T4_DiningEnvironment">
                    <label class="form-check-label" for="T4_DiningEnvironment">用餐環境</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_DiningEnvironmentContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_DiningEnvironment ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_StaffAttire"
                      v-model="EventItemDetailData.EventItemDetail.T4_StaffAttire">
                    <label class="form-check-label" for="T4_StaffAttire">員工服裝</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_StaffAttireContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_StaffAttire ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_StaffServiceTraining"
                      v-model="EventItemDetailData.EventItemDetail.T4_StaffServiceTraining">
                    <label class="form-check-label" for="T4_StaffServiceTraining">員工服務及態度(教育訓練)</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_StaffServiceTrainingContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_StaffServiceTraining ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                  <div class="form-check other_btn">
                    <input type="checkbox" class="form-check-input" id="T4_OtherServices"
                      v-model="EventItemDetailData.EventItemDetail.T4_OtherServices">
                    <label class="form-check-label" for="T4_OtherServices">其他</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T4_OtherServicesContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_OtherServices ? '1' : '0'"
                      data-required-msg="請填寫說明" aria-describedby="" placeholder="請填寫說明">
                  </div>
                </div>
              </div>
            </div>
            <h1>調理產品檢驗</h1>
            <div class="bg">
              <div class="form-group full inspection">
                <div class="check_group">
                  <label for=""><span>*</span>調理產品檢驗</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_CookingProductStatus"
                      id="T4_CookingProductStatus1" v-bind:value="0"
                      v-model="EventItemDetailData.EventItemDetail.T4_CookingProductStatus">
                    <label class="form-check-label" for="T4_CookingProductStatus1">
                      目前尚無調理產品
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_CookingProductStatus"
                      id="T4_CookingProductStatus2" v-bind:value="1"
                      v-model="EventItemDetailData.EventItemDetail.T4_CookingProductStatus">
                    <label class="form-check-label" for="T4_CookingProductStatus2">
                      現有調理產品皆尚未經過相關檢驗
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_CookingProductStatus"
                      id="T4_CookingProductStatus3" v-bind:value="2"
                      v-model="EventItemDetailData.EventItemDetail.T4_CookingProductStatus">
                    <label class="form-check-label" for="T4_CookingProductStatus3">
                      調理產品已進行最終產品檢驗
                    </label>
                    <div class="form-group" v-if="EventItemDetailData.EventItemDetail.T4_CookingProductStatus == 2">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="T4_ComplianceWithFoodSafetyRegulations"
                          v-model="EventItemDetailData.EventItemDetail.T4_ComplianceWithFoodSafetyRegulations">
                        <label class="form-check-label"
                          for="T4_ComplianceWithFoodSafetyRegulations">符合我國「食品安全衛生管理法」所訂食品相關衛生標準或相關規範</label>
                      </div>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="T4_ComplianceWithExportStandards"
                          v-model="EventItemDetailData.EventItemDetail.T4_ComplianceWithExportStandards">
                        <label class="form-check-label"
                          for="T4_ComplianceWithExportStandards">符合輸出國之產品檢驗標準及包裝標示規定</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 v-if="EventItemDetailData.EventItemDetail.T4_CookingProductStatus != 0">製作調理產品之食品工廠</h1>
            <div class="bg" v-if="EventItemDetailData.EventItemDetail.T4_CookingProductStatus != 0">
              <div class="form-group full inspection factory">
                <div class="check_group">
                  <label for=""><span>*</span>製作調理產品之食品工廠</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_FoodFactoryStatus" id="T4_FoodFactoryStatus1"
                      v-bind:value="0" v-model="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus">
                    <label class="form-check-label" for="T4_FoodFactoryStatus1">
                      目前尚無自有或委外製作調理包之食品工廠
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_FoodFactoryStatus" id="T4_FoodFactoryStatus2"
                      v-bind:value="1" v-model="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus">
                    <label class="form-check-label" for="T4_FoodFactoryStatus2">
                      自有工廠
                    </label>
                    <div class="form-group" v-if="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus == 1">
                      <p>共</p>
                      <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                        v-model.number="EventItemDetailData.EventItemDetail.T4_OwnFactoryQuantity">
                      <p>間</p>
                    </div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="T4_FoodFactoryStatus" id="T4_FoodFactoryStatus3"
                      v-bind:value="2" v-model="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus">
                    <label class="form-check-label" for="T4_FoodFactoryStatus3">
                      代工廠
                    </label>
                    <div class="form-group" v-if="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus == 2">
                      <p>合作廠商為</p>
                      <input type="text" class="form-control"
                        v-model="EventItemDetailData.EventItemDetail.T4_OEMFactoryCollaborators"
                        :data-required="EventItemDetailData.EventItemDetail.T4_CookingProductStatus != 0 && EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus == 2 ? '1' : '0'"
                        data-required-msg="請填代工廠合作廠商" aria-describedby="" placeholder="請填寫代工廠合作廠商">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group full pass" v-if="EventItemDetailData.EventItemDetail.T4_FoodFactoryStatus != 0">
                <label for="">上述工廠已通過檢驗</label>
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_ISO22000Certification"
                      v-model="EventItemDetailData.EventItemDetail.T4_ISO22000Certification">
                    <label class="form-check-label" for="T4_ISO22000Certification">ISO22000驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_HACCPCertification"
                      v-model="EventItemDetailData.EventItemDetail.T4_HACCPCertification">
                    <label class="form-check-label" for="T4_HACCPCertification">HACCP驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_HalalCertification"
                      v-model="EventItemDetailData.EventItemDetail.T4_HalalCertification">
                    <label class="form-check-label" for="T4_HalalCertification">清真驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_CASExcellentAgriculturalProductMark"
                      v-model="EventItemDetailData.EventItemDetail.T4_CASExcellentAgriculturalProductMark">
                    <label class="form-check-label" for="T4_CASExcellentAgriculturalProductMark">CAS優良農產品標章</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_TQFTaiwanExcellentFoodCertification"
                      v-model="EventItemDetailData.EventItemDetail.T4_TQFTaiwanExcellentFoodCertification">
                    <label class="form-check-label" for="T4_TQFTaiwanExcellentFoodCertification">TQF台灣優良食品驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_SQFCertification"
                      v-model="EventItemDetailData.EventItemDetail.T4_SQFCertification">
                    <label class="form-check-label" for="T4_SQFCertification">SQF驗證(Safe Quality Food
                      certification)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_FSSF22000Certification"
                      v-model="EventItemDetailData.EventItemDetail.T4_FSSF22000Certification">
                    <label class="form-check-label" for="T4_FSSF22000Certification">FSSF 22000驗證(Food Safety System
                      certification)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_OtherCertifications"
                      v-model="EventItemDetailData.EventItemDetail.T4_OtherCertifications">
                    <label class="form-check-label" for="T4_OtherCertifications">其他</label>
                    <input type="text" class="form-control"
                      v-if="EventItemDetailData.EventItemDetail.T4_OtherCertifications"
                      v-model="EventItemDetailData.EventItemDetail.T4_OtherCertificationsContent"
                      :data-required="EventItemDetailData.EventItemDetail.T4_CookingProductStatus != 0 && EventItemDetailData.EventItemDetail.T4_OtherCertifications ? '1' : '0'"
                      data-required-msg="請填寫其他檢驗內容" aria-describedby="" placeholder="請填寫其他檢驗內容">
                  </div>
                </div>
              </div>
            </div>
            <h1>聯合行銷活動優惠</h1>
            <div class="form-group full content marketing">
              <h2 class="">獲選後願意參與計畫年度聯合行銷活動，並提供相關優惠</h2>
              <div class="form-group full">
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_JointPromoProduct"
                      v-model="EventItemDetailData.EventItemDetail.T4_JointPromoProduct">
                    <label class="form-check-label" for="T4_JointPromoProduct">
                      <div class="flex_box">
                        <p class="width_100">願意提供</p>
                        <input type="text" class="form-control"
                          v-model="EventItemDetailData.EventItemDetail.T4_JointPromoProductName">
                        <p>之商品</p>
                        <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                          v-model.number="EventItemDetailData.EventItemDetail.T4_JointPromoProductQty">
                        <p>份為活動贈品使用</p>
                      </div>
                    </label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_JointPromoDiscount"
                      v-model="EventItemDetailData.EventItemDetail.T4_JointPromoDiscount">
                    <label class="form-check-label" for="T4_JointPromoDiscount">
                      <div class="flex_box">
                        <p>願意提供消費折扣</p>
                        <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                          v-model.number="EventItemDetailData.EventItemDetail.T4_JointPromoDiscountQty">
                        <p>折</p>
                      </div>
                    </label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T4_JointPromoOther"
                      v-model="EventItemDetailData.EventItemDetail.T4_JointPromoOther">
                    <label class="form-check-label" for="T4_JointPromoOther">
                      <div class="flex_box">
                        <p>其他相關優惠方案</p>
                        <input type="text" class="form-control"
                          v-model="EventItemDetailData.EventItemDetail.T4_JointPromoOtherContent">
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="btn_box">
              <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                tag="button">返回列表</router-link>
              <button @click="save()"
                v-if="EventItemDetailData.Review.ReviewStatus == 0 || EventItemDetailData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "EventItemDetail_4",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      EIDId: -1,
      EventItemDetailData: {},
      SideDishes: [],
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    if (this.$route.params.EIDId) {
      this.EIDId = this.$route.params.EIDId;
    }
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/EventItemDetail",
        {
          params: { EId: this.$route.params.EId, EIId: this.$route.params.EIId, EIDId: this.EIDId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventItemDetailData = response.data;
        this.SideDishes = this.EventItemDetailData.SideDishes;
        if (!this.EventItemDetailData.UserInfo ||
          this.EventItemDetailData.UserInfo.UIId == -1) {
          Swal.fire({
            title: "請先填寫基本資料",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "BaseInfo",
                params: { EId: this.$route.params.EId },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    if (this.EventItemDetailData.Review.ReviewStatus != 0 && this.EventItemDetailData.Review.ReviewStatus != 3) {
      $("#stet_1 input").attr("disabled", "disabled").off('click');
      $("#stet_1 textarea").attr("disabled", "disabled").off('click');
    }
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 104857600) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過100MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "T4_PotPhoto") {
              that.EventItemDetailData.EventItemDetail.T4_PotPhoto = reader.result;
            } else if (id == "T4_PotPhoto2") {
              that.EventItemDetailData.EventItemDetail.T4_PotPhoto2 = reader.result;
            } else if (id == "T4_IngredientPhoto") {
              that.EventItemDetailData.EventItemDetail.T4_IngredientPhoto = reader.result;
            } else if (id == "T4_IngredientPhoto2") {
              that.EventItemDetailData.EventItemDetail.T4_IngredientPhoto2 = reader.result;
            } else if (id == "T4_IngredientPhoto3") {
              that.EventItemDetailData.EventItemDetail.T4_IngredientPhoto3 = reader.result;
            } else if (id == "T4_IngredientPhoto4") {
              that.EventItemDetailData.EventItemDetail.T4_IngredientPhoto4 = reader.result;
            } else if (id == "T4_IngredientPhoto5") {
              that.EventItemDetailData.EventItemDetail.T4_IngredientPhoto5 = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    addSideDishe() {
      this.SideDishes.push({
        SideDisheContent: '',
        IngredientOrigin: '',
        LocalIngredientsRatio: 0,
        DomesticProductRecord: false,
        UseExtraIngredients: false,
      });
    },
    removeSideDishe(index) {
      if (this.SideDishes.length > 1) {
        this.SideDishes.splice(index, 1);
      }
    },
    save() {
      var hasError = false;
      $("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (!this.EventItemDetailData.EventItemDetail.T4_PersonalPotType &&
        !this.EventItemDetailData.EventItemDetail.T4_SharedPotType
      ) {
        Swal.fire({
          title: "錯誤",
          text: "請至少選擇一種鍋物型態",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        hasError = true;
        return false;
      }
      if (!this.EventItemDetailData.EventItemDetail.T4_AllYouCanEatPotType &&
        !this.EventItemDetailData.EventItemDetail.T4_AlaCartePotType
      ) {
        Swal.fire({
          title: "錯誤",
          text: "吃到飽/單點中至少需要勾選一個項目",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        hasError = true;
        return false;
      }
      if (hasError) {
        return;
      }
      var SideDisheError = false;
      $.each(this.SideDishes, function (index, SideDishe) {
        if (SideDishe.SideDisheContent == "" ||
          SideDishe.IngredientOrigin == "") {
          SideDisheError = true;
          return;
        }
        if (SideDisheError) {
          Swal.fire({
            title: "錯誤",
            text: "請確實輸入配菜資料",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
      });
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/EventItemDetail",
        type: "post",
        data: {
          EventItemDetailJson: JSON.stringify(that.EventItemDetailData.EventItemDetail),
          AttachmentNames: AttachmentNames,
          SideDishes: JSON.stringify(that.SideDishes),
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "儲存成功",
              text: "即將返回列表",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "EventItem",
                  params: { EId: that.EventItemDetailData.Event.EId }
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
