<template>
  <div>
    <Head />

    <div class="wrapper sign_up">
      <section class="main">
        <div class="container">
          <!-- <h1>2023年經濟部綠色盒餐徵選</h1> -->
          <div class="img_box">
            <div class="img">
              <img v-if="ProjectData && ProjectData.Project && ProjectData.Project.Image" :src="ProjectData.SiteUrl + ProjectData.Project.Image" alt="">
              <img v-else src="img/sample-2.png" alt="">
            </div>
          </div>
          <div class="box">
            <h2>會員登入</h2>
            <div class="form-group">
              <label for="">統一編號</label>
              <input type="" class="form-control" id="" aria-describedby="" placeholder="請輸入統一編號" v-model="TaxID">
            </div>
            <div class="form-group">
              <label for="">密碼</label>
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入密碼"
                v-model="Password">
            </div>
            <div class="btn_box">
              <button @click="login()">登入</button>
              <router-link :to="{ name: 'Register' }" tag="button">會員註冊</router-link>
            </div>
            <div class="forget">
              <router-link :to="{ name: 'Forget' }">忘記密碼？</router-link>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "LoginForUser",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      ProjectData: {},
      local: "zh_tw",
      TaxID: "",
      Password: "",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/GetOngoingProject",
        {
          params: {
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.ProjectData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    async getRecaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      return token;
    },
    async login() {
      if (this.TaxID == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入統一編號",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Password == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.TaxID + "|" + this.Password + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/LoginForUser",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            $cookies.set("is_login_token", res.LoginToken, "30d");
            Swal.fire({
              title: "登入成功",
              text: "即將返回首頁",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                if (that.$route.fullPath == "/") {
                  location.reload();
                } else {
                  that.$router.push({
                    name: "Home",
                  });
                }
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

