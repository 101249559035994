<template>
  <div>
    <Head />

    <div class="wrapper" v-if="RatingHomeData">
      <section class="main">
        <div class="container">
          <ul class="btn_box">
            <li v-for="EventType in RatingHomeData.EventTypes" :key="EventType.ETId">
              <button :class="EventType.ETId == TabIndex ? 'active' : ''" @click="TabIndex = EventType.ETId">{{
      EventType.Name }}</button>
            </li>
          </ul>
          <ul class="list_box show">
            <li v-for="Rating in RatingHomeData.Ratings" :key="Rating.RId" v-if="Rating.Event.ETId == TabIndex">
              <a href="javascript:void(0)" @click="gotoRatingByRId(Rating.RId)">
                <div class="img_box">
                  <div class="img">
                    <img v-if="!Rating.Image" src="img/sample-2.png" alt="">
                    <img v-if="Rating.Image" :src="RatingHomeData.SiteUrl + Rating.Image" alt="">
                  </div>
                </div>
                <div class="text">
                  <h1>{{ Rating.RatingName }}</h1>
                  <div class="content">
                    <template v-if="Rating.StartTime && Rating.EndTime">
                      <p>評分期間：{{ Rating.StartTim | timeString("YYYY-MM-DD") }} 至 {{ Rating.EndTime | timeString("YYYY-MM-DD") }}</p>
                    </template>
                    <p>評分方式：{{ Rating.RatingType }}分制</p>
                  </div>
                  <div class="btn">MORE</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "RatingHome",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      RatingHomeData: {},
      local: "zh_tw",
      TabIndex: 0,
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/RatingHome",
        {
          params: { EId: to.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.RatingHomeData = response.data;
        if (!this.RatingHomeData.User || !this.RatingHomeData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        this.TabIndex = this.RatingHomeData.DefaultETId;
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/RatingHome",
        {
          params: {
            lang: $cookies.get("is_site_lang"), token: $cookies.get("is_login_token")
          },
        }
      )
      .then((response) => {
        this.RatingHomeData = response.data;
        if (!this.RatingHomeData.User || !this.RatingHomeData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        this.TabIndex = this.RatingHomeData.DefaultETId;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId },
        });
      }
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoRatingByRId: function (RId) {
      this.$router.push({
        name: "Rating",
        params: { RId: RId },
      });
    },
  },
};
</script>
