<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb" v-if="EventItemDetailData">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="EventItemDetailData.Event">{{ EventItemDetailData.Event.Name }}</li>
        <li class="breadcrumb-item active" v-if="EventItemDetailData.EventItem" aria-current="page">{{
      EventItemDetailData.EventItem.Name }}</li>
      </ol>
    </div>
    <div class="wrapper member_information member_information_1_main" v-if="EventItemDetailData.EventItemDetail">
      <section class="main">
        <div class="container">
          <div class="step_box">
            <div class="" @click="goto('BaseInfo')" style="cursor: pointer;">基本資料</div>
            <div class="step">品項管理</div>
            <div class="" @click="goto('ReqDoc')" style="cursor: pointer;">應備文件</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 0" class="" @click="sendReview()"
              style="cursor: pointer;">送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 3" class="" @click="sendReview()"
              style="cursor: pointer;">重新送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <div id="stet_1" class="box show">
            <h1>
              基本資料
              <div class="btn_home">
                <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                  tag="button">返回列表</router-link>
              </div>
            </h1>
            <div class="bg">
              <div class="form-group ">
                <label for=""><span>*</span>品牌名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T1_BrandName"
                  data-required="1" data-required-msg="請填寫品牌名稱" aria-describedby="" placeholder="請填寫品牌名稱">
              </div>
              <!-- <div class="form-group project">
                <label for=""><span>*</span>徵選項目</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_Box_1" v-bind:value="1"
                      v-model="EventItemDetailData.EventItemDetail.T1_Box">
                    <label class="form-check-label" for="T1_Box_1">
                      一般餐盒組
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_Box_2" v-bind:value="2"
                      v-model="EventItemDetailData.EventItemDetail.T1_Box">
                    <label class="form-check-label" for="T1_Box_2">
                      循環餐盒組
                    </label>
                  </div>
                </div>
              </div> -->
              <div class="form-group">
                <label for=""><span>*</span>餐點名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T1_MealName"
                  data-required="1" data-required-msg="請填寫餐點名稱" aria-describedby="" placeholder="請填寫餐點名稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>餐點價格</label>
                <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                  v-model.number="EventItemDetailData.EventItemDetail.T1_MealPrice">
              </div>
              <div class="form-group full type">
                <label for=""><span>*</span>餐點類型</label>
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T1_General"
                      v-model="EventItemDetailData.EventItemDetail.T1_General">
                    <label class="form-check-label" for="T1_General">一般餐盒</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T1_Conference"
                      v-model="EventItemDetailData.EventItemDetail.T1_Conference">
                    <label class="form-check-label" for="T1_Conference">會議餐盒</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T1_Other"
                      v-model="EventItemDetailData.EventItemDetail.T1_Other">
                    <label class="form-check-label" for="T1_Other">其他</label>
                    <input type="" class="form-control" id="" aria-describedby="" placeholder="請填寫其他餐點類型"
                      v-if="EventItemDetailData.EventItemDetail.T1_Other"
                      v-model="EventItemDetailData.EventItemDetail.T1_OtherContent"
                      :data-required="EventItemDetailData.EventItemDetail.T1_Other ? '1' : '0'"
                      data-required-msg="請填寫其他餐點類型">
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>餐點風味</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T1_Flavor" aria-describedby=""
                  data-required="1" data-required-msg="請填寫餐點風味" placeholder="請填寫餐點風味"></textarea>
              </div>
            </div>
            <h1>餐點組成（食材須使用50%以上的國內在地食材或格外品製作）</h1>
            <div class="form-group full content">
              <h2 class=""><span>*</span>主食</h2>
              <div class="form-group full">
                <label for="">主食內容：</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T1_M_StapleFoodContent"
                  aria-describedby="" data-required="1" data-required-msg="請填寫主食內容" placeholder="請填寫主食內容"></textarea>
              </div>
              <div class="form-group full">
                <label for="">品類：</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T1_M_Category"
                  data-required="1" data-required-msg="請填寫品類" aria-describedby="" placeholder="請填寫品類">
              </div>
              <div class="form-group">
                <label for="">產地/區域：</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T1_M_OriginRegion"
                  data-required="1" data-required-msg="請填寫產地/區域" aria-describedby="" placeholder="請填寫產地/區域">
              </div>
              <div class="form-group proportion">
                <label for="">在地食材比例:</label>
                <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                  v-model.number="EventItemDetailData.EventItemDetail.T1_M_LocalIngredientsRatio">
                <p>%</p>
              </div>
              <div class="form-group">
                <label for="">國內頒發產品履歷：</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_M_DomesticProductRecord_true"
                      v-bind:value="true" v-model="EventItemDetailData.EventItemDetail.T1_M_DomesticProductRecord">
                    <label class="form-check-label" for="T1_M_DomesticProductRecord_true">
                      有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_M_DomesticProductRecord_false"
                      v-bind:value="false" v-model="EventItemDetailData.EventItemDetail.T1_M_DomesticProductRecord">
                    <label class="form-check-label" for="T1_M_DomesticProductRecord_false">
                      無
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">臺灣米標章：</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_M_TaiwanRiceMark_true" v-bind:value="true"
                      v-model="EventItemDetailData.EventItemDetail.T1_M_TaiwanRiceMark">
                    <label class="form-check-label" for="T1_M_TaiwanRiceMark_true">
                      有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_M_TaiwanRiceMark_false" v-bind:value="false"
                      v-model="EventItemDetailData.EventItemDetail.T1_M_TaiwanRiceMark">
                    <label class="form-check-label" for="T1_M_TaiwanRiceMark_false">
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="form-group full content">
              <h2><span>*</span>主菜</h2>
              <div class="form-group full">
                <label for="">主菜內容：</label>
                <input type="text" class="form-control"
                  v-model="EventItemDetailData.EventItemDetail.T1_A_MainDishContent" data-required="1"
                  data-required-msg="請填寫主菜內容" aria-describedby="" placeholder="請填寫主菜內容">
              </div>
              <div class="form-group">
                <label for="">材料之產地：</label>
                <input type="text" class="form-control"
                  v-model="EventItemDetailData.EventItemDetail.T1_A_IngredientOrigin" data-required="1"
                  data-required-msg="請填寫材料之產地" aria-describedby="" placeholder="請填寫材料之產地">
              </div>
              <div class="form-group proportion">
                <label for="">在地食材比例:</label>
                <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                  v-model.number="EventItemDetailData.EventItemDetail.T1_A_LocalIngredientsRatio">
                <p>%</p>
              </div>
              <div class="form-group">
                <label for="">國內頒發產品履歷：</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_A_DomesticProductRecord_true"
                      v-bind:value="true" v-model="EventItemDetailData.EventItemDetail.T1_A_DomesticProductRecord">
                    <label class="form-check-label" for="T1_A_DomesticProductRecord_true">
                      有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_A_DomesticProductRecord_false"
                      v-bind:value="false" v-model="EventItemDetailData.EventItemDetail.T1_A_DomesticProductRecord">
                    <label class="form-check-label" for="T1_A_DomesticProductRecord_false">
                      無
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">是否使用格外品：</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_A_UseExtraIngredients_true" v-bind:value="true"
                      v-model="EventItemDetailData.EventItemDetail.T1_A_UseExtraIngredients">
                    <label class="form-check-label" for="T1_A_UseExtraIngredients_true">
                      有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="T1_A_UseExtraIngredients_false"
                      v-bind:value="false" v-model="EventItemDetailData.EventItemDetail.T1_A_UseExtraIngredients">
                    <label class="form-check-label" for="T1_A_UseExtraIngredients_false">
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="form-group full">
              <h2 class="plus_box"><span>*</span>配菜 <span class="plus" @click="addSideDishe()">新增 ＋</span>
                <!-- <span
                  class="remove" @click="removeSideDishe()" :style="SideDishes.length > 1 ? 'display: block;' : ''">刪除
                  －</span> -->
              </h2>
              <div class="aa form-group content" v-for="(SideDishe, index) in SideDishes" :key="index">
                <h3>配菜 <span>{{ index + 1 }}</span></h3>
                <div class="remove add" v-if="SideDishes.length > 1" @click="removeSideDishe(index)">刪除 X</div>
                <div class="form-group full">
                  <label for="">配菜內容：</label>
                  <input type="text" class="form-control" v-model="SideDishe.SideDisheContent" data-required="1"
                    data-required-msg="請填寫配菜內容" aria-describedby="" placeholder="請填寫配菜內容">
                </div>
                <div class="form-group">
                  <label for="">材料之產地：</label>
                  <input type="text" class="form-control" v-model="SideDishe.IngredientOrigin" data-required="1"
                    data-required-msg="請填寫材料之產地" aria-describedby="" placeholder="請填寫材料之產地">
                </div>
                <div class="form-group proportion">
                  <label for="">在地食材比例:</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="SideDishe.LocalIngredientsRatio">
                  <p>%</p>
                </div>
                <div class="form-group">
                  <label for="">國內頒發產品履歷：</label>
                  <div class="check_group radio radio_1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" :id="'DomesticProductRecord_true_' + index"
                        v-bind:value="true" v-model="SideDishe.DomesticProductRecord">
                      <label class="form-check-label" :for="'DomesticProductRecord_true_' + index">
                        有
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" :id="'DomesticProductRecord_false_' + index"
                        v-bind:value="false" v-model="SideDishe.DomesticProductRecord">
                      <label class="form-check-label" :for="'DomesticProductRecord_false_' + index">
                        無
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">是否使用格外品：</label>
                  <div class="check_group radio radio_1">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" :id="'UseExtraIngredients_true_' + index"
                        v-bind:value="true" v-model="SideDishe.UseExtraIngredients">
                      <label class="form-check-label" :for="'UseExtraIngredients_true_' + index">
                        有
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" :id="'UseExtraIngredients_false_' + index"
                        v-bind:value="false" v-model="SideDishe.UseExtraIngredients">
                      <label class="form-check-label" :for="'UseExtraIngredients_false_' + index">
                        無
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="bg">
              <h2><span>*</span>餐盒材質</h2>
              <div class="form-group full type">
                <label for="">環保餐盒材質</label>
                <div class="check_group">
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_NoLCRecyclable"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCRecyclable">
                    <label class="form-check-label" for="T1_NoLCRecyclable">回收標誌</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_NoLCSoyInk"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCSoyInk">
                    <label class="form-check-label" for="T1_NoLCSoyInk">使用大豆油墨</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_NoLCPehosphateFree"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCPehosphateFree">
                    <label class="form-check-label" for="T1_NoLCPehosphateFree">包裝無磷膜</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_NoLCEcoMaterials"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCEcoMaterials">
                    <label class="form-check-label" for="T1_NoLCEcoMaterials">使用紙類或木片、甘蔗、稻殼等環保材質</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_NoLCOther"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCOther">
                    <label class="form-check-label" for="T1_NoLCOther">其他</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T1_NoLCOtherContent"
                      v-if="EventItemDetailData.EventItemDetail.T1_NoLCOther"
                      :data-required="EventItemDetailData.EventItemDetail.T1_NoLCOther ? '1' : '0'"
                      data-required-msg="請填寫其他盛裝品材質" aria-describedby="" placeholder="請填寫其他盛裝品材質">
                  </div>
                </div>
              </div>
              <div class="form-group full type">
                <label for="">循環餐盒材質</label>
                <div class="check_group">
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_LCSS"
                      v-model="EventItemDetailData.EventItemDetail.T1_LCSS">
                    <label class="form-check-label" for="T1_LCSS">不鏽鋼</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_LCPYREX"
                      v-model="EventItemDetailData.EventItemDetail.T1_LCPYREX">
                    <label class="form-check-label" for="T1_LCPYREX">耐熱玻璃</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_LCPLA"
                      v-model="EventItemDetailData.EventItemDetail.T1_LCPLA">
                    <label class="form-check-label" for="T1_LCPLA">生物可分解塑膠</label>
                  </div>
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_LCOther"
                      v-model="EventItemDetailData.EventItemDetail.T1_LCOther">
                    <label class="form-check-label" for="T1_LCOther">其他</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T1_LCOtherContent"
                      v-if="EventItemDetailData.EventItemDetail.T1_LCOther"
                      :data-required="EventItemDetailData.EventItemDetail.T1_LCOther ? '1' : '0'"
                      data-required-msg="請填寫其他循環容器之材質" aria-describedby="" placeholder="請填寫其他循環容器之材質">
                  </div>
                </div>
              </div>
              <div class="form-group full type">
                <label for="">其他餐盒材質</label>
                <div class="check_group">
                  <div class="form-check" style="flex-basis: 50%;">
                    <input type="checkbox" class="form-check-input" id="T1_MealBoxOther"
                      v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOther">
                    <label class="form-check-label" for="T1_MealBoxOther">其他</label>
                    <input type="text" class="form-control"
                      v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOtherContent"
                      v-if="EventItemDetailData.EventItemDetail.T1_MealBoxOther"
                      :data-required="EventItemDetailData.EventItemDetail.T1_MealBoxOther ? '1' : '0'"
                      data-required-msg="請填寫其他材質" aria-describedby="" placeholder="請填寫其他材質">
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for="">容器循環作法</label>
                <p>僅有使用循環盒餐需回覆，請簡述容器如何回收與清洗，文字簡述100字內</p>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T1_ContainerRecyclingMethod"
                  aria-describedby="" placeholder="請填寫容器循環作法"></textarea>
              </div>
            </div>
            <h1>餐點特色說明</h1>
            <div class="bg">
              <div class="form-group full">
                <label for=""><span>*</span>餐點特色說明</label>
                <p>請針對本餐點餐點設計、美味程度、餐點是否無任何添加物、低油減鹽、採用當季時蔬或食材等，以及餐點是否有特殊性，特殊烹飪手法、獨特創作料理、在地特色食材、特製風味等等，文字簡述150字內</p>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T1_MealFeaturesDescription"
                  aria-describedby="" data-required="1" data-required-msg="請填寫餐點特色說明"
                  placeholder="請填寫餐點特色說明"></textarea>
              </div>
              <!-- <div class="form-group full">
                <label for=""><span>*</span>如何維持餐點品質</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T1_MaintainingMealQuality"
                  aria-describedby="" data-required="1" data-required-msg="請填寫如何維持餐點品質"
                  placeholder="請填寫如何維持餐點品質"></textarea>
              </div> -->
            </div>
            <h1>照片上傳</h1>
            <div class="bg">
              <div class="form-group file_box full">
                <label for=""><span>*</span>餐盒整體與餐盒菜色照片-1</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T1_MealBoxOverallAndDishPhotos"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_MealBoxOverallAndDishPhotos_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos"
                    id="T1_MealBoxOverallAndDishPhotos_data" data-required="1" data-required-msg="請上傳餐盒整體與餐盒菜色照片-1" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for=""><span>*</span>餐盒整體與餐盒菜色照片-2</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos2.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T1_MealBoxOverallAndDishPhotos2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_MealBoxOverallAndDishPhotos2_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos2"
                    id="T1_MealBoxOverallAndDishPhotos2_data" data-required="1" data-required-msg="請上傳餐盒整體與餐盒菜色照片-2" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="false">
                <label for="">餐盒整體與餐盒菜色照片-3<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos3">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T1_MealBoxOverallAndDishPhotos3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_MealBoxOverallAndDishPhotos3_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos3"
                    id="T1_MealBoxOverallAndDishPhotos3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="false">
                <label for="">餐盒整體與餐盒菜色照片-4<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos4">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T1_MealBoxOverallAndDishPhotos4"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_MealBoxOverallAndDishPhotos4_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos4"
                    id="T1_MealBoxOverallAndDishPhotos4_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="false">
                <label for="">餐盒整體與餐盒菜色照片-5<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos5">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T1_MealBoxOverallAndDishPhotos5"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_MealBoxOverallAndDishPhotos5_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos5"
                    id="T1_MealBoxOverallAndDishPhotos5_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">臺灣米標章</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_TaiwanRiceMark.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T1_TaiwanRiceMark"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T1_TaiwanRiceMark"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_TaiwanRiceMark_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_TaiwanRiceMark"
                    id="T1_TaiwanRiceMark_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">產銷履歷證明</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_OriginAndSalesRecordProof.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T1_OriginAndSalesRecordProof"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T1_OriginAndSalesRecordProof"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_OriginAndSalesRecordProof_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_OriginAndSalesRecordProof"
                    id="T1_OriginAndSalesRecordProof_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">格外品使用證明</label>
                <img
                  v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T1_ExtraIngredientsProof.includes('Upload')"
                  :src="EventItemDetailData.SiteUrl + EventItemDetailData.EventItemDetail.T1_ExtraIngredientsProof"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="T1_ExtraIngredientsProof"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T1_ExtraIngredientsProof_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T1_ExtraIngredientsProof"
                    id="T1_ExtraIngredientsProof_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
            </div>
            <hr>
            <div class="btn_box">
              <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                tag="button">返回列表</router-link>
              <button @click="save()"
                v-if="EventItemDetailData.Review.ReviewStatus == 0 || EventItemDetailData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "EventItemDetail_1",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      EIDId: -1,
      EventItemDetailData: {},
      SideDishes: [],
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    if (this.$route.params.EIDId) {
      this.EIDId = this.$route.params.EIDId;
    }
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/EventItemDetail",
        {
          params: { EId: this.$route.params.EId, EIId: this.$route.params.EIId, EIDId: this.EIDId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventItemDetailData = response.data;
        this.SideDishes = this.EventItemDetailData.SideDishes;
        if (!this.EventItemDetailData.UserInfo ||
          this.EventItemDetailData.UserInfo.UIId == -1) {
          Swal.fire({
            title: "請先填寫基本資料",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "BaseInfo",
                params: { EId: this.$route.params.EId },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    if (this.EventItemDetailData.Review.ReviewStatus != 0 && this.EventItemDetailData.Review.ReviewStatus != 3) {
      $("#stet_1 input").attr("disabled", "disabled").off('click');
      $("#stet_1 textarea").attr("disabled", "disabled").off('click');
    }
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 104857600) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過100MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "T1_MealBoxOverallAndDishPhotos") {
              that.EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos = reader.result;
            } else if (id == "T1_MealBoxOverallAndDishPhotos2") {
              that.EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos2 = reader.result;
            } else if (id == "T1_MealBoxOverallAndDishPhotos3") {
              that.EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos3 = reader.result;
            } else if (id == "T1_MealBoxOverallAndDishPhotos4") {
              that.EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos4 = reader.result;
            } else if (id == "T1_MealBoxOverallAndDishPhotos5") {
              that.EventItemDetailData.EventItemDetail.T1_MealBoxOverallAndDishPhotos5 = reader.result;
            } else if (id == "T1_TaiwanRiceMark") {
              that.EventItemDetailData.EventItemDetail.T1_TaiwanRiceMark = reader.result;
            } else if (id == "T1_OriginAndSalesRecordProof") {
              that.EventItemDetailData.EventItemDetail.T1_OriginAndSalesRecordProof = reader.result;
            } else if (id == "T1_ExtraIngredientsProof") {
              that.EventItemDetailData.EventItemDetail.T1_ExtraIngredientsProof = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    addSideDishe() {
      this.SideDishes.push({
        SideDisheContent: '',
        IngredientOrigin: '',
        LocalIngredientsRatio: 0,
        DomesticProductRecord: false,
        UseExtraIngredients: false,
      });
    },
    removeSideDishe(index) {
      if (this.SideDishes.length > 1) {
        this.SideDishes.splice(index, 1);
      }
    },
    save() {
      var hasError = false;
      $("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      var SideDisheError = false;
      $.each(this.SideDishes, function (index, SideDishe) {
        if (SideDishe.SideDisheContent == "" ||
          SideDishe.IngredientOrigin == "") {
          SideDisheError = true;
          return;
        }
        if (SideDisheError) {
          Swal.fire({
            title: "錯誤",
            text: "請確實輸入配菜資料",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
      });
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/EventItemDetail",
        type: "post",
        data: {
          EventItemDetailJson: JSON.stringify(that.EventItemDetailData.EventItemDetail),
          AttachmentNames: AttachmentNames,
          SideDishes: JSON.stringify(that.SideDishes),
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "儲存成功",
              text: "即將返回列表",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "EventItem",
                  params: { EId: that.EventItemDetailData.Event.EId }
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
