import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RatingHome from '../views/RatingHome.vue'
import Register from '../views/Register.vue'
import LoginForUser from '../views/LoginForUser.vue'
import LoginForReviewer from '../views/LoginForReviewer.vue'
import ApplyManager from '../views/ApplyManager.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Forget from '../views/Forget.vue'
import Event from '../views/Event.vue'
import BaseInfo from '../views/BaseInfo.vue'
import EventItem from '../views/EventItem.vue'
import EventItemDetail_1 from '../views/EventItemDetail_1.vue'
import EventItemDetail_2 from '../views/EventItemDetail_2.vue'
import EventItemDetail_3 from '../views/EventItemDetail_3.vue'
import EventItemDetail_4 from '../views/EventItemDetail_4.vue'
import ReqDoc from '../views/ReqDoc.vue'
import Rating from '../views/Rating.vue'
import RatingDetail from '../views/RatingDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'RatingHome',
    component: RatingHome
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register
  },
  {
    path: '/LoginForUser',
    name: 'LoginForUser',
    component: LoginForUser
  },
  {
    path: '/LoginForReviewer',
    name: 'LoginForReviewer',
    component: LoginForReviewer
  },
  {
    path: '/ApplyManager',
    name: 'ApplyManager',
    component: ApplyManager
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/Forget',
    name: 'Forget',
    component: Forget
  },
  {
    path: '/Event/:EId',
    name: 'Event',
    component: Event
  },
  {
    path: '/BaseInfo/:EId',
    name: 'BaseInfo',
    component: BaseInfo
  },
  {
    path: '/EventItem/:EId',
    name: 'EventItem',
    component: EventItem
  },
  {
    path: '/EventItemDetail_1/:EId/:EIId/:EIDId?',
    name: 'EventItemDetail_1',
    component: EventItemDetail_1
  },
  {
    path: '/EventItemDetail_2/:EId/:EIId/:EIDId?',
    name: 'EventItemDetail_2',
    component: EventItemDetail_2
  },
  {
    path: '/EventItemDetail_3/:EId/:EIId/:EIDId?',
    name: 'EventItemDetail_3',
    component: EventItemDetail_3
  },
  {
    path: '/EventItemDetail_4/:EId/:EIId/:EIDId?',
    name: 'EventItemDetail_4',
    component: EventItemDetail_4
  },
  {
    path: '/BaseInfoEdit/:EId',
    name: 'BaseInfoEdit',
    component: BaseInfo
  },
  {
    path: '/ReqDoc/:EId',
    name: 'ReqDoc',
    component: ReqDoc
  },
  {
    path: '/Rating/:RId',
    name: 'Rating',
    component: Rating
  },
  {
    path: '/RatingDetail/:RId/:URId',
    name: 'RatingDetail',
    component: RatingDetail
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
