<template>
  <!-- 頭 -->
  <nav class="navbar navbar-expand-lg" v-if="HeadData">
    <div class="container">
      <router-link to="/" class="navbar-brand" v-if="HeadData.Project">
        {{ HeadData.Project.Name }}
      </router-link>
      <router-link to="/" class="navbar-brand" v-else>
        尚未開放
      </router-link>
      <template v-if="HeadData.Project">
        <div class="btn_box" v-if="HeadData.User">
          <router-link :to="{ name: 'ApplyManager' }" tag="button" v-if="!HeadData.User.IsReviewer">報名管理</router-link>
          <router-link :to="{ name: 'ResetPassword' }" tag="button">修改密碼</router-link>
          <button @click="logout()">登出</button>
        </div>
        <div class="btn_box" v-else>
          <router-link :to="{ name: 'Register' }" tag="button" v-if="HeadData.CanReg">會員註冊</router-link>
          <router-link :to="{ name: 'LoginForUser' }" tag="button">會員登入</router-link>
          <router-link :to="{ name: 'LoginForReviewer' }" tag="button">委員登入</router-link>
        </div>
      </template>
    </div>
    <div class="floating">
      <div class="circle">
        <button @click="gotoTop()">TOP</button>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Head",
  components: {
  },
  data() {
    return {
      HeadData: null,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
        {
          params: {
            token: $cookies.get("is_login_token"),
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.HeadData = response.data;
        if ($cookies.isKey("is_login_token") && !this.HeadData.User) {
          // 帳號在其他地方登入過
          Swal.fire({
            title: "帳號已登出",
            text: "您已在其他地方進行登入",
            icon: "info",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
            willClose: () => {
              $cookies.remove("is_login_token");
              if (this.$route.fullPath == "/") {
                location.reload();
              } else {
                this.$router.push({
                  name: "Home",
                });
              }
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
    $("html body").scroll(function () {
      var N = $("#app").scrollTop();
      if (N > 600) {
        $('.floating .circle button').show();
      }
      else {
        $('.floating .circle button').hide();
      };
    });
  },
  methods: {
    changeLang(lang) {
      $cookies.set("is_site_lang", lang, "30d");
      location.reload();
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    },
    logout: function () {
      $cookies.remove("is_login_token");
      let that = this;
      Swal.fire({
        title: "登出成功",
        text: "即將返回首頁",
        icon: "success",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500,
        willClose: () => {
          if (that.$route.fullPath == "/") {
            location.reload();
          } else {
            that.$router.push({
              name: "Home",
            });
          }
        },
      });
    },
    gotoTop() {
      $("html,body").animate({ scrollTop: 0 }, 1000);
    }
  },
};
</script>