<template>
  <!-- 尾 -->
  <footer v-if="FooterData">
    <div class="" v-if="!FooterData.Footer_Text">
      <ul class="text_box">
        <li>
          <p>主辦單位</p>
          <p>經濟部商業發展署</p>
        </li>
        <li>
          <p>承辦單位</p>
          <p>財團法人中國生產力中心</p>
        </li>
      </ul>
      <p>
        版權所有©經濟部商業發展署　請尊重智慧財產權．勿任意轉載違者依法必究。
        <br>
        為維持本網站的最佳瀏覽品質,請勿使用IE瀏覽器。
      </p>
    </div>
    <div class="" v-if="FooterData.Footer_Text" v-html="FooterData.Footer_Text"></div>
  </footer>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name: "Footer",
  data() {
    return {
      FooterData: null,
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Footer",
        {
          params: {
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.FooterData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
  },
};
</script>
