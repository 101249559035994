<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">修改密碼</li>
      </ol>
    </div>

    <div class="wrapper sign_up register change_password">
      <section class="main">
        <div class="container">
          <h1>修改密碼</h1>
          <div class="box">
            <div class="form-group">
              <label for="">舊密碼</label>
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入舊密碼" v-model="OldPassword">
            </div>
            <div class="form-group">
              <label for="">新密碼</label>
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請輸入新密碼" v-model="Password">
            </div>
            <div class="form-group">
              <label for="">確認新密碼</label>
              <input type="password" class="form-control" id="" aria-describedby="" placeholder="請再次輸入新密碼" v-model="PasswordConfirm">
            </div>
            <div class="btn_box">
              <button @click="changePassword()">送　　出</button>
            </div>
          </div>
          <hr>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ChangePassword",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      MemberData: null,
      local: "zh_tw",
      OldPassword: "",
      Password: "",
      PasswordConfirm: "",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Member",
        {
          params: {
            token: $cookies.get("is_login_token")
          },
        }
      )
      .then((response) => {
        this.MemberData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    async getRecaptchaToken() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      return token;
    },
    async changePassword() {
      if ((this.OldPassword == "")) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入舊密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if ((this.Password == "")) {
        Swal.fire({
          title: "錯誤",
          text: "請輸入新密碼",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (this.PasswordConfirm != this.Password) {
        Swal.fire({
          title: "錯誤",
          text: "兩次輸入新密碼必須相同",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 我不是機器人
      let recaptchaToken = await this.getRecaptchaToken();
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 組合並送出
      let token = AES.encrypt(this.MemberData.User.Account + "|" + this.OldPassword + "|" + this.Password + "|" + recaptchaToken);
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ResetPassword",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            Swal.fire({
              title: "重設密碼完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

