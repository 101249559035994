<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item active" aria-current="page" v-if="EventData.Event">{{ EventData.Event.Name }}</li>
      </ol>
    </div>
    <div class="wrapper list_main" v-if="EventData.Event">
      <section class="main">
        <div class="container">
          <div class="title_box">
            <div class="img_box">
              <div class="img">
                <img v-if="!EventData.Event.Image" src="img/banner_1.jpg" alt="">
                <img v-if="EventData.Event.Image" :src="EventData.SiteUrl + EventData.Event.Image" alt="">
              </div>
            </div>
            <div class="title">
              <div class="text">
                <h1>{{ EventData.Event.Name }}</h1>
                <p v-if="EventData.Event.SimpleContent">{{ EventData.Event.SimpleContent }}
                </p>
              </div>
              <!-- <router-link v-if="!this.EventData.HasUserInfo"
                :to="{ name: 'BaseInfo', params: { EId: EventData.Event.EId } }" tag="button">我要報名</router-link>
              <router-link v-if="this.EventData.HasUserInfo"
                :to="{ name: 'EventItem', params: { EId: EventData.Event.EId } }" tag="button">我要報名</router-link> -->
              <router-link :to="{ name: 'BaseInfo', params: { EId: EventData.Event.EId } }"
                tag="button" v-if="EventData.Event.IsEnable">我要報名</router-link>
            </div>
          </div>
          <hr>
          <ul class="btn_box">
            <li><button v-if="EventData.Event.PetitionForm" @click="TabIndex = 1"
                :class="TabIndex == 1 ? 'active' : ''">申請資格</button></li>
            <li><button v-if="EventData.Event.SelectionConditions" @click="TabIndex = 2"
                :class="TabIndex == 2 ? 'active' : ''">徵選條件</button></li>
            <li><button v-if="EventData.Event.AcceptanceTime" @click="TabIndex = 3"
                :class="TabIndex == 3 ? 'active' : ''">受理時間</button></li>
            <li><button v-if="EventData.Event.WaysOfRegistration" @click="TabIndex = 4"
                :class="TabIndex == 4 ? 'active' : ''">報名方式</button></li>
            <li><button v-if="EventData.Event.Marketing" @click="TabIndex = 5"
                :class="TabIndex == 5 ? 'active' : ''">行銷宣傳</button></li>
            <li><button v-if="Downloads" @click="TabIndex = 6" :class="TabIndex == 6 ? 'active' : ''">下載專區</button></li>
            <li><button v-if="EventData.Event.RegistrationContactInquiry" @click="TabIndex = 7"
                :class="TabIndex == 7 ? 'active' : ''">報名聯絡洽詢</button></li>
            <li><button v-if="EventData.Event.ConsultingServiceHours" @click="TabIndex = 8"
                :class="TabIndex == 8 ? 'active' : ''">諮詢服務時間</button></li>
          </ul>
          <div class="box show" v-if="EventData.Event.PetitionForm && TabIndex == 1">
            <div class="text" v-html="EventData.Event.PetitionForm">
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.SelectionConditions && TabIndex == 2">
            <div class="text" v-html="EventData.Event.SelectionConditions">
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.AcceptanceTime && TabIndex == 3">
            <div class="text" v-html="EventData.Event.AcceptanceTime">
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.WaysOfRegistration && TabIndex == 4">
            <div class="text" v-html="EventData.Event.WaysOfRegistration">
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.Marketing && TabIndex == 5">
            <div class="text" v-html="EventData.Event.Marketing">
            </div>
          </div>
          <div class="box show" v-if="Downloads && TabIndex == 6">
            <div class="text">
              <ul>
                <li v-for="( Download, index ) in  Downloads " :key="index">
                  <p><span>{{ Download.DisplayName }}</span> <a :href="EventData.SiteUrl +
          '/Platform/Html/Download?FId=' +
          Download.FId
          " target="_blank" :title="'下載' + Download.DisplayName + ', 另開新視窗'">[點我下載]</a></p>
                </li>
              </ul>
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.RegistrationContactInquiry && TabIndex == 7">
            <div class="text" v-html="EventData.Event.RegistrationContactInquiry">
            </div>
          </div>
          <div class="box show" v-if="EventData.Event.ConsultingServiceHours && TabIndex == 8">
            <div class="text" v-html="EventData.Event.ConsultingServiceHours">
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Event",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      EventData: {},
      Downloads: null,
      TabIndex: 1,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Event",
        {
          params: { EId: to.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventData = response.data;
        if (this.EventData.Event.Downloads != "[]") {
          this.Downloads = JSON.parse(this.EventData.Event.Downloads);
        } else {
          this.Downloads = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Event",
        {
          params: { EId: this.$route.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventData = response.data;
        if (this.EventData.Event.Downloads != "[]") {
          this.Downloads = JSON.parse(this.EventData.Event.Downloads);
        } else {
          this.Downloads = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
  },
};
</script>
