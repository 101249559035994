<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item active" aria-current="page" v-if="RatingDetailData.Rating">{{
          RatingDetailData.Rating.RatingName
          }}</li>
        <li class="breadcrumb-item active" aria-current="page" v-if="RatingDetailData.RatingUser">{{
          RatingDetailData.RatingUser.Name }}</li>
      </ol>
    </div>

    <div class="wrapper restaurant_introduction" v-if="RatingDetailData.Rating">
      <section class="main">
        <div class="container">
          <div class="box">
            <h1>{{ RatingDetailData.Rating.RatingName }}</h1>
            <div class="btn_box">
              <button :class="TabIndex == 1 ? 'active' : ''" @click="geteTab(1)">店家介紹</button>
              <button :class="TabIndex == 2 ? 'active' : ''" @click="geteTab(2)">基本資料</button>
              <button :class="TabIndex == 3 ? 'active' : ''" @click="geteTab(3)"
                v-if="RatingDetailData.HasEventItem">報名品項</button>
              <button :class="TabIndex == 4 ? 'active' : ''" @click="geteTab(4)"
                v-if="RatingDetailData.HasEventItem">觀光潛力與實績</button>
              <button :class="TabIndex == 6 ? 'active' : ''" @click="geteTab(6)"
                v-if="!RatingDetailData.HasEventItem">輔導單位資料</button>
              <button :class="TabIndex == 7 ? 'active' : ''" @click="geteTab(7)"
                v-if="!RatingDetailData.HasEventItem">計畫申請摘要</button>
              <button :class="TabIndex == 5 ? 'active' : ''" @click="geteTab(5)">評分結果<span
                  class="pencil"></span></button>
              <button @click="gotoRatingByRId(RatingDetailData.Rating.RId)">返回列表<span class="return"></span></button>
            </div>
            <hr>
            <!-- 店家介紹 -->
            <div class="text_box" :class="TabIndex == 1 ? 'show' : ''">
              <slick ref="slick_main" class="carousel" :options="{
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                  {
                    breakpoint: 1199.98,
                    settings: {
                      slidesToShow: 1,
                    }
                  },
                ]
              }">
                <div class="" v-for="(Photo, index) in RatingDetailData.Photos">
                  <div class="img_box" > <!-- v-viewer -->
                    <img :src="RatingDetailData.SiteUrl + Photo" alt="">
                  </div>
                </div>
              </slick>
              <div class="bg_box">
                <div class="title">
                  <h2>{{ RatingDetailData.RatingUser.Name }}</h2>
                  <p><span>登記名稱</span>{{ RatingDetailData.RatingUser.UserInfo.P1_CompanyName }}</p>
                  <p><span>品牌名稱</span>{{ RatingDetailData.RatingUser.UserInfo.P1_BrandName }}</p>
                  <p><span>負責人</span>{{ RatingDetailData.RatingUser.UserInfo.P1_PrincipalName }}</p>
                  <p>店家(品牌)名稱商標註冊狀況：</p>
                  <p class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandNameTrademarkRegStatus == 0">尚未申請
                  </p>
                  <p class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandNameTrademarkRegStatus == 1">已申請使用
                  </p>
                  <p class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandNameTrademarkRegStatus == 2">
                    申請中，送件日期 {{
                      RatingDetailData.RatingUser.UserInfo.P1_SubmDate | timeString("YYYY/MM/DD") }}</p>
                </div>
                <!-- <div class="tag">
                  <p>
                    <img src="/img/icon_1.svg" alt="">
                    環保餐具 我們即將在台積電員工餐廳的分店 使用 環保餐具租借
                  </p>
                </div>
                <div class="title">
                  <p><span>店長</span>穆惠琴</p>
                </div> -->
              </div>
              <hr>
              <div class="">
                <ul class="list">
                  <li>
                    <p>食品業者登錄字號</p>
                    <p class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_FoodRegNumber }}</p>
                  </li>
                  <li>
                    <p>總部/聯絡地址</p>
                    <p class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ContactAddress }}</p>
                  </li>
                  <li v-if="!RatingDetailData.RatingUser.UserInfo.P1_BusinessSameForContact">
                    <p>營業地址</p>
                    <p class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_BusinessAddress }}</p>
                  </li>
                  <!-- <li>
                    <p>營業電話</p>
                    <p>04-23921773 #</p>
                  </li> -->
                  <li>
                    <p>營業時間</p>
                    <p>平日：{{ RatingDetailData.RatingUser.UserInfo.P1_WeekdayOpeningTime }}~{{
                      RatingDetailData.RatingUser.UserInfo.P1_WeekdayClosingTime }} /
                      <!-- <br> -->
                      假日：{{ RatingDetailData.RatingUser.UserInfo.P1_HolidayOpeningTime }}~{{
                        RatingDetailData.RatingUser.UserInfo.P1_HolidayClosingTime }} /
                      <!-- <br> -->
                      公休日：{{ RatingDetailData.RatingUser.UserInfo.P1_DayOff }}
                    </p>
                  </li>
                  <!-- <li>
                    <p>營業傳真</p>
                    <p>無</p>
                  </li> -->
                  <!-- <li>
                    <p>E-mail</p>
                    <p>noodle.mix2014@gmail.com</p>
                  </li> -->
                  <li v-if="RatingDetailData.RatingUser.UserInfo.P1_OfficialWebsite">
                    <p>營業網址</p>
                    <p>{{ RatingDetailData.RatingUser.UserInfo.P1_OfficialWebsite }}</p>
                  </li>
                </ul>
              </div>
              <template v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStory">
                <hr>
                <div class="box_h3">
                  <h3>品牌故事</h3>
                </div>
                <div class="bg_box">
                  <p class="text">{{ RatingDetailData.RatingUser.UserInfo.P1_BrandStory }}</p>
                </div>
              </template>
            </div>
            <!-- 基本資料 -->
            <div class="text_box" :class="TabIndex == 2 ? 'show' : ''">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ RatingDetailData.RatingUser.Name }}</h2>
                </div>
                <div class="tag" v-if="RatingDetailData.ColA.P1_13">
                  <p>
                    <img src="/img/icon_1.svg" alt="">
                    營業{{ RatingDetailData.TodayYear - RatingDetailData.RatingUser.UserInfo.P1_EstablishmentYear }}年
                  </p>
                </div>
              </div>
              <hr>
              <div class="box_h3">
                <h3>基本資料</h3>
              </div>
              <div class="">
                <ul class="list">
                  <li v-if="RatingDetailData.ColA.P1_1 ||
                    RatingDetailData.ColA.P1_2 ||
                    RatingDetailData.ColA.P1_3 ||
                    RatingDetailData.ColA.P1_4 ||
                    RatingDetailData.ColA.P1_5 ||
                    RatingDetailData.ColA.P1_6 ||
                    RatingDetailData.ColA.P1_7 ||
                    RatingDetailData.ColA.P1_8 ||
                    RatingDetailData.ColA.P1_9 ||
                    RatingDetailData.ColA.P1_10 ||
                    RatingDetailData.ColA.P1_11
                  ">
                    <p v-if="RatingDetailData.ColA.P1_1">統一編號：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_TaxID
                        }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_2">總部、其他門市/分店或其他分公司之統一編號是否相同：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_SameUnifiedNumber ? '是' : '否' }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_3">食品業者登錄字號：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_FoodRegNumber }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_4">公司登記名稱：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_CompanyName }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_5">品牌/餐廳名稱：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_BrandName }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_6">品牌/餐廳英文名稱：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_BrandNameEN }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_44">資本額：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_CapitalAmount }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_44">在職人數：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_NumberOfEmployees }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_7">負責人姓名：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_PrincipalName }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_8">負責人職稱：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_PrincipalJobName }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_9">創立時間：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_FoundingTime | timeString("YYYY/MM/DD") }}</span></p>
                    <p v-if="RatingDetailData.ColA.P1_11">總部/聯絡地址：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_ContactAddress }}</span></p>
                    <p
                      v-if="RatingDetailData.ColA.P1_10 && !RatingDetailData.RatingUser.UserInfo.P1_BusinessSameForContact">
                      營業地址：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_BusinessAddress }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_12">
                    <p>經營型態</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_BusinessType == 1"><span class="red">連鎖，共{{
                      RatingDetailData.RatingUser.UserInfo.P1_NumberOfStores }}家</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_BusinessType == 2"><span class="red">多店，共{{
                      RatingDetailData.RatingUser.UserInfo.P1_NumberOfStores }}家</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_BusinessType == 3"><span class="red">單店</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_13">
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OperationalHistory == 1">經營歷程：第一代</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OperationalHistory == 2">經營歷程：第二代</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OperationalHistory == 3">經營歷程：第三代</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OperationalHistory == 4">經營歷程：{{
                      RatingDetailData.RatingUser.UserInfo.P1_OperationalHistoryOtherContent }}</p>
                    <p>
                      自民國年 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_EstablishmentYear }}</span>
                      開店，至今共營業<span class="red">{{ RatingDetailData.TodayYear -
                        RatingDetailData.RatingUser.UserInfo.P1_EstablishmentYear
                        }}</span> 年。
                      <br>
                      平均日來客數：假日 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_AvgHolidayCustomers
                        }}</span> 人 / 非假日
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_AvgNonHolidayCustomers }}</span> 人
                      <br>
                      平均日營業額：假日 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_AvgHolidayRevenue }}</span>
                      元 / 非假日
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_AvgNonHolidayRevenue }}</span> 元
                      <br>
                      現階段主要客源：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CurrentMainCustomerSource
                        }}</span>
                      <br>
                      未來期待開拓客源：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_ExpectedCustomerSourceExpansion
                        }}</span>
                    </p>
                    <template v-if="RatingDetailData.ColA.P1_42">
                      <p>座位數：{{ RatingDetailData.RatingUser.UserInfo.P1_SeatingCapacity }}</p>
                      <p>是否有友善空間</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AccessibleSpace"><span class="red">無障礙空間</span>
                      </p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FreeWIFI"><span class="red">免費WIFI</span>
                      </p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FriendlyChargingStation"><span
                          class="red">友善充電座</span>
                      </p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_BabyChair"><span class="red">嬰兒座椅</span>
                      </p>
                      <p
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_OtherFriendlySpaces && RatingDetailData.RatingUser.UserInfo.P1_OtherFriendlySpacesContent">
                        <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_OtherFriendlySpacesContent
                          }}</span>
                      </p>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_43">
                    <p>未來三年發展目標</p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ExpandNewStore">
                      拓展新店面：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpandNewStoreContent }}</span>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_LaunchNewProductOrMarketingScheme">
                      推出新產品/新行銷方案：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_LaunchNewProductOrMarketingSchemeContent }}</span>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ExpandECommerceChannels">
                      拓展電商通路：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpandECommerceChannelsContent
                        }}</span>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_InvestInOtherVentures">
                      轉投資其他事業：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_InvestInOtherVenturesContent
                        }}</span>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_OtherDevGoals">
                      其他：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_OtherDevGoalsContent }}</span>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_14">
                    <p>員工概況</p>
                    <p>正職人員： <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_PermanentStaff }}</span> 位 /
                      兼職人員： <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_PartTimeStaff }}</span> 位 /
                      總人數： <span class="red">{{
                        TotalStaff }}</span> 位</p>
                    <p>內場： <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_Infield }}</span> 位 (<span
                        class="red">{{
                          InfieldPercent }}%</span>) / 外場： <span class="red">{{
                          RatingDetailData.RatingUser.UserInfo.P1_Outfield
                        }}</span> 位 (<span class="red">{{ OutfieldPercent }}%</span>)</p>
                    <p>男性： <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_Male }}</span> 位 (<span
                        class="red">{{
                          MalePercent }}%</span>) / 女性： <span class="red">{{
                          RatingDetailData.RatingUser.UserInfo.P1_Female }}</span>
                      位 (<span class="red">{{ FemalePercent }}%</span>)</p>
                    <p>中高年齡員工： <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_MiddleAged }}</span> 位</p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_41">
                    <p>經費說明</p>
                    <p>輔導總經費：新臺幣 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_TotalGuidanceFunds
                        }}</span>
                      元（政府輔導款+廠商配合款）</p>
                    <p>政府輔導款：新臺幣 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_GovtAidFunds }}</span>
                      元（每案上限為新臺幣25萬元）
                    </p>
                    <p>廠商配合款：新臺幣 <span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_ManufacturerContributionFunds
                        }}</span> 元（不可少於政府輔導款金額1/2）</p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_15">
                    <p>品牌官網（品牌官方網站或社群平台等）</p>
                    <p><span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_OfficialWebsite }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_16">
                    <p>相關標章及證明</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertTaiwanRice"><span class="red">臺灣米標章</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertCAS_HACPP"><span class="red">CAS/HACCP
                        認證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertGHP"><span class="red">餐飲衛生管理評核良級以上</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertFoodSafety"><span
                        class="red">相關餐飲食品安全衛生佐證/標章</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertECO"><span class="red">綠色餐廳認證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertHalal"><span class="red">清真認證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MaterialCert"><span class="red">食材證明</span></p>
                    <p
                      v-if="RatingDetailData.RatingUser.UserInfo.P1_CertOther && RatingDetailData.RatingUser.UserInfo.P1_CertOtherContent">
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CertOtherContent }}</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CertFile.includes('Upload')">相關標章及證明（截圖或影本）</p>
                    <div > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P1_CertFile.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_CertFile"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>                    
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_17 ||
                    RatingDetailData.ColA.P1_18 ||
                    RatingDetailData.ColA.P1_19 ||
                    RatingDetailData.ColA.P1_20">
                    <template v-if="RatingDetailData.ColA.P1_17">
                      <p>門面照</p>
                      <div class="row">
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto2.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto2"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto3.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_FacadePhoto3"
                            style="width: 100%; height: auto;" />
                        </div>
                      </div>
                    </template>
                    <template v-if="RatingDetailData.ColA.P1_18">
                      <p>包廂空間照</p>
                      <div class="row">
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto2.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto2"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto3.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BoothSpacePhoto3"
                            style="width: 100%; height: auto;" />
                        </div>
                      </div>
                    </template>
                    <template v-if="RatingDetailData.ColA.P1_19">
                      <p>用餐空間照</p>
                      <div class="row">
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto2.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto2"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto3.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_DiningAreaPhoto3"
                            style="width: 100%; height: auto;" />
                        </div>
                      </div>
                    </template>
                    <template v-if="RatingDetailData.ColA.P1_20">
                      <p>內場環境照</p>
                      <div class="row">
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto2.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto2"
                            style="width: 100%; height: auto;" />
                        </div>
                        <div class="col-4" > <!-- v-viewer -->
                          <img v-if="RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto3.includes('Upload')"
                            :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_EnvironmentalPhoto3"
                            style="width: 100%; height: auto;" />
                        </div>
                      </div>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_39">
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_GuidanceType == 1">參與輔導類別：<span
                        class="red">組合型</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_GuidanceType == 2">參與輔導類別：<span
                        class="red">供應鏈型</span></p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ProductDevGuidance">
                      <p>產品開發試銷輔導</p>
                      <p>預計開發之產品數量：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpectedProductQty
                          }}</span></p>
                      <p>預計開發之產品名稱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpectedProductName
                          }}</span></p>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ChannelExpGuidance">
                      <p>通路拓展輔導</p>
                      <p>預計開發通路之國家/地區：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_ExpectedChannelCountry }}</span></p>
                      <p>預計開發通路名稱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpectedChannelName
                          }}</span></p>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ExpectedService">
                      <p>服務拓展輔導</p>
                      <p>預計開發服務名稱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpectedServiceName
                          }}</span></p>
                      <p>預計開發服務之國家/地區：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_ExpectedServiceTargetCountry }}</span></p>
                    </template>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_ExpectedBusinessModel">
                      <p>商模拓展輔導</p>
                      <p>預計開發商模名稱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_ExpectedBusinessModelName
                          }}</span></p>
                      <p>預計開發商模之國家/地區：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_ExpectedBusinessModelTargetCountry }}</span></p>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_40">
                    <p>政府政策配合證明(加分項目)：</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_RecentSalaryIncreases"><span
                        class="red">近2年實施加薪</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_PromoteGenderDiversity"><span
                        class="red">推動友善多元性別</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_EmploymentOfMiddleAgedWorkers"><span
                        class="red">僱用中高齡年齡就業者</span></p>
                    <p
                      v-if="RatingDetailData.RatingUser.UserInfo.P1_OtherGovtPolicyCompliance && RatingDetailData.RatingUser.UserInfo.P1_OtherGovtPolicyComplianceContent">
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_OtherGovtPolicyComplianceContent
                        }}</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile.includes('Upload')">
                      政府政策配合證明附件-1
                    </p>
                    <div > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>                    
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile2.includes('Upload')">
                      政府政策配合證明附件-2
                    </p>
                    <div > <!-- v-viewer -->
                      <img
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile2.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile2"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile3.includes('Upload')">
                      政府政策配合證明附件-3
                    </p>
                    <div > <!-- v-viewer -->
                      <img
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile3.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_GovtPolicyComplianceProofFile3"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>
                  </li>
                  <li
                    v-if="RatingDetailData.ColA.P1_21 &&
                      (RatingDetailData.RatingUser.UserInfo.P1_MediaReport || RatingDetailData.RatingUser.UserInfo.P1_MediaReportUrl || RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile.includes('Upload'))">
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReport">媒體報導</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReport"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_MediaReport }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReportUrl">媒體報導網址</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReportUrl"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_MediaReportUrl }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile.includes('Upload')">媒體報導畫面</p>
                    <div > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>                    
                  </li>
                  <li
                    v-if="RatingDetailData.ColA.P1_22 &&
                      (RatingDetailData.RatingUser.UserInfo.P1_AwardRecord || RatingDetailData.RatingUser.UserInfo.P1_AwardRecordUrl || RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile.includes('Upload'))">
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecord">獲獎紀錄</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecord"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_AwardRecord }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecordUrl">獲獎紀錄網址</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecordUrl"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_AwardRecordUrl }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile.includes('Upload')">獲獎紀錄資料</p>
                    <div > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile.includes('Upload')"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile"
                        style="width: 100%; max-width: 300px; height: auto;" />
                    </div>                    
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_23">
                    <p>品牌介紹</p>
                    <p><span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_BrandStory }}</span></p>
                    <div class="row">
                      <div class="col-4" > <!-- v-viewer -->
                        <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile.includes('Upload')"
                          :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile"
                          style="width: 100%; height: auto;" />
                      </div>
                      <div class="col-4" > <!-- v-viewer -->
                        <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile2.includes('Upload')"
                          :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile2"
                          style="width: 100%; height: auto;" />
                      </div>
                      <div class="col-4" > <!-- v-viewer -->
                        <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile3.includes('Upload')"
                          :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile3"
                          style="width: 100%; height: auto;" />
                      </div>
                      <div class="col-4" > <!-- v-viewer -->
                        <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile4.includes('Upload')"
                          :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile4"
                          style="width: 100%; height: auto;" />
                      </div>
                      <div class="col-4" > <!-- v-viewer -->
                        <img v-if="RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile5.includes('Upload')"
                          :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_BrandStoryFile5"
                          style="width: 100%; height: auto;" />
                      </div>
                    </div>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_24">
                    <p>目前餐飲業者曝光行銷方式與宣傳管道</p>
                    <p><span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_MarketingChannels }}</span></p>
                    <p>餐飲業者使用哪些網路與社群行銷平台</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCOfficialWebsite"><span class="red">官網</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCFacebook"><span class="red">Facebook</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCInstagram"><span class="red">Instagram</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCECommercePlatform"><span class="red">電商平台</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCBlog"><span class="red">部落格</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCOnlineAdvertising"><span
                        class="red">網路廣告（關鍵字、廣告投放）</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MCLINEAt"><span class="red">Line@</span></p>
                    <p
                      v-if="RatingDetailData.RatingUser.UserInfo.P1_MCOther && RatingDetailData.RatingUser.UserInfo.P1_MCOtherContent">
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_MCOtherContent }}</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OnlineAndSocialMediaMarketingApproach">
                      請舉例目前網路與社群行銷經營方式說明</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OnlineAndSocialMediaMarketingApproach"><span
                        class="red">{{
                          RatingDetailData.RatingUser.UserInfo.P1_OnlineAndSocialMediaMarketingApproach }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_25">
                    <p>是否遭遇品牌經營、行銷瓶頸等相關問題：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_FacingBrandManagementOrMarketingChallenges ? '是' : '否'
                        }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FacingBrandManagementOrMarketingChallenges">問題簡述
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FacingBrandManagementOrMarketingChallenges"><span
                        class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_DescriptionOfChallenges }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_26">
                    <p>是否具有說菜能力：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_HasMenuDescriptionAbility ?
                      '是' : '否'
                        }}</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_HasMenuDescriptionAbility">請說明如何進行說菜</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_HasMenuDescriptionAbility"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_MenuDescriptionProcedure }}</span></p>
                    <p v-if="!RatingDetailData.RatingUser.UserInfo.P1_HasMenuDescriptionAbility">是否有意願增進店內說菜能力：<span
                        class="red">{{
                          RatingDetailData.RatingUser.UserInfo.P1_WillingToImproveMenuDescriptionAbility ? '是' : '否'
                        }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_27">
                    <p>觀光潛力</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_Attractions">鄰近著名觀光景點</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_Attractions"><span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_Attractions }}</span></p>
                    <p>國際旅客比例：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_InternationalProportion
                        }}%</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_InternationalMain">主要旅客國家為：<span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_InternationalMain }}</span></p>
                    <p>店內菜單語言</p>
                    <p><span class="red">中文</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MenuEN"><span class="red">英文</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MenuJP"><span class="red">日文</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_MenuKR"><span class="red">韓文</span></p>
                    <p
                      v-if="RatingDetailData.RatingUser.UserInfo.P1_MenuOther && RatingDetailData.RatingUser.UserInfo.P1_MenuOtherContent">
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_MenuOtherContent }}</span>
                    </p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_28">
                    <p>平日營業時間（週一至週五）：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_WeekdayOpeningTime
                        }}~{{
                          RatingDetailData.RatingUser.UserInfo.P1_WeekdayClosingTime }}</span></p>
                    <p>假日營業時間（週六及週日）：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_HolidayOpeningTime
                        }}~{{
                          RatingDetailData.RatingUser.UserInfo.P1_HolidayClosingTime }}</span></p>
                    <p>公休日：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_DayOff }}</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_29">
                    <p>相關活動參與意願調查</p>
                    <p>輔導</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_ProductDevelopmentGuidance"><span
                        class="red">產品開發試銷輔導</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_ChannelExpansionGuidance"><span
                        class="red">通路拓展輔導</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_SingleStoreOptimizationGuidance"><span
                        class="red">單店優化輔導</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_DemonstrationApplicationGuidance"><span
                        class="red">示範應用輔導</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodServiceSystemGuidance"><span
                        class="red">餐飲體系輔導</span></p>
                    <p>國際拓展</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_InternationalStudyExchange"><span
                        class="red">國際考察交流活動</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_InternationalMediaMatching"><span
                        class="red">國際媒合會</span></p>
                    <p>行銷</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_OnlineMarketingActivities"><span
                        class="red">網實行銷活動</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_TaiwanFoodExpo"><span class="red">台灣美食展</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_JointAchievementExpo"><span
                        class="red">聯合成果展</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_30">
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_StoreOwnership == 1">店面所有權：<span
                        class="red">店面自有</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_StoreOwnership == 2">店面所有權：<span
                        class="red">店面租賃，已租賃使用達{{
                          RatingDetailData.RatingUser.UserInfo.P1_LeaseDurationInYears }}年</span></p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_31">
                    <p>是否有設置中央廚房/工廠：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_HasCentralKitchen ? '有'
                      : '無'
                        }}</span></p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_HasCentralKitchen">
                      <p>共 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKAmount }}</span> 間</p>
                      <p>中央工廠分布地點：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKLocalName }}</span></p>
                      <p>各座中央廚房/工廠平均產能（換算餐盒/食材/原料數量）：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_CKCapacity }}</span>份/月</p>
                      <p>是否自有配送車輛：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKHasDeliveryVehicle ?
                        '有' : '無' }}</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CKHasDeliveryVehicle">共 <span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_CKDeliveryVehicleAmount }}</span> 輛</p>
                      <p>餐點是以何種形式盛裝：
                        <span class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_CKPackType == 1">桶/箱裝</span>
                        <span class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_CKPackType == 2">餐盒裝</span>
                      </p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CKPackType == 2">桶/箱裝收方式：<span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_CKPackRecycle }}</span></p>
                      <p>餐點供應範圍 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKScopeOfSupply }}</span>
                        km</p>
                      <p>每車每趟送運配置人力（不含司機）約 <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKManpower
                          }}</span> 位</p>
                      <p>餐點是否需要再加熱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_CKNeedReheat ? '是' : '否'
                          }}</span></p>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_32">
                    <p>調理產品檢驗：
                      <span class="red"
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_CookingProductStatus == 0">目前尚無調理產品</span>
                      <span class="red"
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_CookingProductStatus == 1">現有調理產品皆尚未經過相關檢驗</span>
                      <span class="red"
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_CookingProductStatus == 2">調理產品已進行最終產品檢驗</span>
                    </p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_CookingProductStatus == 2">
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_ComplianceWithFoodSafetyRegulations"><span
                          class="red">符合我國「食品安全衛生管理法」所訂食品相關衛生標準或相關規範</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_ComplianceWithExportStandards"><span
                          class="red">符合輸出國之產品檢驗標準及包裝標示規定</span></p>
                    </template>
                  </li>
                  <li
                    v-if="RatingDetailData.ColA.P1_33 && RatingDetailData.RatingUser.UserInfo.P1_CookingProductStatus != 0">
                    <p>製作調理產品之食品工廠：
                      <span class="red"
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodFactoryStatus == 0">目前尚無自有或委外製作調理包之食品工廠</span>
                      <span class="red"
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodFactoryStatus == 1">自有工廠</span>
                      <span class="red" v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodFactoryStatus == 2">代工廠</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodFactoryStatus == 1">共 <span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_OwnFactoryQuantity }}</span> 間</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FoodFactoryStatus == 2">合作廠商為 <span class="red">{{
                      RatingDetailData.RatingUser.UserInfo.P1_OEMFactoryCollaborators }}</span></p>
                    <p>上述工廠已通過檢驗</p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_ISO22000Certification"><span
                        class="red">ISO22000驗證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_HACCPCertification"><span
                        class="red">HACCP驗證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_HalalCertification"><span class="red">清真驗證</span>
                    </p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_CASExcellentAgriculturalProductMark"><span
                        class="red">CAS優良農產品標章</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_TQFTaiwanExcellentFoodCertification"><span
                        class="red">TQF台灣優良食品驗證</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_SQFCertification"><span class="red">SQF驗證(Safe
                        Quality Food
                        certification)</span></p>
                    <p v-if="RatingDetailData.RatingUser.UserInfo.P1_FSSF22000Certification"><span class="red">FSSF
                        22000驗證(Food
                        Safety System certification)</span></p>
                    <p
                      v-if="RatingDetailData.RatingUser.UserInfo.P1_OtherCertifications && RatingDetailData.RatingUser.UserInfo.P1_OtherCertificationsContent">
                      <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_OtherCertificationsContent }}</span>
                    </p>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_34">
                    <p>餐具使用情形</p>
                    <p>內用餐器具是否為「重複使用」餐具：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_Reuse ? '是' : '否'
                        }}</span></p>
                    <p>外帶是否使用「循環容器」之餐具：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_LoopContainer ? '是'
                      : '否'
                        }}</span></p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_LoopContainer">
                      <p>循環容器之材質：</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_LCSS"><span class="red">不鏽鋼</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_LCPYREX"><span class="red">耐熱玻璃</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_LCPLA"><span class="red">生物可分解塑膠</span></p>
                      <p
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_LCOther && RatingDetailData.RatingUser.UserInfo.P1_LCOtherContent">
                        <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_LCOtherContent }}</span>
                      </p>
                    </template>
                    <template v-if="!RatingDetailData.RatingUser.UserInfo.P1_LoopContainer">
                      <p>目前使用之盛裝品材質為：</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_NoLCRecyclable"><span class="red">回收標誌</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_NoLCSoyInk"><span class="red">使用大豆油墨</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_NoLCPehosphateFree"><span
                          class="red">包裝無磷膜</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_NoLCEcoMaterials"><span
                          class="red">使用紙類或木片、甘蔗、稻殼等環保材質</span></p>
                      <p
                        v-if="RatingDetailData.RatingUser.UserInfo.P1_NoLCOther && RatingDetailData.RatingUser.UserInfo.P1_NoLCOtherContent">
                        <span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_LCOtherContent }}</span>
                      </p>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_35">
                    <p>接受過政府輔導：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_HasGovGuidance ? '是' : '否'
                        }}</span></p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_HasGovGuidance">
                      <p>曾參與</p>
                      <p>計畫名稱：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_GovProjectName }}</span></p>
                      <p>政府輔導經費：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_GovFunding }}</span></p>
                      <p>輔導成效</p>
                      <p><span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_GovEffectiveness }}</span></p>
                    </template>
                  </li>
                  <li v-if="RatingDetailData.ColA.P1_36">
                    <p>國際拓展狀況：<span class="red">{{ RatingDetailData.RatingUser.UserInfo.P1_InternationalExpansionStatus
                      ? '是' : '否'
                        }}</span></p>
                    <template v-if="RatingDetailData.RatingUser.UserInfo.P1_InternationalExpansionStatus">
                      <p>已具有以下經驗：</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEOverseasStores">海外展店</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEOverseasStores"><span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_IEOverseasStoresContent }}</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEProductExports">產品輸出</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEProductExports"><span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_IEProductExportsContent }}</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IETechLicense">技術授權</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IETechLicense"><span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_IETechLicenseContent }}</span></p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEBrandLicense">品牌授權</p>
                      <p v-if="RatingDetailData.RatingUser.UserInfo.P1_IEBrandLicense"><span class="red">{{
                        RatingDetailData.RatingUser.UserInfo.P1_IEBrandLicenseContent }}</span></p>
                    </template>
                  </li>
                </ul>
              </div>
              <hr>
              <ul class="list" v-if="RatingDetailData.ColA.P1_37">
                <li>
                  <p>計劃主持人</p>
                  <p>
                    姓名：{{ RatingDetailData.RatingUser.UserInfo.P1_HostName }}
                    <br>
                    職稱：{{ RatingDetailData.RatingUser.UserInfo.P1_HostTitle }}
                    <br>
                    聯絡電話：{{ RatingDetailData.RatingUser.UserInfo.P1_HostPhone }}
                    <br>
                    行動電話：{{ RatingDetailData.RatingUser.UserInfo.P1_HostMobile }}
                    <br>
                    電子郵件：{{ RatingDetailData.RatingUser.UserInfo.P1_HostEmail }}
                  </p>
                </li>
              </ul>
              <ul class="list" v-if="RatingDetailData.ColA.P1_38">
                <li>
                  <p>聯絡人</p>
                  <p>
                    姓名：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactName }}
                    <br>
                    職稱：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactJobName }}
                    <br>
                    聯絡電話：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactTelphone }}
                    <br>
                    行動電話：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactPhone }}
                    <br>
                    LineID：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactLineID }}
                    <br>
                    電子郵件：{{ RatingDetailData.RatingUser.UserInfo.P1_ContactEmail }}
                  </p>
                </li>
              </ul>
            </div>
            <!-- 20240304 報名品項 -->
            <div class="text_box" :class="TabIndex == 3 ? 'show' : ''">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ RatingDetailData.RatingUser.Name }}</h2>
                </div>
              </div>
              <hr>
              <ul class="row list items_box">
                <li class="col-12 col-md-6" v-for="EventItemDetail in RatingDetailData.RatingUser.EventItemDetails"
                  :key="EventItemDetail.EIDId">
                  <div class="" v-if="EventItemDetail.EIId == 1">
                    <div class="img_box">
                      <img v-if="!EventItemDetail.T1_MealBoxOverallAndDishPhotos" src="/img/sample-2.png" alt="">
                      <div > <!-- v-viewer -->
                        <img v-if="EventItemDetail.T1_MealBoxOverallAndDishPhotos"
                        :src  ="RatingDetailData.SiteUrl + EventItemDetail.T1_MealBoxOverallAndDishPhotos" alt="">
                      </div>                      
                      <button type="button" class="" data-toggle="modal" data-target="#eventItemDetail_1_Modal"
                        @click="EventItemDetailShow = EventItemDetail">MORE ＋</button>
                    </div>
                    <h3>{{ EventItemDetail.T1_MealName }}</h3>
                    <hr>
                    <p>
                      {{ EventItemDetail.T1_Flavor }}
                    </p>
                    <textarea v-if="RatingDetailData.Rating.EventItemReview == 1" name="" id="" cols="0" rows="3"
                      placeholder="評審備註" v-model="ItemContent['EIDID_' + EventItemDetail.EIDId]"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended"></textarea>
                    <hr v-if="RatingDetailData.Rating.EventItemReview == 2">
                    <div class="form-group" v-if="RatingDetailData.Rating.EventItemReview == 2">
                      <label for="">評分分數</label>
                      <div class="check_group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_0'" v-bind:value="0"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_0'">
                            0分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_1'" v-bind:value="1"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_1'">
                            1分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_2'" v-bind:value="2"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_2'">
                            2分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_3'" v-bind:value="3"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_3'">
                            3分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_4'" v-bind:value="4"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_4'">
                            4分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_5'" v-bind:value="5"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_5'">
                            5分
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="EventItemDetail.EIId == 2">
                    <div class="img_box">
                      <img v-if="!EventItemDetail.T2_DishPhoto" src="/img/sample-2.png" alt="">
                      <div > <!-- v-viewer -->
                        <img v-if="EventItemDetail.T2_DishPhoto"
                          :src="RatingDetailData.SiteUrl + EventItemDetail.T2_DishPhoto" alt="">
                      </div>                      
                      <button type="button" class="" data-toggle="modal" data-target="#eventItemDetail_2_Modal"
                        @click="EventItemDetailShow = EventItemDetail">MORE ＋</button>
                    </div>
                    <h3>{{ EventItemDetail.T2_DishName }}</h3>
                    <hr>
                    <p>
                      {{ EventItemDetail.T2_CookingMethodDescription }}
                    </p>
                    <textarea v-if="RatingDetailData.Rating.EventItemReview == 1" name="" id="" cols="0" rows="3"
                      placeholder="評審備註" v-model="ItemContent['EIDID_' + EventItemDetail.EIDId]"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended"></textarea>
                    <hr v-if="RatingDetailData.Rating.EventItemReview == 2">
                    <div class="form-group" v-if="RatingDetailData.Rating.EventItemReview == 2">
                      <label for="">評分分數</label>
                      <div class="check_group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_0'" v-bind:value="0"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_0'">
                            0分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_1'" v-bind:value="1"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_1'">
                            1分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_2'" v-bind:value="2"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_2'">
                            2分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_3'" v-bind:value="3"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_3'">
                            3分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_4'" v-bind:value="4"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_4'">
                            4分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_5'" v-bind:value="5"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_5'">
                            5分
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="EventItemDetail.EIId == 3">
                    <div class="img_box">
                      <img v-if="!EventItemDetail.T3_ItemPhotos" src="/img/sample-2.png" alt="">
                      <div > <!-- v-viewer -->
                        <img v-if="EventItemDetail.T3_ItemPhotos"
                          :src="RatingDetailData.SiteUrl + EventItemDetail.T3_ItemPhotos" alt="">
                      </div>                      
                      <button type="button" class="" data-toggle="modal" data-target="#eventItemDetail_3_Modal"
                        @click="EventItemDetailShow = EventItemDetail">MORE ＋</button>
                    </div>
                    <h3>{{ EventItemDetail.T3_Item }}</h3>
                    <hr>
                    <p>
                      {{ EventItemDetail.T3_DishOrCookingPackageDescription }}
                    </p>
                    <textarea v-if="RatingDetailData.Rating.EventItemReview == 1" name="" id="" cols="0" rows="3"
                      placeholder="評審備註" v-model="ItemContent['EIDID_' + EventItemDetail.EIDId]"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended"></textarea>
                    <hr v-if="RatingDetailData.Rating.EventItemReview == 2">
                    <div class="form-group" v-if="RatingDetailData.Rating.EventItemReview == 2">
                      <label for="">評分分數</label>
                      <div class="check_group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_0'" v-bind:value="0"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_0'">
                            0分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_1'" v-bind:value="1"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_1'">
                            1分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_2'" v-bind:value="2"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_2'">
                            2分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_3'" v-bind:value="3"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_3'">
                            3分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_4'" v-bind:value="4"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_4'">
                            4分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_5'" v-bind:value="5"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_5'">
                            5分
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" v-if="EventItemDetail.EIId == 4">
                    <div class="img_box">
                      <img v-if="!EventItemDetail.T4_PotPhoto" src="/img/sample-2.png" alt="">
                      <div > <!-- v-viewer -->
                        <img v-if="EventItemDetail.T4_PotPhoto"
                          :src="RatingDetailData.SiteUrl + EventItemDetail.T4_PotPhoto" alt="">
                      </div>                      
                      <button type="button" class="" data-toggle="modal" data-target="#eventItemDetail_4_Modal"
                        @click="EventItemDetailShow = EventItemDetail">MORE ＋</button>
                    </div>
                    <h3>{{ EventItemDetail.T4_SoupName }}</h3>
                    <hr>
                    <p>
                      {{ EventItemDetail.T4_TaiwaneseHotpot }}
                    </p>
                    <textarea v-if="RatingDetailData.Rating.EventItemReview == 1" name="" id="" cols="0" rows="3"
                      placeholder="評審備註" v-model="ItemContent['EIDID_' + EventItemDetail.EIDId]"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended"></textarea>
                    <hr v-if="RatingDetailData.Rating.EventItemReview == 2">
                    <div class="form-group" v-if="RatingDetailData.Rating.EventItemReview == 2">
                      <label for="">評分分數</label>
                      <div class="check_group">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_0'" v-bind:value="0"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_0'">
                            0分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_1'" v-bind:value="1"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_1'">
                            1分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_2'" v-bind:value="2"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_2'">
                            2分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_3'" v-bind:value="3"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_3'">
                            3分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_4'" v-bind:value="4"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_4'">
                            4分
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="'score_' + EventItemDetail.EIDId"
                            :id="'score_' + EventItemDetail.EIDId + '_5'" v-bind:value="5"
                            v-model="ItemContent['EIDID_SCORE_' + EventItemDetail.EIDId]"
                            :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                          <label class="form-check-label" :for="'score_' + EventItemDetail.EIDId + '_5'">
                            5分
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 觀光潛力與實績 -->
            <div class="text_box" :class="TabIndex == 4 ? 'show' : ''">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ RatingDetailData.RatingUser.Name }}</h2>
                </div>
              </div>
              <hr>
              <div class="box_h3">
                <h3>品牌實績</h3>
              </div>
              <ul class="list">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReport">
                  <p>媒體報導</p>
                  <p>
                    {{ RatingDetailData.RatingUser.UserInfo.P1_MediaReport }}
                    <br>
                    {{ RatingDetailData.RatingUser.UserInfo.P1_MediaReportUrl }}
                  </p>
                  <div > <!-- v-viewer -->
                    <img v-if="RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile"
                      :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_MediaReportFile" alt="">
                  </div>                  
                </li>
                <li v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecord">
                  <p>獲獎記錄</p>
                  <p>
                    {{ RatingDetailData.RatingUser.UserInfo.P1_AwardRecord }}
                    <br>
                    {{ RatingDetailData.RatingUser.UserInfo.P1_AwardRecordUrl }}
                  </p>
                  <div > <!-- v-viewer -->
                    <img v-if="RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile"
                      :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P1_AwardRecordFile" alt="">
                  </div>
                </li>
              </ul>
            </div>
            <!-- 輔導單位資料 -->
            <div class="text_box" :class="TabIndex == 6 ? 'show' : ''">
              <div class="box_h3">
                <h3>輔導單位資料</h3>
              </div>
              <ul class="list">
                <li>
                  <p>統一編號</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_TaxID }}</p>
                </li>
                <li>
                  <p>單位名稱</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_UnitName }}</p>
                </li>
                <li>
                  <p>創立時間(立案或登記證明書日期)</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_FoundingTime | timeString("YYYY/MM/DD") }}</p>
                </li>
                <li>
                  <p>資本額</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_CapitalAmount }}</p>
                </li>
                <li>
                  <p>負責人</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_Principal }}</p>
                </li>
                <li>
                  <p>網址</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_Url }}</p>
                </li>
                <li>
                  <p>通訊地址</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactAddressZipCode }} {{
                    RatingDetailData.RatingUser.UserInfo.P2_ContactAddress }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>計畫主持人資料</h3>
              </div>
              <ul class="list">
                <li>
                  <p>計畫主持人姓名</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_HostName }}</p>
                </li>
                <li>
                  <p>計畫主持人職稱</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_HostTitle }}</p>
                </li>
                <li>
                  <p>連絡電話</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_HostPhone }}</p>
                </li>
                <li>
                  <p>行動電話</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_HostMobile }}</p>
                </li>
                <li>
                  <p>電子郵件</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_HostEmail }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>計畫聯絡人資料</h3>
              </div>
              <ul class="list">
                <li>
                  <p>計畫聯絡人姓名</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactName }}</p>
                </li>
                <li>
                  <p>計畫聯絡人職稱</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactTitle }}</p>
                </li>
                <li>
                  <p>連絡電話</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactPhone }}</p>
                </li>
                <li>
                  <p>行動電話</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactMobile }}</p>
                </li>
                <li>
                  <p>電子郵件</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P2_ContactEmail }}</p>
                </li>
              </ul>
            </div>
            <!-- 計畫申請摘要 -->
            <div class="text_box" :class="TabIndex == 7 ? 'show' : ''">
              <div class="box_h3">
                <h3>現況說明</h3>
              </div>
              <div class="bg_box">
                <p class="text">{{ RatingDetailData.RatingUser.UserInfo.P3_CurrentStatus }}</p>
              </div>
              <ul class="list">
                <li>
                  <p>現況說明附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile1"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile1"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile2"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile3"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_CurrentStatusFile3"
                        alt="">
                    </div>
                  </p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>申請業者面臨問題及改善需求</h3>
              </div>
              <ul class="list">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P3_BrandBuilding">
                  <p>品牌塑造問題說明</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssues }}</p>
                  <hr class="line">
                  <p class="title">附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile2"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile3"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingIssuesFile3"
                        alt="">
                    </div>
                  </p>
                  <hr class="line">
                  <p class="title">改善需求</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_BrandBuildingImprovement }}</p>
                </li>
                <hr v-if="RatingDetailData.RatingUser.UserInfo.P3_BrandBuilding">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimization">
                  <p>環境優化問題說明</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssues }}</p>
                  <hr class="line">
                  <p class="title">附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile2"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile3"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationIssuesFile3"
                        alt="">
                    </div>
                  </p>
                  <hr class="line">
                  <p class="title">改善需求</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimizationImprovement }}</p>
                </li>
                <hr v-if="RatingDetailData.RatingUser.UserInfo.P3_EnvironmentOptimization">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuService">
                  <p>多語化菜單問題說明</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssues }}</p>
                  <hr class="line">
                  <p class="title">多語化菜單附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile2"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile3"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceIssuesFile3"
                        alt="">
                    </div>
                  </p>
                  <hr class="line">
                  <p class="title">改善需求</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuServiceImprovement }}</p>
                </li>
                <hr v-if="RatingDetailData.RatingUser.UserInfo.P3_MultilingualMenuService">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimization">
                  <p>社群經營、數位行銷優化問題說明</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssues }}</p>
                  <hr class="line">
                  <p class="title">社群經營、數位行銷優化附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img
                        v-if="RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img
                        v-if="RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile2"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img
                        v-if="RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile3"
                        class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile3"
                        alt="">
                    </div>
                  </p>
                  <hr class="line">
                  <p class="title">改善需求</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimizationImprovement }}
                  </p>
                </li>
                <hr v-if="RatingDetailData.RatingUser.UserInfo.P3_SocialMediaDigitalMarketingOptimization">
                <li v-if="RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProducts">
                  <p>預製品問題說明</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssues }}</p>
                  <hr class="line">
                  <p class="title">預製品附加檔案</p>
                  <p class="img_box">
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile" class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile2" class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile2"
                        alt="">
                    </div>
                    <div style="width: calc(100% / 3); padding: 5px; display: inline-block; border-radius: 10px;" > <!-- v-viewer -->
                      <img v-if="RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile3" class="width_50"
                        :src="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsIssuesFile3"
                        alt="">
                    </div>
                  </p>
                  <hr class="line">
                  <p class="title">改善需求</p>
                  <p>{{ RatingDetailData.RatingUser.UserInfo.P3_PrepackagedProductsImprovement }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>改善需求</h3>
              </div>
              <div class="bg_box">
                <p class="text">{{ RatingDetailData.RatingUser.UserInfo.P3_ImprovementNeeds }}</p>
              </div>
              <hr>
              <div class="box_h3">
                <h3>預期效益</h3>
              </div>
              <div class="bg_box">
                <p class="text">{{ RatingDetailData.RatingUser.UserInfo.P3_ExpectedBenefits }}</p>
              </div>
            </div>
            <!-- 評分結果 -->
            <div class="text_box" :class="TabIndex == 5 ? 'show' : ''">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ RatingDetailData.RatingUser.Name }}</h2>
                </div>
              </div>
              <templeat v-if="RatingDetailData.Event.CounselingPlanDocument">
                <hr>
                <div>
                  <ul class="list">
                    <li>
                      <p>輔導計畫書</p>
                      <p class="red"><a
                          :href="RatingDetailData.SiteUrl + RatingDetailData.RatingUser.Review.CounselingPlanDocument"
                          target="_blank">下載輔導計畫書</a></p>
                    </li>
                  </ul>
                </div>
              </templeat>
              <hr>
              <div class="bg_box">
                <!-- 評分分數 1-5分 -->
                <div class="like_box" v-if="RatingDetailData.Rating.RatingType == 5">
                  <div class="like">
                    <p>極度不推薦</p>
                    <p>推薦</p>
                    <p>極度推薦</p>
                  </div>
                  <div class="like">
                    <button :class="Score == 1 ? 'active' : ''" @click="Score = 1"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended">1分</button>
                    <button :class="Score == 2 ? 'active' : ''" @click="Score = 2"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended">2分</button>
                    <button :class="Score == 3 ? 'active' : ''" @click="Score = 3"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended">3分</button>
                    <button :class="Score == 4 ? 'active' : ''" @click="Score = 4"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended">4分</button>
                    <button :class="Score == 5 ? 'active' : ''" @click="Score = 5"
                      :disabled="RatingDetailData.RatingReviewerRelated.IsSended">5分</button>
                  </div>
                </div>
                <!-- 20240311 評分分數 1-100分 -->
                <div class="like_box" v-if="RatingDetailData.Rating.RatingType == 100">
                  <div class="like">
                    <p>0分</p>
                    <p>50分</p>
                    <p>100分</p>
                  </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="" aria-valuemin="0" aria-valuemax="100"
                      :style="Score <= 3 ? 'width: 3%;' : 'width: ' + Score + '%;'">
                      <div class="">{{ Score }}</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">分數</label>
                    <input type="number" class="form-control" id="" aria-describedby="" placeholder="請輸入分數"
                      v-model="Score" :disabled="RatingDetailData.RatingReviewerRelated.IsSended">
                  </div>
                </div>
                <textarea name="" id="" cols="" rows="10" v-model="ReviewContent"
                  :disabled="RatingDetailData.RatingReviewerRelated.IsSended"></textarea>
                <p class="notice">*委員建議(限500字內)</p>
                <div class="btn" v-if="!RatingDetailData.RatingReviewerRelated.IsSended">
                  <button @click="save()">填好送出</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <!-- 20240502 新增 img放大 -->
    <div class="imgPreview">
      <div class="img_box">
        <div class="close_btn">X</div>
        <img src="" alt="">
      </div>
    </div>

    <div class="modal fade restaurant_introduction" id="eventItemDetail_1_Modal" tabindex="-1"
      aria-labelledby="eventItemDetail_1_ModalLabel" aria-hidden="true" v-if="EventItemDetailShow">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text_box">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ EventItemDetailShow.T1_BrandName }}</h2>
                </div>
                <button type="button" class="close" data-dismiss="modal">X</button>
              </div>
              <hr>
              <div class="bg_box">
                <div class="price">
                  <h3>{{ EventItemDetailShow.T1_MealName }}</h3>
                  <h3>$<span>{{ EventItemDetailShow.T1_MealPrice }}</span>元</h3>
                </div>
              </div>
              <!-- <slick ref="slick_1" class="carousel" :options="{
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1199.98,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        }">
                <div class="" v-if="EventItemDetailShow.T1_MealBoxOverallAndDishPhotos">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T1_MealBoxOverallAndDishPhotos" alt="">
                  </div>
                </div>
                <div class="" v-if="EventItemDetailShow.T1_MealBoxOverallAndDishPhotos2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T1_MealBoxOverallAndDishPhotos2" alt="">
                  </div>
                </div>
              </slick> -->
              <div class="bg_box row">
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6"
                  v-if="EventItemDetailShow.T1_MealBoxOverallAndDishPhotos">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T1_MealBoxOverallAndDishPhotos" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6"
                  v-if="EventItemDetailShow.T1_MealBoxOverallAndDishPhotos2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T1_MealBoxOverallAndDishPhotos2" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
              </div>
              <ul class="list">
                <!-- <li class="full">
                  <p>甄選項目</p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_Box == 1">一般餐盒組</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_Box == 2">循環餐盒組</span></p>
                </li> -->
                <li class="full">
                  <p>餐盒材質</p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_NoLCRecyclable">回收標誌</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_NoLCSoyInk">使用大豆油墨</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_NoLCPehosphateFree">包裝無磷膜</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_NoLCEcoMaterials">使用紙類或木片、甘蔗、稻殼等環保材質</span></p>
                  <p><span class="red"
                      v-if="EventItemDetailShow.T1_NoLCOther && EventItemDetailShow.T1_NoLCOtherContent">{{
                        EventItemDetailShow.T1_NoLCOtherContent }}</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_LCSS">不鏽鋼</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_LCPYREX">耐熱玻璃</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_LCPLA">生物可分解塑膠</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T1_LCOther && EventItemDetailShow.T1_LCOtherContent">{{
                    EventItemDetailShow.T1_LCOtherContent }}</span></p>
                  <p><span class="red"
                      v-if="EventItemDetailShow.T1_MealBoxOther && EventItemDetailShow.T1_MealBoxOtherContent">{{
                        EventItemDetailShow.T1_MealBoxOtherContent }}</span></p>
                <li class="full" v-if="EventItemDetailShow.T1_ContainerRecyclingMethod">
                  <p>容器循環作法</p>
                  <p>
                    {{ EventItemDetailShow.T1_ContainerRecyclingMethod }}
                  </p>
                </li>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>主食</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>主食內容</p>
                  <p>
                    {{ EventItemDetailShow.T1_M_StapleFoodContent }}
                  </p>
                </li>
                <li>
                  <p>品類</p>
                  <p>{{ EventItemDetailShow.T1_M_Category }}</p>
                </li>

                <li>
                  <p>產地/區域</p>
                  <p>{{ EventItemDetailShow.T1_M_OriginRegion }}</p>
                </li>
                <li>
                  <p>在地食材比例</p>
                  <p>{{ EventItemDetailShow.T1_M_LocalIngredientsRatio }}%</p>
                </li>
                <li>
                  <p>國內頒發產品履歷</p>
                  <p v-if="EventItemDetailShow.T1_M_DomesticProductRecord">有</p>
                  <p v-if="!EventItemDetailShow.T1_M_DomesticProductRecord">無</p>
                </li>
                <li>
                  <p>臺灣米標章</p>
                  <p v-if="EventItemDetailShow.T1_M_TaiwanRiceMark">有</p>
                  <p v-if="!EventItemDetailShow.T1_M_TaiwanRiceMark">無</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>主菜</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>主菜內容</p>
                  <p>{{ EventItemDetailShow.T1_A_MainDishContent }}</p>
                </li>

                <li>
                  <p>材料之產地</p>
                  <p>{{ EventItemDetailShow.T1_A_IngredientOrigin }}</p>
                </li>
                <li>
                  <p>在地食材比例</p>
                  <p>{{ EventItemDetailShow.T1_A_LocalIngredientsRatio }}%</p>
                </li>
                <li>
                  <p>國內頒發產品履歷</p>
                  <p v-if="EventItemDetailShow.T1_A_DomesticProductRecord">有</p>
                  <p v-if="!EventItemDetailShow.T1_A_DomesticProductRecord">無</p>
                </li>

                <li>
                  <p>是否使用格外品</p>
                  <p v-if="EventItemDetailShow.T1_A_UseExtraIngredients">有</p>
                  <p v-if="!EventItemDetailShow.T1_A_UseExtraIngredients">無</p>
                </li>
              </ul>
              <hr>
              <template v-for="(SideDishe, index) in EventItemDetailShow.SideDishes">
                <div class="box_h3">
                  <h3>配菜</h3>
                  <p>配菜{{ index + 1 }}</p>
                </div>
                <ul class="list">
                  <li class="full">
                    <p>配菜內容</p>
                    <p>{{ SideDishe.SideDisheContent }}</p>
                  </li>
                  <li>
                    <p>材料之產地</p>
                    <p>{{ SideDishe.IngredientOrigin }}</p>
                  </li>
                  <li>
                    <p>在地食材比例</p>
                    <p>{{ SideDishe.LocalIngredientsRatio }}%</p>
                  </li>
                  <li>
                    <p>國內頒發產品履歷</p>
                    <p v-if="SideDishe.DomesticProductRecord">有</p>
                    <p v-if="!SideDishe.DomesticProductRecord">無</p>
                  </li>
                  <li>
                    <p>是否使用格外品</p>
                    <p v-if="SideDishe.UseExtraIngredients">有</p>
                    <p v-if="!SideDishe.UseExtraIngredients">無</p>
                  </li>
                </ul>
              </template>
              <hr>
              <div class="box_h3">
                <h3>餐點風味</h3>
              </div>
              <div class="bg_box">
                <p class="text">{{ EventItemDetailShow.T1_Flavor }}</p>
              </div>
              <div class="box_h3">
                <h3>餐點特色說明</h3>
              </div>
              <div class="bg_box">
                <p class="text">
                  {{ EventItemDetailShow.T1_MealFeaturesDescription }}
                </p>
              </div>
              <!-- <div class="box_h3">
                <h3>如何維持餐點品質</h3>
              </div>
              <div class="bg_box">
                <p class="text">{{ EventItemDetailShow.T1_MaintainingMealQuality }}</p>
              </div> -->
              <div class="btn">
                <button type="button" class="" data-dismiss="modal">返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade restaurant_introduction" id="eventItemDetail_2_Modal" tabindex="-1"
      aria-labelledby="eventItemDetail_2_ModalLabel" aria-hidden="true" v-if="EventItemDetailShow">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text_box">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ EventItemDetailShow.T2_VendorName }}</h2>
                </div>
                <button type="button" class="close" data-dismiss="modal">X</button>
              </div>
              <hr>
              <div class="bg_box row">
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T2_DishPhoto">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T2_DishPhoto" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T2_DishPhoto2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T2_DishPhoto2" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T2_DishPhoto3">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T2_DishPhoto3" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T2_DishPhoto4">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T2_DishPhoto4" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T2_DishPhoto5">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T2_DishPhoto5" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
              </div>
              <ul class="list">
                <li class="full">
                  <p>菜餚類別</p>
                  <p><span class="red">{{ EventItemDetailShow.T2_DishCategory }}</span></p>
                </li>
                <li class="full">
                  <p>是否已於通路上架販售</p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_OnShelfInOutlets">是</span></p>
                  <p><span class="red" v-if="!EventItemDetailShow.T2_OnShelfInOutlets">否</span></p>
                </li>
                <li>
                  <p>編號</p>
                  <p><span class="red">{{ EventItemDetailShow.T2_ID }}</span></p>
                </li>
                <li>
                  <p>菜名</p>
                  <p><span class="red">{{ EventItemDetailShow.T2_DishName }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>主食材</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>內容</p>
                  <p>
                    {{ EventItemDetailShow.T2_MainIngredientContent }}
                  </p>
                </li>
                <li class="full">
                  <p>品牌/產地來源</p>
                  <p>{{ EventItemDetailShow.T2_MainIngredientBrandOrOrigin }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>副材料</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>內容</p>
                  <p>
                    {{ EventItemDetailShow.T2_SecondaryIngredientContent }}
                  </p>
                </li>
                <li class="full">
                  <p>品牌/產地來源</p>
                  <p>{{ EventItemDetailShow.T2_SecondaryIngredientBrandOrOrigin }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>調味料</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>內容</p>
                  <p>
                    {{ EventItemDetailShow.T2_SeasoningContent }}
                  </p>
                </li>
                <li class="full">
                  <p>品牌/產地來源</p>
                  <p>{{ EventItemDetailShow.T2_SeasoningBrandOrOrigin }}</p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>菜餚製作手法說明</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>
                    {{ EventItemDetailShow.T2_CookingMethodDescription }}
                  </p>
                </li>
              </ul>
              <hr>
              <ul class="list">
                <li class="full">
                  <p>調味重點</p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_SesameOilGingerFlavor">麻油(薑)味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_PorkOilScallionFlavor">豬油(蔥)味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_ChickenOilGarlicFlavor">雞油(蒜)味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_BraisedFlavor">紅燒味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_ThreeCupsFlavor">三杯味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_SweetAndSourFlavor">糖醋味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_HoneyGlazedFlavor">蜜汁味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_BraisedFlavor2">滷味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_FiveSpicesFlavor">五柳味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_SourAndFreshFlavor">酸鮮味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_RedLeesFlavor">紅糟味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_RiceWineLeesFlavor">酒釀味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_FermentedBeanCurdFlavor">腐乳味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_PickledFlavor">醃漬味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_RiceWineFlavor">米酒味</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T2_VinegarFlavor">醋味</span></p>
                  <p><span class="red"
                      v-if="EventItemDetailShow.T2_OtherFlavor && EventItemDetailShow.T2_OtherFlavorContent">{{
                        EventItemDetailShow.T2_OtherFlavorContent }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>菜餚內容介紹</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>
                    {{ EventItemDetailShow.T2_DishDescription }}
                  </p>
                </li>
              </ul>
              <div class="btn">
                <button type="button" class="" data-dismiss="modal">返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade restaurant_introduction" id="eventItemDetail_3_Modal" tabindex="-1"
      aria-labelledby="eventItemDetail_3_ModalLabel" aria-hidden="true" v-if="EventItemDetailShow">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text_box">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ EventItemDetailShow.T3_VendorName }}</h2>
                </div>
                <button type="button" class="close" data-dismiss="modal">X</button>
              </div>
              <hr>
              <div class="bg_box row">
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T3_ItemPhotos">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T3_ItemPhotos" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T3_ItemPhotos2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T3_ItemPhotos2" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T3_ItemPhotos3">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T3_ItemPhotos3" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T3_ItemPhotos4">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T3_ItemPhotos4" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T3_ItemPhotos5">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T3_ItemPhotos5" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
              </div>
              <ul class="list">
                <li class="full">
                  <p>品項</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_Item }}</span></p>
                </li>
                <li class="full">
                  <p>是否已商品化（製成調理包）</p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_IsCommercialized == 1">是</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_IsCommercialized == 2">開發中</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_IsCommercialized == 0">否</span></p>
                </li>
                <li class="full">
                  <p>保存方式</p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_AmbientTemperature">常溫</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_Refrigerated">冷藏</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_Frozen">冷凍</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_DomesticDistribution">
                  <p>國內通路</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_DomesticDistribution }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_NegotiatingDomesticDistribution">
                  <p>洽談中國內通路</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_NegotiatingDomesticDistribution }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_InternationalDistribution">
                  <p>國外通路</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_InternationalDistribution }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_NegotiatingInternationalDistribution">
                  <p>洽談中國外通路</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_NegotiatingInternationalDistribution }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_IngredientComposition">
                  <p>成分組成</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_IngredientComposition }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_DishOrCookingPackageDescription">
                  <p>菜餚或調理包特色說明</p>
                  <p><span class="red">{{ EventItemDetailShow.T3_DishOrCookingPackageDescription }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>調理產品檢驗</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p><span class="red" v-if="EventItemDetailShow.T3_CookingProductStatus == 0">目前尚無調理產品</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_CookingProductStatus == 1">現有調理產品皆尚未經過相關檢驗</span>
                  </p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_CookingProductStatus == 2">調理產品已進行最終產品檢驗</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_CookingProductStatus == 2">
                  <p>符合我國「食品安全衛生管理法」所訂食品相關衛生標準或相關規範</p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_ComplianceWithFoodSafetyRegulations"">是</span></p>
                  <p><span class=" red" v-if="!EventItemDetailShow.T3_ComplianceWithFoodSafetyRegulations">否</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T3_CookingProductStatus == 2">
                  <p>符合輸出國之產品檢驗標準及包裝標示規定</p>
                  <p><span class="red" v-if="EventItemDetailShow.T3_ComplianceWithExportStandards"">是</span></p>
                  <p><span class=" red" v-if="!EventItemDetailShow.T3_ComplianceWithExportStandards">否</span></p>
                </li>
              </ul>
              <template v-if="EventItemDetailShow.T3_CookingProductStatus != 0">
                <hr>
                <div class="box_h3">
                  <h3>製作調理產品之食品工廠</h3>
                </div>
                <ul class="list">
                  <li class="full">
                    <p><span class="red" v-if="EventItemDetailShow.T3_FoodFactoryStatus == 0">目前尚無自有或委外製作調理包之食品工廠</span>
                    </p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_FoodFactoryStatus == 1">自有工廠</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_FoodFactoryStatus == 2">代工廠</span></p>
                  </li>
                  <li class="full" v-if="EventItemDetailShow.T3_FoodFactoryStatus == 1">
                    <p>工廠數量</p>
                    <p><span class="red">{{ EventItemDetailShow.T3_OwnFactoryQuantity }}</span></p>
                  </li>
                  <li class="full" v-if="EventItemDetailShow.T3_FoodFactoryStatus == 2">
                    <p>合作廠商</p>
                    <p><span class="red">{{ EventItemDetailShow.T3_OEMFactoryCollaborators }}</span></p>
                  </li>
                </ul>
                <ul class="list">
                  <li class="full">
                    <p>上述工廠已通過檢驗</p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_ISO22000Certification">ISO22000驗證</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_HACCPCertification">HACCP驗證</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_HalalCertification">清真驗證</span></p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T3_CASExcellentAgriculturalProductMark">CAS優良農產品標章</span>
                    </p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T3_TQFTaiwanExcellentFoodCertification">TQF台灣優良食品驗證</span>
                    </p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_SQFCertification">SQF驗證(Safe Quality Food
                        certification)</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T3_FSSF22000Certification">FSSF 22000驗證(Food Safety
                        System certification)</span></p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T3_OtherCertifications && EventItemDetailShow.T3_OtherCertificationsContent">{{
                          EventItemDetailShow.T3_OtherCertificationsContent }}</span></p>
                  </li>
                </ul>
              </template>
              <div class="btn">
                <button type="button" class="" data-dismiss="modal">返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade restaurant_introduction" id="eventItemDetail_4_Modal" tabindex="-1"
      aria-labelledby="eventItemDetail_4_ModalLabel" aria-hidden="true" v-if="EventItemDetailShow">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text_box">
              <div class="bg_box">
                <div class="title">
                  <h2>{{ EventItemDetailShow.T4_SoupName }}</h2>
                </div>
                <button type="button" class="close" data-dismiss="modal">X</button>
              </div>
              <hr>
              <div class="bg_box row">
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_PotPhoto">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_PotPhoto" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_PotPhoto2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_PotPhoto2" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_IngredientPhoto">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_IngredientPhoto" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_IngredientPhoto2">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_IngredientPhoto2" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_IngredientPhoto3">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_IngredientPhoto3" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_IngredientPhoto4">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_IngredientPhoto4" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-6 col-xxl-6" v-if="EventItemDetailShow.T4_IngredientPhoto5">
                  <div class="img_box">
                    <img :src="RatingDetailData.SiteUrl + EventItemDetailShow.T4_IngredientPhoto5" alt=""
                      style="width: 100%; height: auto;">
                  </div>
                </div>
              </div>
              <ul class="list">
                <li class="full">
                  <p>報名組別</p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_Group == 1">單店經營店</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_Group == 2">連鎖品牌組</span></p>
                </li>
                <li class="full">
                  <p>鍋物名稱</p>
                  <p><span class="red">{{ EventItemDetailShow.T4_SoupName }}</span></p>
                </li>
                <li class="full">
                  <p>價格</p>
                  <p><span class="red">{{ EventItemDetailShow.T4_SoupPrice }}</span></p>
                </li>
                <li class="full">
                  <p>湯頭種類</p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_SoupType == 1">清湯</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_SoupType == 2">白湯</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_SoupType == 3">紅湯</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_SoupType == 5">乾鍋</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_SoupType == 4">{{
                    EventItemDetailShow.T4_SoupTypeContent
                      }}</span></p>
                </li>
                <li class="full">
                  <p>鍋物型態</p>
                  <p v-if="EventItemDetailShow.T4_PersonalPotType"><span class="red">個人鍋</span></p>
                  <p v-if="EventItemDetailShow.T4_SharedPotType"><span class="red">多人共鍋</span></p>
                  <p v-if="EventItemDetailShow.T4_AllYouCanEatPotType"><span class="red">吃到飽</span></p>
                  <p v-if="EventItemDetailShow.T4_AlaCartePotType"><span class="red">單點</span></p>
                </li>
                <li class="full">
                  <p>鍋物特色說明</p>
                  <p><span class="red">{{ EventItemDetailShow.T4_PotFeaturesDesc }}</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_FitFSLaw">
                  <p>鍋物湯底及鍋底調味已符合食安法，消費者能明確看到製作標示於</p>
                  <p v-if="EventItemDetailShow.T4_FitFSLawMenu"><span class="red">菜單</span></p>
                  <p v-if="EventItemDetailShow.T4_FitFSLawBoard"><span class="red">看板</span></p>
                  <p v-if="EventItemDetailShow.T4_FitFSLawOther && EventItemDetailShow.T4_FitFSLawOtherContent"><span
                      class="red">{{ EventItemDetailShow.T4_FitFSLawOtherContent }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>特色食材</h3>
              </div>
              <ul class="list">
                <li class="full" v-if="EventItemDetailShow.T4_Ingredient">
                  <p>特色食材-1</p>
                  <p>食材：<span class="red">{{ EventItemDetailShow.T4_Ingredient }}</span></p>
                  <p>販賣名稱：<span class="red">{{ EventItemDetailShow.T4_SellingName }}</span></p>
                  <p>份量：<span class="red">{{ EventItemDetailShow.T4_Amount }}</span></p>
                  <p>克數：<span class="red">{{ EventItemDetailShow.T4_Gram }}</span></p>
                  <p>價格：<span class="red">{{ EventItemDetailShow.T4_Price }}</span></p>
                  <p>食材產地來源：<span class="red">{{ EventItemDetailShow.T4_FMAOrigin }}</span></p>
                  <p>在地食材使用占比：<span class="red">{{ EventItemDetailShow.T4_LocProdRatio }}%</span></p>
                  <p v-if="EventItemDetailShow.T4_GMFood ||
                    EventItemDetailShow.T4_ReorgMeat ||
                    EventItemDetailShow.T4_ProcessMeat ||
                    EventItemDetailShow.T4_OtherMeat">肉品參選</p>
                  <p v-if="EventItemDetailShow.T4_GMFood"><span class="red">基改食品</span></p>
                  <p v-if="EventItemDetailShow.T4_ReorgMeat"><span class="red">重組肉</span></p>
                  <p v-if="EventItemDetailShow.T4_ProcessMeat"><span class="red">加工肉</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherMeat && EventItemDetailShow.T4_OtherMeatContent"><span
                      class="red">{{
                        EventItemDetailShow.T4_OtherMeatContent }}</span></p>
                  <p>相關檢驗與標章</p>
                  <p v-if="EventItemDetailShow.T4_IngredientOrigin"><span class="red">食材產地來源</span></p>
                  <p v-if="EventItemDetailShow.T4_AgriResidueTest"><span class="red">農業殘留檢驗</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionHistory"><span class="red">生產履歷</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProduct"><span class="red">優良農產品</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabelAndCert"><span class="red">食品標章及認證</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherCertification"><span class="red">{{
                    EventItemDetailShow.T4_OtherCertificationContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_NoneCertification"><span class="red">無</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_Ingredient2">
                  <p>特色食材-2</p>
                  <p>食材：<span class="red">{{ EventItemDetailShow.T4_Ingredient2 }}</span></p>
                  <p>販賣名稱：<span class="red">{{ EventItemDetailShow.T4_SellingName2 }}</span></p>
                  <p>份量：<span class="red">{{ EventItemDetailShow.T4_Amount2 }}</span></p>
                  <p>克數：<span class="red">{{ EventItemDetailShow.T4_Gram2 }}</span></p>
                  <p>價格：<span class="red">{{ EventItemDetailShow.T4_Price2 }}</span></p>
                  <p>食材產地來源：<span class="red">{{ EventItemDetailShow.T4_FMAOrigin2 }}</span></p>
                  <p>在地食材使用占比：<span class="red">{{ EventItemDetailShow.T4_LocProdRatio2 }}%</span></p>
                  <p v-if="EventItemDetailShow.T4_GMFood2 ||
                    EventItemDetailShow.T4_ReorgMeat2 ||
                    EventItemDetailShow.T4_ProcessMeat2 ||
                    EventItemDetailShow.T4_OtherMeat2">肉品參選</p>
                  <p v-if="EventItemDetailShow.T4_GMFood2"><span class="red">基改食品</span></p>
                  <p v-if="EventItemDetailShow.T4_ReorgMeat2"><span class="red">重組肉</span></p>
                  <p v-if="EventItemDetailShow.T4_ProcessMeat2"><span class="red">加工肉</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherMeat2 && EventItemDetailShow.T4_OtherMeatContent2"><span
                      class="red">{{ EventItemDetailShow.T4_OtherMeatContent2 }}</span></p>
                  <p>相關檢驗與標章</p>
                  <p v-if="EventItemDetailShow.T4_IngredientOrigin2"><span class="red">食材產地來源</span></p>
                  <p v-if="EventItemDetailShow.T4_AgriResidueTest2"><span class="red">農業殘留檢驗</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionHistory2"><span class="red">生產履歷</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProduct2"><span class="red">優良農產品</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabelAndCert2"><span class="red">食品標章及認證</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherCertification2"><span class="red">{{
                    EventItemDetailShow.T4_OtherCertificationContent2 }}</span></p>
                  <p v-if="EventItemDetailShow.T4_NoneCertification2"><span class="red">無</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_Ingredient3">
                  <p>特色食材-3</p>
                  <p>食材：<span class="red">{{ EventItemDetailShow.T4_Ingredient3 }}</span></p>
                  <p>販賣名稱：<span class="red">{{ EventItemDetailShow.T4_SellingName3 }}</span></p>
                  <p>份量：<span class="red">{{ EventItemDetailShow.T4_Amount3 }}</span></p>
                  <p>克數：<span class="red">{{ EventItemDetailShow.T4_Gram3 }}</span></p>
                  <p>價格：<span class="red">{{ EventItemDetailShow.T4_Price3 }}</span></p>
                  <p>食材產地來源：<span class="red">{{ EventItemDetailShow.T4_FMAOrigin3 }}</span></p>
                  <p>在地食材使用占比：<span class="red">{{ EventItemDetailShow.T4_LocProdRatio3 }}%</span></p>
                  <p v-if="EventItemDetailShow.T4_GMFood3 ||
                    EventItemDetailShow.T4_ReorgMeat3 ||
                    EventItemDetailShow.T4_ProcessMeat3 ||
                    EventItemDetailShow.T4_OtherMeat3">肉品參選</p>
                  <p v-if="EventItemDetailShow.T4_GMFood3"><span class="red">基改食品</span></p>
                  <p v-if="EventItemDetailShow.T4_ReorgMeat3"><span class="red">重組肉</span></p>
                  <p v-if="EventItemDetailShow.T4_ProcessMeat3"><span class="red">加工肉</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherMeat3 && EventItemDetailShow.T4_OtherMeatContent3"><span
                      class="red">{{ EventItemDetailShow.T4_OtherMeatContent3 }}</span></p>
                  <p>相關檢驗與標章</p>
                  <p v-if="EventItemDetailShow.T4_IngredientOrigin3"><span class="red">食材產地來源</span></p>
                  <p v-if="EventItemDetailShow.T4_AgriResidueTest3"><span class="red">農業殘留檢驗</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionHistory3"><span class="red">生產履歷</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProduct3"><span class="red">優良農產品</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabelAndCert3"><span class="red">食品標章及認證</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherCertification3"><span class="red">{{
                    EventItemDetailShow.T4_OtherCertificationContent3 }}</span></p>
                  <p v-if="EventItemDetailShow.T4_NoneCertification3"><span class="red">無</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_Ingredient4">
                  <p>特色食材-4</p>
                  <p>食材：<span class="red">{{ EventItemDetailShow.T4_Ingredient4 }}</span></p>
                  <p>販賣名稱：<span class="red">{{ EventItemDetailShow.T4_SellingName4 }}</span></p>
                  <p>份量：<span class="red">{{ EventItemDetailShow.T4_Amount4 }}</span></p>
                  <p>克數：<span class="red">{{ EventItemDetailShow.T4_Gram4 }}</span></p>
                  <p>價格：<span class="red">{{ EventItemDetailShow.T4_Price4 }}</span></p>
                  <p>食材產地來源：<span class="red">{{ EventItemDetailShow.T4_FMAOrigin4 }}</span></p>
                  <p>在地食材使用占比：<span class="red">{{ EventItemDetailShow.T4_LocProdRatio4 }}%</span></p>
                  <p v-if="EventItemDetailShow.T4_GMFood4 ||
                    EventItemDetailShow.T4_ReorgMeat4 ||
                    EventItemDetailShow.T4_ProcessMeat4 ||
                    EventItemDetailShow.T4_OtherMeat4">肉品參選</p>
                  <p v-if="EventItemDetailShow.T4_GMFood4"><span class="red">基改食品</span></p>
                  <p v-if="EventItemDetailShow.T4_ReorgMeat4"><span class="red">重組肉</span></p>
                  <p v-if="EventItemDetailShow.T4_ProcessMeat4"><span class="red">加工肉</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherMeat4 && EventItemDetailShow.T4_OtherMeatContent4"><span
                      class="red">{{ EventItemDetailShow.T4_OtherMeatContent4 }}</span></p>
                  <p>相關檢驗與標章</p>
                  <p v-if="EventItemDetailShow.T4_IngredientOrigin4"><span class="red">食材產地來源</span></p>
                  <p v-if="EventItemDetailShow.T4_AgriResidueTest4"><span class="red">農業殘留檢驗</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionHistory4"><span class="red">生產履歷</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProduct4"><span class="red">優良農產品</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabelAndCert4"><span class="red">食品標章及認證</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherCertification4"><span class="red">{{
                    EventItemDetailShow.T4_OtherCertificationContent4 }}</span></p>
                  <p v-if="EventItemDetailShow.T4_NoneCertification4"><span class="red">無</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_Ingredient5">
                  <p>特色食材-5</p>
                  <p>食材：<span class="red">{{ EventItemDetailShow.T4_Ingredient5 }}</span></p>
                  <p>販賣名稱：<span class="red">{{ EventItemDetailShow.T4_SellingName5 }}</span></p>
                  <p>份量：<span class="red">{{ EventItemDetailShow.T4_Amount5 }}</span></p>
                  <p>克數：<span class="red">{{ EventItemDetailShow.T4_Gram5 }}</span></p>
                  <p>價格：<span class="red">{{ EventItemDetailShow.T4_Price5 }}</span></p>
                  <p>食材產地來源：<span class="red">{{ EventItemDetailShow.T4_FMAOrigin5 }}</span></p>
                  <p>在地食材使用占比：<span class="red">{{ EventItemDetailShow.T4_LocProdRatio5 }}%</span></p>
                  <p v-if="EventItemDetailShow.T4_GMFood5 ||
                    EventItemDetailShow.T4_ReorgMeat5 ||
                    EventItemDetailShow.T4_ProcessMeat5 ||
                    EventItemDetailShow.T4_OtherMeat5">肉品參選</p>
                  <p v-if="EventItemDetailShow.T4_GMFood5"><span class="red">基改食品</span></p>
                  <p v-if="EventItemDetailShow.T4_ReorgMeat5"><span class="red">重組肉</span></p>
                  <p v-if="EventItemDetailShow.T4_ProcessMeat5"><span class="red">加工肉</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherMeat5 && EventItemDetailShow.T4_OtherMeatContent5"><span
                      class="red">{{ EventItemDetailShow.T4_OtherMeatContent5 }}</span></p>
                  <p>相關檢驗與標章</p>
                  <p v-if="EventItemDetailShow.T4_IngredientOrigin5"><span class="red">食材產地來源</span></p>
                  <p v-if="EventItemDetailShow.T4_AgriResidueTest5"><span class="red">農業殘留檢驗</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionHistory5"><span class="red">生產履歷</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProduct5"><span class="red">優良農產品</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabelAndCert5"><span class="red">食品標章及認證</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherCertification5"><span class="red">{{
                    EventItemDetailShow.T4_OtherCertificationContent5 }}</span></p>
                  <p v-if="EventItemDetailShow.T4_NoneCertification5"><span class="red">無</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>特色醬料</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>醬料名稱：<span class="red">{{ EventItemDetailShow.T4_SauceName }}</span></p>
                  <p>醬料成分：<span class="red">{{ EventItemDetailShow.T4_SauceContent }}</span></p>
                  <p>醬料特色說明：<span class="red">{{ EventItemDetailShow.T4_SauceDesc }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>參選鍋物與臺灣之關聯性說明</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>簡述</p>
                  <p><span class="red">{{ EventItemDetailShow.T4_TaiwaneseHotpot }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>環境安全與衛生</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p v-if="EventItemDetailShow.T4_PrdLiabilityIns">產品責任險承保單位：<span class="red">{{
                    EventItemDetailShow.T4_ProdLiabilityInsurer }}</span></p>
                  <template v-if="EventItemDetailShow.T4_CompFAndBReg">
                    <p v-if="EventItemDetailShow.T4_FSANReg">食品安全衛生管理法：<span class="red">{{
                      EventItemDetailShow.T4_FSANRegContent }}</span></p>
                    <p v-if="EventItemDetailShow.T4_GoodHygieneNorm">食品良好衛生規範準則：<span class="red">{{
                      EventItemDetailShow.T4_GoodHygieneNormContent }}</span></p>
                    <p v-if="EventItemDetailShow.T4_UtensilWashGuide">餐具清洗良好作業指引：<span class="red">{{
                      EventItemDetailShow.T4_UtensilWashGuideContent }}</span></p>
                    <p v-if="EventItemDetailShow.T4_OtherReg">其他法規：<span class="red">{{
                      EventItemDetailShow.T4_OtherRegContent }}</span></p>
                  </template>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>其他具特色或創新說明</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p>簡述</p>
                  <p><span class="red">{{ EventItemDetailShow.T4_OtherUniqueDesc }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>食材來源</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p v-if="EventItemDetailShow.T4_AgriResidueCheck">農業殘留檢驗：<span class="red">{{
                    EventItemDetailShow.T4_AgriResidueCheckName }} {{
                        EventItemDetailShow.T4_AgriResidueCheckIngredientName }}</span></p>
                  <p v-if="EventItemDetailShow.T4_ProductionRecord">生產履歷：<span class="red">{{
                    EventItemDetailShow.T4_ProductionRecordName }} {{ EventItemDetailShow.T4_ProductionRecordName
                      }}</span></p>
                  <p v-if="EventItemDetailShow.T4_HighQualityAgriProducts">優良農產品：<span class="red">{{
                    EventItemDetailShow.T4_HighQualityAgriProductsName }} {{
                        EventItemDetailShow.T4_HighQualityAgriProductsIngredientName }}</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodLabel">食品標章：<span class="red">{{
                    EventItemDetailShow.T4_FoodLabelName
                      }} {{ EventItemDetailShow.T4_FoodLabelIngredientName }}</span></p>
                  <p v-if="EventItemDetailShow.T4_FoodCertification">食品認證：<span class="red">{{
                    EventItemDetailShow.T4_FoodCertificationName }} {{
                        EventItemDetailShow.T4_FoodCertificationIngredientName }}</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherSource">其他：<span class="red">{{
                    EventItemDetailShow.T4_OtherSourceName }} {{ EventItemDetailShow.T4_OtherSourceIngredientName
                      }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>服務體驗</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p v-if="EventItemDetailShow.T4_SelfService">自助吧/餐點種類多項：<span class="red">{{
                    EventItemDetailShow.T4_SelfServiceDesc }} {{ EventItemDetailShow.T4_SelfServiceQty }}</span></p>
                  <p v-if="EventItemDetailShow.T4_TableService">桌邊服務：<span class="red">{{
                    EventItemDetailShow.T4_TableServiceContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_AccessibleSpace">無障礙空間：<span class="red">{{
                    EventItemDetailShow.T4_AccessibleSpaceContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_ChildrenFurniture">兒童桌椅/餐具：<span class="red">{{
                    EventItemDetailShow.T4_ChildrenFurnitureContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_MemberServices">會員服務/活動：<span class="red">{{
                    EventItemDetailShow.T4_MemberServicesContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_ReservationNotification">訂位通知：<span class="red">{{
                    EventItemDetailShow.T4_ReservationNotificationContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_LivePerformance">現場表演：<span class="red">{{
                    EventItemDetailShow.T4_LivePerformanceContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_DiningAmbiance">用餐氛圍：<span class="red">{{
                    EventItemDetailShow.T4_DiningAmbianceContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_DiningEnvironment">用餐環境：<span class="red">{{
                    EventItemDetailShow.T4_DiningEnvironmentContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_StaffAttire">員工服裝：<span class="red">{{
                    EventItemDetailShow.T4_StaffAttireContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_StaffServiceTraining">員工服務及態度(教育訓練)：<span class="red">{{
                    EventItemDetailShow.T4_StaffServiceTrainingContent }}</span></p>
                  <p v-if="EventItemDetailShow.T4_OtherServices">其他：<span class="red">{{
                    EventItemDetailShow.T4_OtherServicesContent }}</span></p>
                </li>
              </ul>
              <hr>
              <div class="box_h3">
                <h3>調理產品檢驗</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p><span class="red" v-if="EventItemDetailShow.T4_CookingProductStatus == 0">目前尚無調理產品</span></p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_CookingProductStatus == 1">現有調理產品皆尚未經過相關檢驗</span>
                  </p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_CookingProductStatus == 2">調理產品已進行最終產品檢驗</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_CookingProductStatus == 2">
                  <p>符合我國「食品安全衛生管理法」所訂食品相關衛生標準或相關規範</p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_ComplianceWithFoodSafetyRegulations"">是</span></p>
                  <p><span class=" red" v-if="!EventItemDetailShow.T4_ComplianceWithFoodSafetyRegulations">否</span></p>
                </li>
                <li class="full" v-if="EventItemDetailShow.T4_CookingProductStatus == 2">
                  <p>符合輸出國之產品檢驗標準及包裝標示規定</p>
                  <p><span class="red" v-if="EventItemDetailShow.T4_ComplianceWithExportStandards"">是</span></p>
                  <p><span class=" red" v-if="!EventItemDetailShow.T4_ComplianceWithExportStandards">否</span></p>
                </li>
              </ul>
              <template v-if="EventItemDetailShow.T4_CookingProductStatus != 0">
                <hr>
                <div class="box_h3">
                  <h3>製作調理產品之食品工廠</h3>
                </div>
                <ul class="list">
                  <li class="full">
                    <p><span class="red" v-if="EventItemDetailShow.T4_FoodFactoryStatus == 0">目前尚無自有或委外製作調理包之食品工廠</span>
                    </p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_FoodFactoryStatus == 1">自有工廠</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_FoodFactoryStatus == 2">代工廠</span></p>
                  </li>
                  <li class="full" v-if="EventItemDetailShow.T4_FoodFactoryStatus == 1">
                    <p>工廠數量</p>
                    <p><span class="red">{{ EventItemDetailShow.T4_OwnFactoryQuantity }}</span></p>
                  </li>
                  <li class="full" v-if="EventItemDetailShow.T4_FoodFactoryStatus == 2">
                    <p>合作廠商</p>
                    <p><span class="red">{{ EventItemDetailShow.T4_OEMFactoryCollaborators }}</span></p>
                  </li>
                </ul>
                <ul class="list">
                  <li class="full">
                    <p>上述工廠已通過檢驗</p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_ISO22000Certification">ISO22000驗證</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_HACCPCertification">HACCP驗證</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_HalalCertification">清真驗證</span></p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T4_CASExcellentAgriculturalProductMark">CAS優良農產品標章</span>
                    </p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T4_TQFTaiwanExcellentFoodCertification">TQF台灣優良食品驗證</span>
                    </p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_SQFCertification">SQF驗證(Safe Quality Food
                        certification)</span></p>
                    <p><span class="red" v-if="EventItemDetailShow.T4_FSSF22000Certification">FSSF 22000驗證(Food Safety
                        System certification)</span></p>
                    <p><span class="red"
                        v-if="EventItemDetailShow.T4_OtherCertifications && EventItemDetailShow.T4_OtherCertificationsContent">{{
                          EventItemDetailShow.T4_OtherCertificationsContent }}</span></p>
                  </li>
                </ul>
              </template>
              <hr>
              <div class="box_h3">
                <h3>聯合行銷活動優惠</h3>
              </div>
              <ul class="list">
                <li class="full">
                  <p v-if="EventItemDetailShow.T4_JointPromoProduct">願意提供<span class="red">{{
                    EventItemDetailShow.T4_JointPromoProductName }}</span>之商品<span class="red">{{
                        EventItemDetailShow.T4_JointPromoProductQty }}</span>份為活動贈品使用</p>
                  <p v-if="EventItemDetailShow.T4_JointPromoDiscount">願意提供消費折扣<span class="red">{{
                    EventItemDetailShow.T4_JointPromoDiscountQty }}</span>折</p>
                  <p v-if="EventItemDetailShow.T4_JointPromoOther">其他相關優惠方案：<span class="red">{{
                    EventItemDetailShow.T4_JointPromoOtherContent }}</span></p>
                </li>
              </ul>
              <div class="btn">
                <button type="button" class="" data-dismiss="modal">返回</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Slick from "vue-slick";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "RatingDetail",
  components: {
    Head,
    Footer,
    Slick
  },
  data() {
    return {
      RatingDetailData: {},
      TabIndex: 1,
      Score: 0,
      ReviewContent: '',
      EventItemDetailShow: null,
      ItemContent: null,
      TotalStaff: 0,
      TotalYear: 0,
      InfieldPercent: 0,
      OutfieldPercent: 0,
      MalePercent: 0,
      FemalePercent: 0,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/RatingDetail",
        {
          params: { RId: to.params.RId, URId: to.params.URId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.RatingDetailData = response.data;
        if (!this.RatingDetailData.User || !this.RatingDetailData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        this.Score = this.RatingDetailData.RatingUser.RatingUserSource.Score;
        this.ReviewContent = this.RatingDetailData.RatingUser.RatingUserSource.ReviewContent;
        this.ItemContent = JSON.parse(this.RatingDetailData.RatingUser.RatingUserSource.ItemContent);
        this.CalStaff();
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/RatingDetail",
        {
          params: { RId: this.$route.params.RId, URId: this.$route.params.URId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.RatingDetailData = response.data;
        if (!this.RatingDetailData.User || !this.RatingDetailData.User.IsReviewer) {
          this.$router.push({
            name: "Home",
          });
        }
        if (this.RatingDetailData.Rating.RatingType == 100) {
          $('.restaurant_introduction .text_box .like_box .form-group').on('change', function () {
            var number = $(".restaurant_introduction .text_box .like_box .form-group input").val();
            $(".restaurant_introduction .text_box .like_box .progress-bar div").text(number);
            if (number < 3) {
              $(".restaurant_introduction .text_box .like_box .progress-bar").css("width", "3%");
            } else {
              $(".restaurant_introduction .text_box .like_box .progress-bar").css("width", number + "%");
            }
          });
        }
        this.Score = this.RatingDetailData.RatingUser.RatingUserSource.Score;
        this.ReviewContent = this.RatingDetailData.RatingUser.RatingUserSource.ReviewContent;
        this.ItemContent = JSON.parse(this.RatingDetailData.RatingUser.RatingUserSource.ItemContent);
        this.CalStaff();
        // 20240502 新增 img放大
        $('.restaurant_introduction .text_box .list li p img.width_50').on('click', function () {
          var src = $(this).attr('src');
          $('.imgPreview img').attr('src', src);
          $('.imgPreview').show();
        });

        $('.imgPreview').not("img").on('click', function () {
          $('.imgPreview').hide();

        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    // let that = this;
    // $('#eventItemDetail_1_Modal').on('shown.bs.modal', function () {
    //   that.$refs.slick_1.reSlick();
    // })
    // $('#eventItemDetail_2_Modal').on('shown.bs.modal', function () {
    //   that.$refs.slick_2.reSlick();
    // })
    // $('#eventItemDetail_3_Modal').on('shown.bs.modal', function () {
    //   that.$refs.slick_3.reSlick();
    // })
  },
  methods: {
    gotoRatingByRId: function (RId) {
      this.$router.push({
        name: "Rating",
        params: { RId: RId },
      });
    },
    save() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      if (this.RatingDetailData.Rating.RatingType == 5) {
        if (this.Score == 0) {
          Swal.fire({
            title: "錯誤",
            text: "評分後才可送出",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return false;
        }
      } else if (this.RatingDetailData.Rating.RatingType == 100) {
        if (this.Score < 0 || this.Score > 100) {
          Swal.fire({
            title: "錯誤",
            text: "分數格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return false;
        }
      }
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/RatingDetail",
        type: "post",
        data: {
          RURSId: this.RatingDetailData.RatingUser.RatingUserSource.RURSId,
          ItemContent: JSON.stringify(this.ItemContent),
          Score: this.Score,
          ReviewContent: this.ReviewContent,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "儲存成功",
              text: "即將返回列表",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "Rating",
                  params: { RId: that.$route.params.RId }
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    geteTab(index) {
      this.TabIndex = index;
      if (this.TabIndex == 1) {
        this.$refs.slick_main.reSlick();
      }
    },
    CalStaff: function () {
      this.TotalStaff = this.RatingDetailData.RatingUser.UserInfo.P1_PermanentStaff + this.RatingDetailData.RatingUser.UserInfo.P1_PartTimeStaff
      if (this.TotalStaff > 0) {
        this.InfieldPercent = Math.round((this.RatingDetailData.RatingUser.UserInfo.P1_Infield / this.TotalStaff * 100) * 100) / 100;
        this.OutfieldPercent = Math.round((this.RatingDetailData.RatingUser.UserInfo.P1_Outfield / this.TotalStaff * 100) * 100) / 100;
        this.MalePercent = Math.round((this.RatingDetailData.RatingUser.UserInfo.P1_Male / this.TotalStaff * 100) * 100) / 100;
        this.FemalePercent = Math.round((this.RatingDetailData.RatingUser.UserInfo.P1_Female / this.TotalStaff * 100) * 100) / 100;
      } else {
        this.InfieldPercent = 0;
        this.OutfieldPercent = 0;
        this.MalePercent = 0;
        this.FemalePercent = 0;
      }
    },
  },
};
</script>
