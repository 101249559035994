<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb" v-if="EventItemData">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="EventItemData.Event">{{ EventItemData.Event.Name }}</li>
        <li class="breadcrumb-item active" aria-current="page">品項管理</li>
      </ol>
    </div>
    <div class="wrapper member_information member_information_1_list" v-if="EventItemData && EventItemData.Event">
      <section class="main">
        <div class="container">
          <div class="step_box">
            <div class="" @click="goto('BaseInfo')" style="cursor: pointer;">基本資料</div>
            <div class="step">品項管理</div>
            <div class="" @click="goto('ReqDoc')" style="cursor: pointer;">應備文件</div>
            <div v-if="EventItemData.Review.ReviewStatus == 0" class="" @click="sendReview()" style="cursor: pointer;">
              送出審核</div>
            <div v-if="EventItemData.Review.ReviewStatus == 3" class="" @click="sendReview()" style="cursor: pointer;">
              重新送出審核</div>
            <div v-if="EventItemData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="EventItemData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="EventItemData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="EventItemData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <h1>
            {{ EventItemData.Event.Name }}
            <div class="btn_home">
              <router-link to="/" tag="button">返回首頁</router-link>
            </div>
          </h1>
          <ul class="list row">
            <li class="col-12 col-sm-6 col-md-4" v-for="EventItemDetail in EventItemData.EventItemDetails"
              :key="EventItemDetail.EIDId">
              <router-link
                :to="{ name: 'EventItemDetail_' + EventItemDetail.EIId, params: { EId: EventItemData.Event.EId, EIId: EventItemDetail.EIId, EIDId: EventItemDetail.EIDId } }">
                <div class="img_box">
                  <img :src="EventItemData.SiteUrl + EventItemDetail.T1_MealBoxOverallAndDishPhotos" alt=""
                    v-if="EventItemDetail.EIId == 1 && EventItemDetail.T1_MealBoxOverallAndDishPhotos">
                  <img :src="EventItemData.SiteUrl + EventItemDetail.T2_DishPhoto" alt=""
                    v-else-if="EventItemDetail.EIId == 2 && EventItemDetail.T2_DishPhoto">
                  <img :src="EventItemData.SiteUrl + EventItemDetail.T3_ItemPhotos" alt=""
                    v-else-if="EventItemDetail.EIId == 3 && EventItemDetail.T3_ItemPhotos">
                  <img :src="EventItemData.SiteUrl + EventItemDetail.T4_PotPhoto" alt=""
                    v-else-if="EventItemDetail.EIId == 4 && EventItemDetail.T4_PotPhoto">
                  <img v-else src="/img/sample-2.png" alt="">
                </div>
                <p v-if="EventItemDetail.EIId == 1">{{ EventItemDetail.T1_MealName }}
                </p>
                <p v-else-if="EventItemDetail.EIId == 2">{{
      EventItemDetail.T2_VendorName }}
                </p>
                <p v-else-if="EventItemDetail.EIId == 3">{{
      EventItemDetail.T3_VendorName }}
                </p>
                <p v-else-if="EventItemDetail.EIId == 4">{{
      EventItemDetail.T4_SoupName }}
                </p>
                <p v-else>{{ EventItemDetail.EventItemName }}</p>
              </router-link>
            </li>
          </ul>
          <hr>
          <div class="member_information">
            <div class="main">
              <div class="box show">
                <div class="btn_box">
                  <router-link
                    v-if="(EventItemData.Review.ReviewStatus == 0 || EventItemData.Review.ReviewStatus == EventItemData.Event.MaxItem) && EventItemData.EventItemDetails.length < EventItemData.Event.MaxItem"
                    :to="{ name: 'EventItemDetail_' + EventItem.EIId, params: { EId: EventItemData.Event.EId, EIId: EventItem.EIId } }"
                    v-for="EventItem in EventItemData.EventItems" :key="EventItem.EIId" tag="button"
                    style="width: auto;">增加徵選品項</router-link>
                  <!-- <router-link :to="{ name: 'BaseInfo', params: { EId: EventItemData.Event.EId } }"
                    tag="button">修改基本資料</router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "EevntItem",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      EventItemData: {},
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/EventItem",
        {
          params: { EId: this.$route.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventItemData = response.data;
        if (!this.EventItemData.UserInfo ||
          this.EventItemData.UserInfo.UIId == -1) {
          Swal.fire({
            title: "請先填寫基本資料",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "BaseInfo",
                params: { EId: this.$route.params.EId },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
