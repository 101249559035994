<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">報名管理</li>
      </ol>
    </div>
    <div class="wrapper member" v-if="ApplyManagerData">
      <section class="main">
        <div class="container">
          <div class="row">
            <div class="box col-12 col-md-6" v-for="Event in ApplyManagerData.Events" :key="Event.EId">
              <h1>{{ Event.Name }}</h1>
              <div class="img_box">
                <div class="img">
                  <img v-if="!Event.Image" src="img/banner_1.jpg" alt="">
                  <img v-if="Event.Image" :src="ApplyManagerData.SiteUrl + Event.Image" alt="">
                </div>
              </div>
              <div class="btn_box">
                <router-link :to="{ name: 'BaseInfo', params: { EId: Event.EId } }" tag="button">基本資料</router-link>
                <router-link :to="{ name: 'EventItem', params: { EId: Event.EId } }" tag="button">品項管理</router-link>
                <router-link :to="{ name: 'ReqDoc', params: { EId: Event.EId } }" tag="button">應備文件</router-link>
                <button v-if="Event.Review.ReviewStatus == 0" @click="sendReview(Event.EId)">送出審核</button>
                <button v-if="Event.Review.ReviewStatus == 3" @click="sendReview(Event.EId)">重新送出審核</button>
                <button v-if="Event.Review.ReviewStatus == 99">審核中</button>
                <button v-if="Event.Review.ReviewStatus == 999">重審中</button>
                <button v-if="Event.Review.ReviewStatus == 1">審核通過</button>
                <button v-if="Event.Review.ReviewStatus == 2">審核失敗</button>
              </div>
              <div v-if="Event.Review.ReviewStatus == 1" class="success">
                <p>審核<br>通過</p>
              </div>
              <div v-if="Event.Review.ReviewStatus == 2" class="success">
                <p>審核<br>失敗</p>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ApplyManager",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      ApplyManagerData: {},
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/ApplyManager",
        {
          params: { token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.ApplyManagerData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    sendReview(EId) {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
