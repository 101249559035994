<template>
  <div>
    <Head v-if="BaseInfoData" />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="BaseInfoData.Event">{{ BaseInfoData.Event.Name }}</li>
        <li class="breadcrumb-item active" aria-current="page">基本資料</li>
      </ol>
    </div>
    <div class="wrapper member_information factory" v-if="BaseInfoData.UserInfo">
      <section class="main">
        <div class="container">
          <!-- <h1>2023年經濟部綠色盒餐徵選</h1> -->
          <div class="step_box">
            <div class="step">基本資料</div>
            <div class="" @click="goto('EventItem')" style="cursor: pointer;" v-if="BaseInfoData.EventItems.length > 0">
              品項管理</div>
            <div class="" @click="goto('ReqDoc')" style="cursor: pointer;">應備文件</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 0" class="" @click="sendReview()" style="cursor: pointer;">
              送出審核</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 3" class="" @click="sendReview()" style="cursor: pointer;">
              重新送出審核</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="BaseInfoData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <templeat v-if="BaseInfoData.Review.ReviewStatus == 3 || BaseInfoData.Review.ReviewStatus == 2">
            <h1>
              審核說明
            </h1>
            <div class="box show">
              <div class="bg">
                <p style="white-space: pre-line; font-size: 1.2rem; font-weight: 500;">
                  {{ BaseInfoData.Review.ReviewContent }}}
                </p>
              </div>
            </div>
          </templeat>
          <h1>
            {{ BaseInfoData.Event.Name }}
            <div class="btn_home">
              <router-link to="/" tag="button">返回首頁</router-link>
            </div>
          </h1>
          <div class="btn_box information_box">
            <button :class="Step == 1 ? 'active' : ''" @click="Step = 1">基本資料</button>
            <button :class="Step == 2 ? 'active' : ''" @click="Step = 2">輔導單位資料</button>
            <button :class="Step == 3 ? 'active' : ''" @click="Step = 3">申請計畫摘要說明</button>
          </div>
          <div id="stet_1" class="box" :class="Step == 1 ? 'show' : ''">
            <div class="bg" v-if="BaseInfoData.ColA.P1_1 ||
              BaseInfoData.ColA.P1_2 ||
              BaseInfoData.ColA.P1_3 ||
              BaseInfoData.ColA.P1_4 ||
              BaseInfoData.ColA.P1_5 ||
              BaseInfoData.ColA.P1_6 ||
              BaseInfoData.ColA.P1_7 ||
              BaseInfoData.ColA.P1_8 ||
              BaseInfoData.ColA.P1_9 ||
              BaseInfoData.ColA.P1_10 ||
              BaseInfoData.ColA.P1_11
            ">
              <div class="form-group" v-if="BaseInfoData.ColA.P1_1">
                <label for=""><span>*</span>統一編號</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_TaxID" data-required="1"
                  data-required-msg="請填寫統一編號" aria-describedby="" placeholder="請填寫統一編號" readonly>
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_2">
                <label for=""><span>*</span>總部、其他門市/分店或其他分公司之統一編號是否相同</label>
                <div class="check_group radio">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_SameUnifiedNumber" id="P1_SameUnifiedNumber1"
                      v-bind:value="true" v-model="BaseInfoData.UserInfo.P1_SameUnifiedNumber">
                    <label class="form-check-label" for="P1_SameUnifiedNumber1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_SameUnifiedNumber" id="P1_SameUnifiedNumber2"
                      v-bind:value="false" v-model="BaseInfoData.UserInfo.P1_SameUnifiedNumber">
                    <label class="form-check-label" for="P1_SameUnifiedNumber2">
                      否
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_3">
                <label for=""><span>*</span>食品業者登錄字號 <a href="https://fadenbook.fda.gov.tw/pub/index.aspx"
                    target="_blank">前往查詢</a></label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_FoodRegNumber"
                  data-required="1" data-required-msg="請填寫食品業者登錄字號" aria-describedby="" placeholder="請填寫食品業者登錄字號">
                <p>食品業者登錄字號共19碼，包含"-"符號，建議查詢後複製貼上</p>
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_4">
                <label for=""><span>*</span>公司登記名稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_CompanyName" data-required="1"
                  data-required-msg="請填寫公司登記名稱" aria-describedby="" placeholder="請填寫公司登記名稱">
              </div>
              <div class="form-group full" v-if="BaseInfoData.ColA.P1_4">
                <label for=""><span>*</span>店家(品牌)名稱商標註冊狀況</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline" style="flex-basis: 25%;">
                    <input class="form-check-input" type="radio" name="P1_BrandNameTrademarkRegStatus"
                      id="P1_BrandNameTrademarkRegStatus0" value="0"
                      v-model="BaseInfoData.UserInfo.P1_BrandNameTrademarkRegStatus">
                    <label class="form-check-label" for="P1_BrandNameTrademarkRegStatus0">
                      尚未申請
                    </label>
                  </div>
                  <div class="form-check form-check-inline" style="flex-basis: 25%;">
                    <input class="form-check-input" type="radio" name="P1_BrandNameTrademarkRegStatus"
                      id="P1_BrandNameTrademarkRegStatus1" value="1"
                      v-model="BaseInfoData.UserInfo.P1_BrandNameTrademarkRegStatus">
                    <label class="form-check-label" for="P1_BrandNameTrademarkRegStatus1">
                      已申請使用
                    </label>
                  </div>
                  <div class="form-check form-check-inline" style="flex-basis: 50%;">
                    <input class="form-check-input" type="radio" name="P1_BrandNameTrademarkRegStatus"
                      id="P1_BrandNameTrademarkRegStatus2" value="2"
                      v-model="BaseInfoData.UserInfo.P1_BrandNameTrademarkRegStatus">
                    <label class="form-check-label" for="P1_BrandNameTrademarkRegStatus2">
                      申請中，送件日期
                    </label>
                    <div class="form-group number" style="display: block;">
                      <input type="text" id="P1_SubmDate" class="form-control"
                        v-model="BaseInfoData.UserInfo.P1_SubmDate" aria-describedby="" placeholder=""
                        :data-required="BaseInfoData.UserInfo.P1_BrandNameTrademarkRegStatus == 2 ? '1' : '0'"
                        data-required-msg="請填寫送件日期" v-once>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_5">
                <label for=""><span>*</span>品牌/餐廳名稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_BrandName" data-required="1"
                  data-required-msg="請填寫品牌名稱" aria-describedby="" placeholder="請填寫品牌名稱">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_6">
                <label for=""><span>*</span>品牌/餐廳英文名稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_BrandNameEN" data-required="1"
                  data-required-msg="請填寫品牌英文名稱" aria-describedby="" placeholder="請填寫品牌英文名稱">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_7">
                <label for=""><span>*</span>負責人姓名</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_PrincipalName"
                  data-required="1" data-required-msg="請填寫負責人姓名" aria-describedby="" placeholder="請填寫負責人姓名">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_44">
                <label for="">資本額</label>
                <input type="number" min="0" class="form-control"
                  v-model.number="BaseInfoData.UserInfo.P1_CapitalAmount" aria-describedby="" placeholder="請填寫資本額">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_44">
                <label for="">在職人數</label>
                <input type="number" min="0" class="form-control"
                  v-model.number="BaseInfoData.UserInfo.P1_NumberOfEmployees" aria-describedby="" placeholder="請填寫在職人數">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColA.P1_8">
                <label for=""><span>*</span>負責人職稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_PrincipalJobName"
                  data-required="1" data-required-msg="請填寫職稱" aria-describedby="" placeholder="請填寫職稱">
              </div>
              <!-- <div class="form-group" id="chineseDiv">
                            <label for=""><span>*</span>創立時間</label>
                            <input type="" autocomplete="off" id="dateFrom" class="datePicker" placeholder="民國年/月/日" />
                            <p>（立案或登記證明書日期）</p>
                        </div> -->
              <div class="form-group" id="" v-if="BaseInfoData.ColA.P1_9">
                <label for=""><span>*</span>創立時間</label>
                <input type="text" id="P1_FoundingTime" class="form-control"
                  v-model="BaseInfoData.UserInfo.P1_FoundingTime" data-required="1" data-required-msg="請填寫創立時間"
                  aria-describedby="" placeholder="" v-once>
                <p>（立案或登記證明書日期）</p>
              </div>
              <div class="form-group full address_box" v-if="BaseInfoData.ColA.P1_11">
                <label for=""><span>*</span>總部/聯絡地址</label>
                <div class="address">
                  <input type="text" class="form-control" id="address_1"
                    v-model="BaseInfoData.UserInfo.P1_ContactAddressZipCode" data-required="1"
                    data-required-msg="請填寫郵遞區號" aria-describedby="" placeholder="請填寫郵遞區號">
                  <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactAddress"
                    data-required="1" data-required-msg="請填寫地址" aria-describedby="" placeholder="請填寫地址">
                </div>
              </div>
              <div class="form-group full address_box" v-if="BaseInfoData.ColA.P1_10">
                <label for=""><span>*</span>營業地址</label>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="isSameAddress"
                    v-model="BaseInfoData.UserInfo.P1_BusinessSameForContact">
                  <label class="form-check-label" for="isSameAddress">同上</label>
                </div>
                <div class="address" v-show="!BaseInfoData.UserInfo.P1_BusinessSameForContact">
                  <input type="text" class="form-control" id="receiverAddress_1"
                    v-model="BaseInfoData.UserInfo.P1_BusinessAddressZipCode"
                    :data-required="!BaseInfoData.UserInfo.P1_BusinessSameForContact ? '1' : '0'"
                    data-required-msg="請填寫郵遞區號" aria-describedby="" placeholder="請填寫郵遞區號">
                  <input type="text" class="form-control" id="receiverAddress_2"
                    v-model="BaseInfoData.UserInfo.P1_BusinessAddress"
                    :data-required="!BaseInfoData.UserInfo.P1_BusinessSameForContact ? '1' : '0'"
                    data-required-msg="請填寫地址" aria-describedby="" placeholder="請填寫地址">
                </div>
                <p>（連鎖業者請以一家門店為代表填寫）</p>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_1 ||
              BaseInfoData.ColA.P1_2 ||
              BaseInfoData.ColA.P1_3 ||
              BaseInfoData.ColA.P1_4 ||
              BaseInfoData.ColA.P1_5 ||
              BaseInfoData.ColA.P1_6 ||
              BaseInfoData.ColA.P1_7 ||
              BaseInfoData.ColA.P1_8 ||
              BaseInfoData.ColA.P1_9 ||
              BaseInfoData.ColA.P1_10 ||
              BaseInfoData.ColA.P1_11
            ">
            <div class="bg" v-if="BaseInfoData.ColA.P1_12">
              <div class="form-group full" v-if="BaseInfoData.ColA.P1_12">
                <label for="">經營型態</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_BusinessType" id="P1_BusinessType1" value="1"
                      v-model="BaseInfoData.UserInfo.P1_BusinessType">
                    <label class="form-check-label" for="P1_BusinessType1">
                      連鎖
                    </label>
                    <div class="form-group number" v-if="BaseInfoData.UserInfo.P1_BusinessType == 1">
                      <p>共</p>
                      <input type="number" class="form-control" id="" aria-describedby="" placeholder=""
                        v-model.number="BaseInfoData.UserInfo.P1_NumberOfStores">
                      <p>家</p>
                    </div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_BusinessType" id="P1_BusinessType2" value="2"
                      v-model="BaseInfoData.UserInfo.P1_BusinessType">
                    <label class="form-check-label" for="P1_BusinessType2">
                      多店
                    </label>
                    <div class="form-group number" v-if="BaseInfoData.UserInfo.P1_BusinessType == 2">
                      <p>共</p>
                      <input type="number" class="form-control" id="" aria-describedby="" placeholder=""
                        v-model.number="BaseInfoData.UserInfo.P1_NumberOfStores">
                      <p>家</p>
                    </div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_BusinessType" id="P1_BusinessType3" value="3"
                      v-model="BaseInfoData.UserInfo.P1_BusinessType">
                    <label class="form-check-label" for="P1_BusinessType3">
                      單店
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_12">
            <div class="bg" v-if="BaseInfoData.ColA.P1_13">
              <div class="form-group full">
                <label for=""><span>*</span>營業概述</label>
                <div class="number">
                  <div>
                    <p>自民國年</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_EstablishmentYear" @change="CalYear()">
                    <p>開店/創立</p>
                  </div>
                  <p>，</p>
                  <div>
                    <p>至今共營業</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="TotalYear" readonly>
                    <p>年</p>
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>經營歷程</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_OperationalHistory"
                      id="P1_OperationalHistory1" value="1" v-model="BaseInfoData.UserInfo.P1_OperationalHistory">
                    <label class="form-check-label" for="P1_OperationalHistory1">
                      第一代
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_OperationalHistory"
                      id="P1_OperationalHistory2" value="2" v-model="BaseInfoData.UserInfo.P1_OperationalHistory">
                    <label class="form-check-label" for="P1_OperationalHistory2">
                      第二代
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_OperationalHistory"
                      id="P1_OperationalHistory3" value="3" v-model="BaseInfoData.UserInfo.P1_OperationalHistory">
                    <label class="form-check-label" for="P1_OperationalHistory3">
                      第三代
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_OperationalHistory"
                      id="P1_OperationalHistory4" value="4" v-model="BaseInfoData.UserInfo.P1_OperationalHistory">
                    <label class="form-check-label" for="P1_OperationalHistory4">
                      其他
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="BaseInfoData.UserInfo.P1_OperationalHistory == 4">
                <label for=""><span>*</span>其他經營歷程</label>
                <input type="text" class="form-control"
                  v-model="BaseInfoData.UserInfo.P1_OperationalHistoryOtherContent"
                  :data-required="BaseInfoData.UserInfo.P1_OperationalHistory == 4 ? '1' : '0'"
                  data-required-msg="請填寫其他經營歷程" aria-describedby="" placeholder="請填寫其他經營歷程">
              </div>
              <div class="form-group full staff">
                <label for=""><span>*</span>平均日來客數</label>
                <div class="number">
                  <div>
                    <p>假日</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_AvgHolidayCustomers">
                    <p>人</p>
                  </div>

                  <p class="aa">/</p>
                  <div>
                    <p>非假日</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_AvgNonHolidayCustomers">
                    <p>人</p>
                  </div>
                </div>
              </div>
              <div class="form-group full staff">
                <label for=""><span>*</span>平均營業額</label>
                <div class="number">
                  <div>
                    <p>假日</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_AvgHolidayRevenue">
                    <p>元</p>
                  </div>

                  <p class="aa">/</p>
                  <div>
                    <p>非假日</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_AvgNonHolidayRevenue">
                    <p>元</p>
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>現階段主要客源</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_CurrentMainCustomerSource" data-required="1"
                  data-required-msg="請填寫現階段主要客源" aria-describedby="" placeholder="請填寫現階段主要客源">
                </textarea>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>未來期待開拓客源</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedCustomerSourceExpansion" data-required="1"
                  data-required-msg="請填寫未來期待開拓客源" aria-describedby="" placeholder="請填寫未來期待開拓客源">
                </textarea>
              </div>
              <div class="form-group full staff" v-if="BaseInfoData.ColA.P1_42">
                <label for=""><span>*</span>座位數</label>
                <div class="number">
                  <div>
                    <p>店內座位數</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_SeatingCapacity">
                    <p>個</p>
                  </div>
                </div>
              </div>
              <div class="form-group full certificate" v-if="BaseInfoData.ColA.P1_42">
                <label for=""><span>*</span>是否有友善空間</label>
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_AccessibleSpace"
                      v-model="BaseInfoData.UserInfo.P1_AccessibleSpace">
                    <label class="form-check-label" for="P1_AccessibleSpace">無障礙空間</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_FreeWIFI"
                      v-model="BaseInfoData.UserInfo.P1_FreeWIFI">
                    <label class="form-check-label" for="P1_FreeWIFI">免費WIFI</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_FriendlyChargingStation"
                      v-model="BaseInfoData.UserInfo.P1_FriendlyChargingStation">
                    <label class="form-check-label" for="P1_FriendlyChargingStation">友善充電座</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_BabyChair"
                      v-model="BaseInfoData.UserInfo.P1_BabyChair">
                    <label class="form-check-label" for="P1_BabyChair">嬰兒座椅</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_OtherFriendlySpaces"
                      v-model="BaseInfoData.UserInfo.P1_OtherFriendlySpaces">
                    <label class="form-check-label" for="P1_OtherFriendlySpaces">其他</label>
                    <input type="" class="form-control" id="" v-if="BaseInfoData.UserInfo.P1_OtherFriendlySpaces"
                      v-model="BaseInfoData.UserInfo.P1_OtherFriendlySpacesContent"
                      :data-required="BaseInfoData.UserInfo.P1_OtherFriendlySpaces ? '1' : '0'"
                      data-required-msg="請填寫其他友善空間" aria-describedby="" placeholder="請填寫其他友善空間">
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_13">
            <div class="bg" v-if="BaseInfoData.ColA.P1_43">
              <div class="form-group full environment">
                <label for="">未來三年發展目標</label>
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ExpandNewStore"
                        v-model="BaseInfoData.UserInfo.P1_ExpandNewStore">
                      <label class="form-check-label" for="P1_ExpandNewStore">拓展新店面</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ExpandNewStore">
                  <label for=""><span>*</span>說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpandNewStoreContent" aria-describedby=""
                    placeholder="請填寫說明" :data-required="BaseInfoData.UserInfo.P1_ExpandNewStore ? '1' : '0'"
                    data-required-msg="請填寫說明"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_LaunchNewProductOrMarketingScheme"
                        v-model="BaseInfoData.UserInfo.P1_LaunchNewProductOrMarketingScheme">
                      <label class="form-check-label" for="P1_LaunchNewProductOrMarketingScheme">推出新產品/新行銷方案</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_LaunchNewProductOrMarketingScheme">
                  <label for=""><span>*</span>說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_LaunchNewProductOrMarketingSchemeContent"
                    aria-describedby="" placeholder="請填寫說明"
                    :data-required="BaseInfoData.UserInfo.P1_LaunchNewProductOrMarketingScheme ? '1' : '0'"
                    data-required-msg="請填寫說明"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ExpandECommerceChannels"
                        v-model="BaseInfoData.UserInfo.P1_ExpandECommerceChannels">
                      <label class="form-check-label" for="P1_ExpandECommerceChannels">拓展電商通路</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ExpandECommerceChannels">
                  <label for=""><span>*</span>說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpandECommerceChannelsContent"
                    aria-describedby="" placeholder="請填寫說明"
                    :data-required="BaseInfoData.UserInfo.P1_ExpandECommerceChannels ? '1' : '0'"
                    data-required-msg="請填寫說明"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_InvestInOtherVentures"
                        v-model="BaseInfoData.UserInfo.P1_InvestInOtherVentures">
                      <label class="form-check-label" for="P1_InvestInOtherVentures">轉投資其他事業</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_InvestInOtherVentures">
                  <label for=""><span>*</span>說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_InvestInOtherVenturesContent" aria-describedby=""
                    placeholder="請填寫說明" :data-required="BaseInfoData.UserInfo.P1_InvestInOtherVentures ? '1' : '0'"
                    data-required-msg="請填寫說明"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_OtherDevGoals"
                        v-model="BaseInfoData.UserInfo.P1_OtherDevGoals">
                      <label class="form-check-label" for="P1_OtherDevGoals">其他</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_OtherDevGoals">
                  <label for=""><span>*</span>說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_OtherDevGoalsContent" aria-describedby=""
                    placeholder="請填寫說明" :data-required="BaseInfoData.UserInfo.P1_OtherDevGoals ? '1' : '0'"
                    data-required-msg="請填寫說明"></textarea>
                </template>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_43">
            <div class="bg" v-if="BaseInfoData.ColA.P1_14">
              <div class="form-group full staff" v-if="BaseInfoData.ColA.P1_14">
                <label for="">員工概況</label>
                <div class="number">
                  <div>
                    <p>正職人員：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_PermanentStaff" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p>兼職人員：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_PartTimeStaff" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p>總人數：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="TotalStaff" readonly>
                    <p>位</p>
                  </div>
                </div>
                <div class="number">
                  <div>
                    <p>內場：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_Infield" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p class="count">內場比例：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="InfieldPercent" readonly>
                    <p>%</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p>外場：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_Outfield" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p class="count">外場比例：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="OutfieldPercent" readonly>
                    <p>%</p>
                  </div>
                </div>
                <div class="number">
                  <div>
                    <p>男性：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_Male" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p class="count">男性比例：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="MalePercent" readonly>
                    <p>%</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p>女性：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_Female" @change="CalStaff()">
                    <p>位</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p class="count">女性比例：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder=""
                      v-model.number="FemalePercent" readonly>
                    <p>%</p>
                  </div>
                </div>
                <div class="number">
                  <div>
                    <p>中高年齡(45歲以上)員工數：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_MiddleAged">
                    <p>位</p>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_14">
            <div class="bg" v-if="BaseInfoData.ColA.P1_41">
              <div class="form-group full staff">
                <label for=""><span>*</span>經費說明</label>
                <div class="number">
                  <div>
                    <p>輔導總經費：新臺幣</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_TotalGuidanceFunds">
                    <p>元（政府輔導款+廠商配合款）</p>
                  </div>
                </div>
                <div class="number">
                  <div>
                    <p>政府輔導款：新臺幣</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_GovtAidFunds">
                    <p>元（每案上限為新臺幣25萬元）</p>
                  </div>
                </div>
                <div class="number">
                  <div>
                    <p>廠商配合款：新臺幣</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_ManufacturerContributionFunds">
                    <p>元（不可少於政府輔導款金額1/2）</p>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_14">
            <div class="bg" v-if="BaseInfoData.ColA.P1_15">
              <div class="form-group full">
                <label for=""><span>*</span>品牌官網（品牌官方網站或社群平台等）</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_OfficialWebsite" data-required="1"
                  data-required-msg="請填寫品牌官網" aria-describedby="" placeholder="請填寫品牌官網">
                </textarea>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_15">
            <div class="bg" v-if="BaseInfoData.ColA.P1_16">
              <div class="form-group full certificate">
                <label for="">相關標章及證明</label>
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertTaiwanRice"
                      v-model="BaseInfoData.UserInfo.P1_CertTaiwanRice">
                    <label class="form-check-label" for="P1_CertTaiwanRice">臺灣米標章</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertCAS_HACPP"
                      v-model="BaseInfoData.UserInfo.P1_CertCAS_HACPP">
                    <label class="form-check-label" for="P1_CertCAS_HACPP">CAS/HACCP 認證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertGHP"
                      v-model="BaseInfoData.UserInfo.P1_CertGHP">
                    <label class="form-check-label" for="P1_CertGHP">餐飲衛生管理評核良級以上</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertFoodSafety"
                      v-model="BaseInfoData.UserInfo.P1_CertFoodSafety">
                    <label class="form-check-label" for="P1_CertFoodSafety">相關餐飲食品安全衛生佐證/標章</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertECO"
                      v-model="BaseInfoData.UserInfo.P1_CertECO">
                    <label class="form-check-label" for="P1_CertECO">綠色餐廳認證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertHalal"
                      v-model="BaseInfoData.UserInfo.P1_CertHalal">
                    <label class="form-check-label" for="P1_CertHalal">清真認證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MaterialCert"
                      v-model="BaseInfoData.UserInfo.P1_MaterialCert">
                    <label class="form-check-label" for="P1_MaterialCert">食材證明</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CertOther"
                      v-model="BaseInfoData.UserInfo.P1_CertOther">
                    <label class="form-check-label" for="P1_CertOther">其他</label>
                    <input type="" class="form-control" id="" v-if="BaseInfoData.UserInfo.P1_CertOther"
                      v-model="BaseInfoData.UserInfo.P1_CertOtherContent"
                      :data-required="BaseInfoData.UserInfo.P1_CertOther ? '1' : '0'" data-required-msg="請填寫其他相關標章及證明"
                      aria-describedby="" placeholder="請填寫其他相關標章及證明">
                  </div>
                </div>
                <div class="file_box">
                  <label for="">請提供相關標章及證明（截圖或影本），若無免提供。<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_CertFile"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_CertFile"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_CertFile_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_CertFile" id="P1_CertFile_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_16">
            <div class="bg" v-if="BaseInfoData.ColA.P1_17 ||
              BaseInfoData.ColA.P1_18 ||
              BaseInfoData.ColA.P1_19 ||
              BaseInfoData.ColA.P1_20">
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_17">
                <label for=""><span>*</span>門面照-1</label>
                <img v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_FacadePhoto.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_FacadePhoto"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_FacadePhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_FacadePhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_FacadePhoto" id="P1_FacadePhoto_data"
                    data-required="1" data-required-msg="請上傳門面照" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_17">
                <label for="">門面照-2</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_FacadePhoto2.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_FacadePhoto2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_FacadePhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_FacadePhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_FacadePhoto2" id="P1_FacadePhoto2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_17">
                <label for="">門面照-3</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_FacadePhoto3.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_FacadePhoto3"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_FacadePhoto3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_FacadePhoto3_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_FacadePhoto3" id="P1_FacadePhoto3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_18">
                <label for=""><span>*</span>包廂空間照-1</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BoothSpacePhoto.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BoothSpacePhoto"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BoothSpacePhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BoothSpacePhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BoothSpacePhoto" id="P1_BoothSpacePhoto_data"
                    data-required="1" data-required-msg="請上傳包廂空間照" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_18">
                <label for="">包廂空間照-2</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BoothSpacePhoto2.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BoothSpacePhoto2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BoothSpacePhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BoothSpacePhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BoothSpacePhoto2"
                    id="P1_BoothSpacePhoto2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_18">
                <label for="">包廂空間照-3</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BoothSpacePhoto3.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BoothSpacePhoto3"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BoothSpacePhoto3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BoothSpacePhoto3_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BoothSpacePhoto3"
                    id="P1_BoothSpacePhoto3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_19">
                <label for=""><span>*</span>用餐空間照-1</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_DiningAreaPhoto.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_DiningAreaPhoto"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_DiningAreaPhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_DiningAreaPhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_DiningAreaPhoto" id="P1_DiningAreaPhoto_data"
                    data-required="1" data-required-msg="請上傳用餐空間照" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_19">
                <label for="">用餐空間照-2</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_DiningAreaPhoto2.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_DiningAreaPhoto2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_DiningAreaPhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_DiningAreaPhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_DiningAreaPhoto2"
                    id="P1_DiningAreaPhoto2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_19">
                <label for="">用餐空間照-3</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_DiningAreaPhoto3.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_DiningAreaPhoto3"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_DiningAreaPhoto3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_DiningAreaPhoto3_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_DiningAreaPhoto3"
                    id="P1_DiningAreaPhoto3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_20">
                <label for=""><span>*</span>內場環境照-1</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_EnvironmentalPhoto.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_EnvironmentalPhoto"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_EnvironmentalPhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_EnvironmentalPhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_EnvironmentalPhoto"
                    id="P1_EnvironmentalPhoto_data" data-required="1" data-required-msg="請上傳內場環境照" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_20">
                <label for="">內場環境照-2</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_EnvironmentalPhoto2.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_EnvironmentalPhoto2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_EnvironmentalPhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_EnvironmentalPhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_EnvironmentalPhoto2"
                    id="P1_EnvironmentalPhoto2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full" v-if="BaseInfoData.ColA.P1_20">
                <label for="">內場環境照-3</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_EnvironmentalPhoto3.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_EnvironmentalPhoto3"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_EnvironmentalPhoto3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_EnvironmentalPhoto3_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_EnvironmentalPhoto3"
                    id="P1_EnvironmentalPhoto3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_17 ||
              BaseInfoData.ColA.P1_18 ||
              BaseInfoData.ColA.P1_19 ||
              BaseInfoData.ColA.P1_20">
            <div class="bg" v-if="BaseInfoData.ColA.P1_39">
              <div class="form-group full environment">
                <label for="">參與輔導項目</label>
                <br />
                <label for=""><span>*</span>參與輔導類別</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_GuidanceType" id="P1_GuidanceType1" value="1"
                      v-model="BaseInfoData.UserInfo.P1_GuidanceType">
                    <label class="form-check-label" for="P1_GuidanceType1">
                      組合型
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_GuidanceType" id="P1_GuidanceType2" value="2"
                      v-model="BaseInfoData.UserInfo.P1_GuidanceType">
                    <label class="form-check-label" for="P1_GuidanceType2">
                      供應鏈型
                    </label>
                  </div>
                </div>
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ProductDevGuidance"
                        v-model="BaseInfoData.UserInfo.P1_ProductDevGuidance">
                      <label class="form-check-label" for="P1_ProductDevGuidance">產品開發試銷輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ProductDevGuidance">
                  <label for=""><span>*</span>預計開發之產品數量</label>
                  <input type="number" min="0" aria-describedby="" placeholder="請填寫預計開發之產品數量" class="form-control"
                    v-model="BaseInfoData.UserInfo.P1_ExpectedProductQty"
                    :data-required="BaseInfoData.UserInfo.P1_ProductDevGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發之產品數量">
                  <label for=""><span>*</span>預計開發之產品名稱</label>
                  <P>品項與數量須相符</P>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedProductName" aria-describedby=""
                    placeholder="請填寫預計開發之產品名稱" :data-required="BaseInfoData.UserInfo.P1_ProductDevGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發之產品名稱"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ChannelExpGuidance"
                        v-model="BaseInfoData.UserInfo.P1_ChannelExpGuidance">
                      <label class="form-check-label" for="P1_ChannelExpGuidance">通路拓展輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ChannelExpGuidance">
                  <label for=""><span>*</span>預計開發通路之國家/地區</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedChannelCountry" aria-describedby=""
                    placeholder="請填寫預計開發通路之國家/地區"
                    :data-required="BaseInfoData.UserInfo.P1_ChannelExpGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發通路之國家/地區"></textarea>
                  <label for=""><span>*</span>預計開發通路名稱</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedChannelName" aria-describedby=""
                    placeholder="請填寫預計開發通路名稱" :data-required="BaseInfoData.UserInfo.P1_ChannelExpGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發通路名稱"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ExpectedService"
                        v-model="BaseInfoData.UserInfo.P1_ExpectedService">
                      <label class="form-check-label" for="P1_ExpectedService">服務拓展輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ExpectedService">
                  <label for=""><span>*</span>預計開發服務名稱</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedServiceName" aria-describedby=""
                    placeholder="請填寫預計開發服務名稱" :data-required="BaseInfoData.UserInfo.P1_ExpectedService ? '1' : '0'"
                    data-required-msg="請填寫預計開發服務名稱"></textarea>
                  <label for=""><span>*</span>預計開發服務之國家/地區</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedServiceTargetCountry" aria-describedby=""
                    placeholder="請填寫預計開發服務之國家/地區" :data-required="BaseInfoData.UserInfo.P1_ExpectedService ? '1' : '0'"
                    data-required-msg="請填寫預計開發服務之國家/地區"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ExpectedBusinessModel"
                        v-model="BaseInfoData.UserInfo.P1_ExpectedBusinessModel">
                      <label class="form-check-label" for="P1_ExpectedBusinessModel">商模拓展輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P1_ExpectedBusinessModel">
                  <label for=""><span>*</span>預計開發商模名稱</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedBusinessModelName" aria-describedby=""
                    placeholder="請填寫預計開發商模名稱"
                    :data-required="BaseInfoData.UserInfo.P1_ExpectedBusinessModel ? '1' : '0'"
                    data-required-msg="請填寫預計開發商模名稱"></textarea>
                  <label for=""><span>*</span>預計開發商模之國家/地區</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_ExpectedBusinessModelTargetCountry"
                    aria-describedby="" placeholder="請填寫預計開發商模之國家/地區"
                    :data-required="BaseInfoData.UserInfo.P1_ExpectedBusinessModel ? '1' : '0'"
                    data-required-msg="請填寫預計開發商模之國家/地區"></textarea>
                </template>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_39">
            <div class="bg" v-if="BaseInfoData.ColA.P1_40">
              <div class="form-group full">
                <label for="">政府政策配合證明(加分項目)</label>
                <P>配合政府政策推動事項</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_GovtPolicyComplianceProof" aria-describedby=""
                  placeholder="請填寫配合政府政策推動事項" v-if="false"></textarea>
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_RecentSalaryIncreases"
                      v-model="BaseInfoData.UserInfo.P1_RecentSalaryIncreases">
                    <label class="form-check-label" for="P1_RecentSalaryIncreases">近2年實施加薪(投保證明、薪資清冊等佐證)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_PromoteGenderDiversity"
                      v-model="BaseInfoData.UserInfo.P1_PromoteGenderDiversity">
                    <label class="form-check-label" for="P1_PromoteGenderDiversity">推動友善多元性別</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_EmploymentOfMiddleAgedWorkers"
                      v-model="BaseInfoData.UserInfo.P1_EmploymentOfMiddleAgedWorkers">
                    <label class="form-check-label"
                      for="P1_EmploymentOfMiddleAgedWorkers">僱用中高齡年齡就業者(45歲以上，投保證明佐證)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_OtherGovtPolicyCompliance"
                      v-model="BaseInfoData.UserInfo.P1_OtherGovtPolicyCompliance">
                    <label class="form-check-label" for="P1_OtherGovtPolicyCompliance">其他</label>
                    <input type="" class="form-control" id="" v-if="BaseInfoData.UserInfo.P1_OtherGovtPolicyCompliance"
                      v-model="BaseInfoData.UserInfo.P1_OtherGovtPolicyComplianceContent"
                      :data-required="BaseInfoData.UserInfo.P1_OtherGovtPolicyCompliance ? '1' : '0'"
                      data-required-msg="請填寫其他政府政策配合" aria-describedby="" placeholder="請填寫其他政府政策配合">
                  </div>
                </div>
                <div class="file_box">
                  <label for="">檢附相關相關佐證資料-1<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile">
                      (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_GovtPolicyComplianceProofFile"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_GovtPolicyComplianceProofFile_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile"
                      id="P1_GovtPolicyComplianceProofFile_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
                <div class="file_box">
                  <label for="">檢附相關相關佐證資料-2<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile2">
                      (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_GovtPolicyComplianceProofFile2"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_GovtPolicyComplianceProofFile2_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile2"
                      id="P1_GovtPolicyComplianceProofFile2_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
                <div class="file_box">
                  <label for="">檢附相關相關佐證資料-3<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile3">
                      (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_GovtPolicyComplianceProofFile3"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_GovtPolicyComplianceProofFile3_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile3"
                      id="P1_GovtPolicyComplianceProofFile3_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_40">
            <div class="bg" v-if="BaseInfoData.ColA.P1_21">
              <div class="form-group full">
                <label for="">媒體報導</label>
                <P>文字：【請條列式媒體報導】</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_MediaReport" aria-describedby=""
                  placeholder="請填寫媒體報導">
    </textarea>
                <P>網址：</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_MediaReportUrl" aria-describedby=""
                  placeholder="請填寫媒體報導網址">
    </textarea>
                <div class="file_box">
                  <label for="">【檢附相關報導畫面】<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_MediaReportFile"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_MediaReportFile"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_MediaReportFile_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_MediaReportFile"
                      id="P1_MediaReportFile_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_21">
            <div class="bg" v-if="BaseInfoData.ColA.P1_22">
              <div class="form-group full">
                <label for="">獲獎紀錄</label>
                <P>文字：【請條列式獲獎紀錄】</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_AwardRecord" aria-describedby=""
                  placeholder="請填寫獲獎紀錄"></textarea>
                <P>網址：</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_AwardRecordUrl" aria-describedby=""
                  placeholder="請填寫獲獎紀錄網址"></textarea>
                <div class="file_box">
                  <label for="">【檢附相關獲獎資料】<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_AwardRecordFile"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P1_AwardRecordFile"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P1_AwardRecordFile_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P1_AwardRecordFile"
                      id="P1_AwardRecordFile_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_22">
            <div class="bg" v-if="BaseInfoData.ColA.P1_23">
              <div class="form-group full">
                <label for=""><span>*</span>品牌介紹</label>
                <P>請提供目前品牌故事介紹200字以內、品牌 slogan，並附上品牌 logo 圖片和品牌相關的應用文宣品譬如招牌、DM 設計、提袋等 3~5 張照片</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_BrandStory" aria-describedby="" data-required="1"
                  data-required-msg="請填寫品牌介紹" placeholder="請填寫品牌介紹"></textarea>
              </div>
              <div class="form-group file_box full">
                <label for="">品牌介紹附加檔案-1</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BrandStoryFile.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BrandStoryFile"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BrandStoryFile"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BrandStoryFile_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BrandStoryFile" id="P1_BrandStoryFile_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">品牌介紹附加檔案-2</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BrandStoryFile2.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BrandStoryFile2"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BrandStoryFile2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BrandStoryFile2_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BrandStoryFile2"
                    id="P1_BrandStoryFile2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">品牌介紹附加檔案-3</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BrandStoryFile3.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BrandStoryFile3"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BrandStoryFile3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BrandStoryFile3_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BrandStoryFile3"
                    id="P1_BrandStoryFile3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">品牌介紹附加檔案-4</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BrandStoryFile4.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BrandStoryFile4"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BrandStoryFile4"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BrandStoryFile4_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BrandStoryFile4"
                    id="P1_BrandStoryFile4_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">品牌介紹附加檔案-5</label>
                <img
                  v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P1_BrandStoryFile5.includes('Upload')"
                  :src="BaseInfoData.SiteUrl + BaseInfoData.UserInfo.P1_BrandStoryFile5"
                  style="width: 300px; height: auto;">
                <div class="file">
                  <input type="file" id="P1_BrandStoryFile5"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="P1_BrandStoryFile5_name" data-namgtag="1" />
                  <input type="hidden" v-model="BaseInfoData.UserInfo.P1_BrandStoryFile5"
                    id="P1_BrandStoryFile5_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_23">

            <div class="bg" v-if="BaseInfoData.ColA.P1_24">
              <div class="form-group full">
                <label for=""><span>*</span>目前餐飲業者曝光行銷方式與宣傳管道</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_MarketingChannels" aria-describedby=""
                  placeholder="請填寫目前餐飲業者曝光行銷方式與宣傳管道"></textarea>
              </div>
              <div class="form-group full certificate">
                <label for="">餐飲業者使用哪些網路與社群行銷平台</label>
                <div class="check_group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCOfficialWebsite"
                      v-model="BaseInfoData.UserInfo.P1_MCOfficialWebsite">
                    <label class="form-check-label" for="P1_MCOfficialWebsite">官網</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCFacebook"
                      v-model="BaseInfoData.UserInfo.P1_MCFacebook">
                    <label class="form-check-label" for="P1_MCFacebook">Facebook</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCInstagram"
                      v-model="BaseInfoData.UserInfo.P1_MCInstagram">
                    <label class="form-check-label" for="P1_MCInstagram">Instagram</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCECommercePlatform"
                      v-model="BaseInfoData.UserInfo.P1_MCECommercePlatform">
                    <label class="form-check-label" for="P1_MCECommercePlatform">電商平台</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCBlog"
                      v-model="BaseInfoData.UserInfo.P1_MCBlog">
                    <label class="form-check-label" for="P1_MCBlog">部落格</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCOnlineAdvertising"
                      v-model="BaseInfoData.UserInfo.P1_MCOnlineAdvertising">
                    <label class="form-check-label" for="P1_MCOnlineAdvertising">網路廣告（關鍵字、廣告投放）</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCLINEAt"
                      v-model="BaseInfoData.UserInfo.P1_MCLINEAt">
                    <label class="form-check-label" for="P1_MCLINEAt">Line@</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MCOther"
                      v-model="BaseInfoData.UserInfo.P1_MCOther">
                    <label class="form-check-label" for="P1_MCOther">其他</label>
                    <input type="" class="form-control" id="" v-if="BaseInfoData.UserInfo.P1_MCOther"
                      v-model="BaseInfoData.UserInfo.P1_MCOtherContent"
                      :data-required="BaseInfoData.UserInfo.P1_MCOther ? '1' : '0'" data-required-msg="請填寫其他網路與社群行銷平台"
                      aria-describedby="" placeholder="請填寫其他網路與社群行銷平台">
                  </div>
                </div>
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>請舉例目前網路與社群行銷經營方式說明</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_OnlineAndSocialMediaMarketingApproach"
                  aria-describedby="" placeholder="請舉例目前網路與社群行銷經營方式說明"></textarea>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_24">
            <div class="bg" v-if="BaseInfoData.ColA.P1_25">
              <div class="form-group full">
                <label for=""><span>*</span>是否遭遇品牌經營、行銷瓶頸等相關問題</label>
                <div class="check_group radio factory">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_FacingBrandManagementOrMarketingChallenges"
                      id="P1_FacingBrandManagementOrMarketingChallenges1" v-bind:value="true"
                      v-model="BaseInfoData.UserInfo.P1_FacingBrandManagementOrMarketingChallenges">
                    <label class="form-check-label" for="P1_FacingBrandManagementOrMarketingChallenges">
                      有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_FacingBrandManagementOrMarketingChallenges"
                      id="P1_FacingBrandManagementOrMarketingChallenges2" v-bind:value="false"
                      v-model="BaseInfoData.UserInfo.P1_FacingBrandManagementOrMarketingChallenges">
                    <label class="form-check-label" for="P1_FacingBrandManagementOrMarketingChallenges2">
                      無
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_FacingBrandManagementOrMarketingChallenges">
                <label for=""><span>*</span>請條列簡述</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_DescriptionOfChallenges" aria-describedby=""
                  :data-required="BaseInfoData.UserInfo.P1_FacingBrandManagementOrMarketingChallenges ? '1' : '0'"
                  data-required-msg="請條列簡述" placeholder="請條列簡述"></textarea>
                <p>（建議盡量填寫完整）</p>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_25">
            <div class="bg" v-if="BaseInfoData.ColA.P1_26">
              <div class="form-group full">
                <label for=""><span>*</span>是否具有說菜能力</label>
                <div class="check_group radio factory">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_HasMenuDescriptionAbility"
                      id="P1_HasMenuDescriptionAbility1" v-bind:value="true"
                      v-model="BaseInfoData.UserInfo.P1_HasMenuDescriptionAbility">
                    <label class="form-check-label" for="P1_HasMenuDescriptionAbility1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_HasMenuDescriptionAbility"
                      id="P1_HasMenuDescriptionAbility2" v-bind:value="false"
                      v-model="BaseInfoData.UserInfo.P1_HasMenuDescriptionAbility">
                    <label class="form-check-label" for="P1_HasMenuDescriptionAbility2">
                      否
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_HasMenuDescriptionAbility">
                <label for="">請說明如何進行說菜</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_MenuDescriptionProcedure" aria-describedby=""
                  :data-required="BaseInfoData.UserInfo.P1_HasMenuDescriptionAbility ? '1' : '0'"
                  data-required-msg="請說明如何進行說菜" placeholder="請說明如何進行說菜"></textarea>
              </div>
              <div class="form-group full" v-if="!BaseInfoData.UserInfo.P1_HasMenuDescriptionAbility">
                <label for=""><span>*</span>是否有意願增進店內說菜能力</label>
                <div class="check_group radio factory">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_WillingToImproveMenuDescriptionAbility"
                      id="P1_WillingToImproveMenuDescriptionAbility1" v-bind:value="true"
                      v-model="BaseInfoData.UserInfo.P1_WillingToImproveMenuDescriptionAbility">
                    <label class="form-check-label" for="P1_WillingToImproveMenuDescriptionAbility1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_WillingToImproveMenuDescriptionAbility"
                      id="P1_WillingToImproveMenuDescriptionAbility2" v-bind:value="false"
                      v-model="BaseInfoData.UserInfo.P1_WillingToImproveMenuDescriptionAbility">
                    <label class="form-check-label" for="P1_WillingToImproveMenuDescriptionAbility2">
                      否
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_26">
            <div class="bg" v-if="BaseInfoData.ColA.P1_27">
              <div class="form-group full">
                <label for="">觀光潛力</label>
                <P>鄰近是否有著名觀光景點：</P>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_Attractions" aria-describedby=""
                  placeholder="請填寫鄰近著名觀光景點"></textarea>
                <div class="number travelers">
                  <div>
                    <p>國際旅客比例：</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_InternationalProportion">
                    <p>%</p>
                  </div>
                  <p class="aa">/</p>
                  <div>
                    <p>主要旅客國家為：</p>
                    <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_InternationalMain"
                      aria-describedby="" placeholder="請填寫主要旅客國家">
                  </div>
                </div>
                <label for="">店內菜單語言：</label>
                <div class="check_group language">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MenuEN"
                      v-model="BaseInfoData.UserInfo.P1_MenuEN">
                    <label class="form-check-label" for="P1_MenuEN">英文</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MenuJP"
                      v-model="BaseInfoData.UserInfo.P1_MenuJP">
                    <label class="form-check-label" for="P1_MenuJP">日文</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MenuKR"
                      v-model="BaseInfoData.UserInfo.P1_MenuKR">
                    <label class="form-check-label" for="P1_MenuKR">韓文</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_MenuOther"
                      v-model="BaseInfoData.UserInfo.P1_MenuOther">
                    <label class="form-check-label" for="P1_MenuOther">其他</label>
                    <input type="" class="form-control" id="" v-if="BaseInfoData.UserInfo.P1_MenuOther"
                      v-model="BaseInfoData.UserInfo.P1_MenuOtherContent"
                      :data-required="BaseInfoData.UserInfo.P1_MenuOther ? '1' : '0'" data-required-msg="請填寫其他店內菜單語言"
                      aria-describedby="" placeholder="請填寫其他店內菜單語言">
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_27">
            <div class="bg" v-if="BaseInfoData.ColA.P1_28">
              <div class="form-group full staff">
                <label for=""><span>*</span>平日營業時間（週一至週五）</label>
                <div class="number">
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_WeekdayOpeningTime_HH">
                      <option v-for="n in 24" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>:</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_WeekdayOpeningTime_MM">
                      <option v-for="n in 60" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>~</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_WeekdayClosingTime_HH">
                      <option v-for="n in 24" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>:</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_WeekdayClosingTime_MM">
                      <option v-for="n in 60" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group full staff">
                <label for=""><span>*</span>假日營業時間（週六及週日）</label>
                <div class="number">
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_HolidayOpeningTime_HH">
                      <option v-for="n in 24" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>:</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_HolidayOpeningTime_MM">
                      <option v-for="n in 60" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>~</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_HolidayClosingTime_HH">
                      <option v-for="n in 24" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                  <p>:</p>
                  <div>
                    <select class="form-control" v-model="BaseInfoData.P1_HolidayClosingTime_MM">
                      <option v-for="n in 60" :key="n">
                        {{ String(n - 1).padStart(2, '0') }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for=""><span>*</span>公休日</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_DayOff" data-required="1"
                  data-required-msg="請填寫公休日" aria-describedby="" placeholder="請填寫公休日">
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_28">
            <div class="bg" v-if="BaseInfoData.ColA.P1_29">
              <div class="form-group full environment">
                <label for="">相關活動參與意願調查</label>
                <div class="check_group aa box_2" style="display: block;">
                  <label for="">輔導：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ProductDevelopmentGuidance"
                        v-model="BaseInfoData.UserInfo.P1_ProductDevelopmentGuidance">
                      <label class="form-check-label" for="P1_ProductDevelopmentGuidance">產品開發試銷輔導</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_ChannelExpansionGuidance"
                        v-model="BaseInfoData.UserInfo.P1_ChannelExpansionGuidance">
                      <label class="form-check-label" for="P1_ChannelExpansionGuidance">通路拓展輔導</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_SingleStoreOptimizationGuidance"
                        v-model="BaseInfoData.UserInfo.P1_SingleStoreOptimizationGuidance">
                      <label class="form-check-label" for="P1_SingleStoreOptimizationGuidance">單店優化輔導</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_DemonstrationApplicationGuidance"
                        v-model="BaseInfoData.UserInfo.P1_DemonstrationApplicationGuidance">
                      <label class="form-check-label" for="P1_DemonstrationApplicationGuidance">示範應用輔導</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_FoodServiceSystemGuidance"
                        v-model="BaseInfoData.UserInfo.P1_FoodServiceSystemGuidance">
                      <label class="form-check-label" for="P1_FoodServiceSystemGuidance">餐飲體系輔導</label>
                    </div>
                  </div>
                </div>
                <div class="check_group aa box_2" style="display: block;">
                  <label for="">國際拓展：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_InternationalStudyExchange"
                        v-model="BaseInfoData.UserInfo.P1_InternationalStudyExchange">
                      <label class="form-check-label" for="P1_InternationalStudyExchange">國際考察交流活動</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_InternationalMediaMatching"
                        v-model="BaseInfoData.UserInfo.P1_InternationalMediaMatching">
                      <label class="form-check-label" for="P1_InternationalMediaMatching">國際媒合會</label>
                    </div>
                  </div>
                </div>
                <div class="check_group aa box_2" style="display: block;">
                  <label for="">行銷：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_OnlineMarketingActivities"
                        v-model="BaseInfoData.UserInfo.P1_OnlineMarketingActivities">
                      <label class="form-check-label" for="P1_OnlineMarketingActivities">網實行銷活動</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_TaiwanFoodExpo"
                        v-model="BaseInfoData.UserInfo.P1_TaiwanFoodExpo">
                      <label class="form-check-label" for="P1_TaiwanFoodExpo">台灣美食展</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_JointAchievementExpo"
                        v-model="BaseInfoData.UserInfo.P1_JointAchievementExpo">
                      <label class="form-check-label" for="P1_JointAchievementExpo">聯合成果展</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_29">
            <div class="bg" v-if="BaseInfoData.ColA.P1_30">
              <div class="form-group full">
                <label for=""><span>*</span>店面所有權</label>
                <div class="check_group radio factory">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_StoreOwnership" id="P1_StoreOwnership1"
                      v-bind:value="1" v-model="BaseInfoData.UserInfo.P1_StoreOwnership">
                    <label class="form-check-label" for="P1_StoreOwnership1">
                      店面自有
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_StoreOwnership" id="P1_StoreOwnership2"
                      v-bind:value="2" v-model="BaseInfoData.UserInfo.P1_StoreOwnership">
                    <label class="form-check-label" for="P1_StoreOwnership2">
                      店面租賃
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group full staff" v-if="BaseInfoData.UserInfo.P1_StoreOwnership == 2">
                <div class="number">
                  <div>
                    <p>已租賃使用達</p>
                    <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                      v-model.number="BaseInfoData.UserInfo.P1_LeaseDurationInYears">
                    <p>年</p>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_30">
            <div class="bg" v-if="BaseInfoData.ColA.P1_31">
              <div class="form-group full">
                <label for=""><span>*</span>是否有設置中央廚房/工廠</label>
                <div class="check_group radio factory">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_HasCentralKitchen" id="P1_HasCentralKitchen1"
                      v-bind:value="true" v-model="BaseInfoData.UserInfo.P1_HasCentralKitchen">
                    <label class="form-check-label" for="P1_HasCentralKitchen1">
                      有
                    </label>
                    <div class="aa" v-if="BaseInfoData.UserInfo.P1_HasCentralKitchen">
                      <div class="form-group">
                        <p class="title">共</p>
                        <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                          v-model.number="BaseInfoData.UserInfo.P1_CKAmount">
                        <p class="title">間</p>
                      </div>
                      <hr>
                      <div class="form-group">
                        <p class="title">中央工廠分布地點：</p>
                        <div class="form-check form-group">
                          <input type="radio" class="form-check-input" name="P1_CKLocal" id="P1_CKLocal1" value="1"
                            v-model="BaseInfoData.UserInfo.P1_CKLocal">
                          <label class="form-check-label" for="P1_CKLocal1">國內</label>
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_CKLocalName"
                              :data-required="BaseInfoData.UserInfo.P1_HasCentralKitchen && BaseInfoData.UserInfo.P1_CKLocal == 1 ? '1' : '0'"
                              data-required-msg="請填寫縣市" aria-describedby="" placeholder="請填寫縣市">
                          </div>
                        </div>
                        <div class="form-check form-group">
                          <input type="radio" class="form-check-input" name="P1_CKLocal" id="P1_CKLocal2" value="2"
                            v-model="BaseInfoData.UserInfo.P1_CKLocal">
                          <label class="form-check-label" for="P1_CKLocal2">國外</label>
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_CKLocalName"
                              :data-required="BaseInfoData.UserInfo.P1_HasCentralKitchen && BaseInfoData.UserInfo.P1_CKLocal == 2 ? '1' : '0'"
                              data-required-msg="請填寫國家" aria-describedby="" placeholder="請填寫國家">
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <p class="title">各座中央廚房/工廠平均產能（換算餐盒/食材/原料數量）：</p>
                        <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                          v-model.number="BaseInfoData.UserInfo.P1_CKCapacity">
                        <p class="title">份/月</p>
                      </div>
                      <div class="form-group">
                        <p>（如：一日產3千份，若一個月工作20天，則每月約產出6萬份，以此類推）</p>
                      </div>
                      <div class="form-group">
                        <p class="title">是否自有配送車輛：</p>
                        <div class="check_group">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="P1_CKHasDeliveryVehicle"
                              id="P1_CKHasDeliveryVehicle1" v-bind:value="true"
                              v-model="BaseInfoData.UserInfo.P1_CKHasDeliveryVehicle">
                            <label class="form-check-label" for="P1_CKHasDeliveryVehicle1">
                              有
                            </label>
                            <div class="form-group" v-if="BaseInfoData.UserInfo.P1_CKHasDeliveryVehicle">
                              <p class="title">共</p>
                              <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                                v-model.number="BaseInfoData.UserInfo.P1_CKDeliveryVehicleAmount">
                              <p class="title">輛</p>
                            </div>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="P1_CKHasDeliveryVehicle"
                              id="P1_CKHasDeliveryVehicle2" v-bind:value="false"
                              v-model="BaseInfoData.UserInfo.P1_CKHasDeliveryVehicle">
                            <label class="form-check-label" for="P1_CKHasDeliveryVehicle2">
                              無
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="check_group form-group">
                        <p class="title">餐點是以何種形式盛裝：</p>
                        <div class="form-check form-group appear">
                          <input type="radio" class="form-check-input" id="P1_CKPackType" value="1"
                            v-model="BaseInfoData.UserInfo.P1_CKPackType">
                          <label class="form-check-label" for="P1_CKPackType">桶/箱裝</label>
                        </div>
                        <div class="form-check form-group">
                          <input type="radio" class="form-check-input" id="P1_CKPackType" value="2"
                            v-model="BaseInfoData.UserInfo.P1_CKPackType">
                          <label class="form-check-label" for="P1_CKPackType">餐盒裝</label>
                        </div>
                      </div>
                      <div class="form-group full none" style="display: block;"
                        v-if="BaseInfoData.UserInfo.P1_CKPackType == 1">
                        <p class="title">若為餐桶/箱運送，回收方式為何?</p>
                        <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_CKPackRecycle" aria-describedby=""
                          :data-required="BaseInfoData.UserInfo.P1_HasCentralKitchen ? '1' : '0'"
                          data-required-msg="請填寫中央廚房/工廠相關資訊" placeholder="請填寫回收方式"></textarea>
                      </div>
                      <div class="form-group">
                        <p class="title">餐點供應範圍? </p>
                        <div class="bb">
                          <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                            v-model.number="BaseInfoData.UserInfo.P1_CKScopeOfSupply">
                          <p class="title">km</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <p class="title">每車每趟送運配置人力（不含司機）約幾位? </p>
                        <div class="bb">
                          <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                            v-model.number="BaseInfoData.UserInfo.P1_CKManpower">
                          <p class="title">位</p>
                        </div>
                      </div>
                      <div class="form-group">
                        <p class="title">餐點是否需要再加熱?</p>
                        <div class="check_group radio">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="P1_CKNeedReheat" id="P1_CKNeedReheat1"
                              v-bind:value="false" v-model="BaseInfoData.UserInfo.P1_CKNeedReheat">
                            <label class="form-check-label" for="P1_CKNeedReheat1">
                              是
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="P1_CKNeedReheat" id="P1_CKNeedReheat2"
                              v-bind:value="true" v-model="BaseInfoData.UserInfo.P1_CKNeedReheat">
                            <label class="form-check-label" for="P1_CKNeedReheat2">
                              否
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_HasCentralKitchen" id="P1_HasCentralKitchen2"
                      v-bind:value="false" v-model="BaseInfoData.UserInfo.P1_HasCentralKitchen">
                    <label class="form-check-label" for="P1_HasCentralKitchen2">
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_31">
            <div class="bg" v-if="BaseInfoData.ColA.P1_32">
              <div class="form-group full inspection">
                <div class="check_group">
                  <label for=""><span>*</span>調理產品檢驗</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_CookingProductStatus"
                      id="P1_CookingProductStatus1" v-bind:value="0"
                      v-model="BaseInfoData.UserInfo.P1_CookingProductStatus">
                    <label class="form-check-label" for="P1_CookingProductStatus1">
                      目前尚無調理產品
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_CookingProductStatus"
                      id="P1_CookingProductStatus2" v-bind:value="1"
                      v-model="BaseInfoData.UserInfo.P1_CookingProductStatus">
                    <label class="form-check-label" for="P1_CookingProductStatus2">
                      現有調理產品皆尚未經過相關檢驗
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_CookingProductStatus"
                      id="P1_CookingProductStatus3" v-bind:value="2"
                      v-model="BaseInfoData.UserInfo.P1_CookingProductStatus">
                    <label class="form-check-label" for="P1_CookingProductStatus3">
                      調理產品已進行最終產品檢驗
                    </label>
                    <div class="form-group" v-if="BaseInfoData.UserInfo.P1_CookingProductStatus == 2">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="P1_ComplianceWithFoodSafetyRegulations"
                          v-model="BaseInfoData.UserInfo.P1_ComplianceWithFoodSafetyRegulations">
                        <label class="form-check-label"
                          for="P1_ComplianceWithFoodSafetyRegulations">符合我國「食品安全衛生管理法」所訂食品相關衛生標準或相關規範</label>
                      </div>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="P1_ComplianceWithExportStandards"
                          v-model="BaseInfoData.UserInfo.P1_ComplianceWithExportStandards">
                        <label class="form-check-label"
                          for="P1_ComplianceWithExportStandards">符合輸出國之產品檢驗標準及包裝標示規定</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg" v-if="BaseInfoData.ColA.P1_33 && BaseInfoData.UserInfo.P1_CookingProductStatus != 0">
              <div class="form-group full inspection factory">
                <label for=""><span>*</span>製作調理產品之食品工廠</label>
                <div class="check_group">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_FoodFactoryStatus" id="P1_FoodFactoryStatus1"
                      v-bind:value="0" v-model="BaseInfoData.UserInfo.P1_FoodFactoryStatus">
                    <label class="form-check-label" for="P1_FoodFactoryStatus1">
                      目前尚無自有或委外製作調理包之食品工廠(無則以下免填)
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_FoodFactoryStatus" id="P1_FoodFactoryStatus2"
                      v-bind:value="1" v-model="BaseInfoData.UserInfo.P1_FoodFactoryStatus">
                    <label class="form-check-label" for="P1_FoodFactoryStatus2">
                      自有工廠
                    </label>
                    <div class="form-group" v-if="BaseInfoData.UserInfo.P1_FoodFactoryStatus == 1">
                      <p>共</p>
                      <input type="number" class="form-control" id="" aria-describedby="" placeholder="" min="0"
                        v-model.number="BaseInfoData.UserInfo.P1_OwnFactoryQuantity">
                      <p>間</p>
                    </div>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_FoodFactoryStatus" id="P1_FoodFactoryStatus3"
                      v-bind:value="2" v-model="BaseInfoData.UserInfo.P1_FoodFactoryStatus">
                    <label class="form-check-label" for="P1_FoodFactoryStatus3">
                      代工廠
                    </label>
                    <div class="form-group" v-if="BaseInfoData.UserInfo.P1_FoodFactoryStatus == 2">
                      <p>合作廠商為</p>
                      <input type="" class="form-control" id=""
                        v-model="BaseInfoData.UserInfo.P1_OEMFactoryCollaborators"
                        :data-required="BaseInfoData.UserInfo.P1_CookingProductStatus != 0 && BaseInfoData.UserInfo.P1_FoodFactoryStatus == 2 ? '1' : '0'"
                        data-required-msg="請填代工廠合作廠商" aria-describedby="" placeholder="請填寫代工廠合作廠商">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group full pass" v-if="BaseInfoData.UserInfo.P1_FoodFactoryStatus != 0">
                <label for="">上述工廠已通過檢驗</label>
                <div class="form-group">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_ISO22000Certification"
                      v-model="BaseInfoData.UserInfo.P1_ISO22000Certification">
                    <label class="form-check-label" for="P1_ISO22000Certification">ISO22000驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_HACCPCertification"
                      v-model="BaseInfoData.UserInfo.P1_HACCPCertification">
                    <label class="form-check-label" for="P1_HACCPCertification">HACCP驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_HalalCertification"
                      v-model="BaseInfoData.UserInfo.P1_HalalCertification">
                    <label class="form-check-label" for="P1_HalalCertification">清真驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_CASExcellentAgriculturalProductMark"
                      v-model="BaseInfoData.UserInfo.P1_CASExcellentAgriculturalProductMark">
                    <label class="form-check-label" for="P1_CASExcellentAgriculturalProductMark">CAS優良農產品標章</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_TQFTaiwanExcellentFoodCertification"
                      v-model="BaseInfoData.UserInfo.P1_TQFTaiwanExcellentFoodCertification">
                    <label class="form-check-label" for="P1_TQFTaiwanExcellentFoodCertification">TQF台灣優良食品驗證</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_SQFCertification"
                      v-model="BaseInfoData.UserInfo.P1_SQFCertification">
                    <label class="form-check-label" for="P1_SQFCertification">SQF驗證(Safe Quality Food
                      certification)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_FSSF22000Certification"
                      v-model="BaseInfoData.UserInfo.P1_FSSF22000Certification">
                    <label class="form-check-label" for="P1_FSSF22000Certification">FSSF 22000驗證(Food Safety System
                      certification)</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="P1_OtherCertifications"
                      v-model="BaseInfoData.UserInfo.P1_OtherCertifications">
                    <label class="form-check-label" for="P1_OtherCertifications">其他</label>
                    <input type="text" class="form-control" v-if="BaseInfoData.UserInfo.P1_OtherCertifications"
                      v-model="BaseInfoData.UserInfo.P1_OtherCertificationsContent"
                      :data-required="BaseInfoData.UserInfo.P1_CookingProductStatus != 0 && BaseInfoData.UserInfo.P1_OtherCertifications ? '1' : '0'"
                      data-required-msg="請填寫其他檢驗內容" aria-describedby="" placeholder="請填寫其他檢驗內容">
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_33">
            <div class="bg" v-if="BaseInfoData.ColA.P1_34">
              <div class="form-group full environment">
                <label for=""><span>*</span>餐具使用情形</label>
                <div class="check_group radio">
                  <label for="">內用餐器具是否為「重複使用」餐具</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_Reuse" id="P1_Reuse1" v-bind:value="true"
                      v-model="BaseInfoData.UserInfo.P1_Reuse">
                    <label class="form-check-label" for="P1_Reuse1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P1_Reuse" id="P1_Reuse2" v-bind:value="false"
                      v-model="BaseInfoData.UserInfo.P1_Reuse">
                    <label class="form-check-label" for="P1_Reuse2">
                      否
                    </label>
                  </div>
                </div>
                <div class="check_group radio">
                  <label for="">外帶是否使用「循環容器」之餐具</label>
                  <div class="form-check form-check-inline btn_1">
                    <input class="form-check-input" type="radio" name="P1_LoopContainer" id="P1_LoopContainer1"
                      v-bind:value="true" v-model="BaseInfoData.UserInfo.P1_LoopContainer">
                    <label class="form-check-label" for="P1_LoopContainer1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline btn_2">
                    <input class="form-check-input" type="radio" name="P1_LoopContainer" id="P1_LoopContainer2"
                      v-bind:value="false" v-model="BaseInfoData.UserInfo.P1_LoopContainer">
                    <label class="form-check-label" for="P1_LoopContainer2">
                      否
                    </label>
                  </div>
                </div>
                <div class="check_group aa box_1"
                  :style="BaseInfoData.UserInfo.P1_LoopContainer ? 'display: block;' : ''"
                  v-if="BaseInfoData.UserInfo.P1_LoopContainer">
                  <label for="">循環容器之材質：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_LCSS"
                        v-model="BaseInfoData.UserInfo.P1_LCSS">
                      <label class="form-check-label" for="P1_LCSS">不鏽鋼</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_LCPYREX"
                        v-model="BaseInfoData.UserInfo.P1_LCPYREX">
                      <label class="form-check-label" for="P1_LCPYREX">耐熱玻璃</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_LCPLA"
                        v-model="BaseInfoData.UserInfo.P1_LCPLA">
                      <label class="form-check-label" for="P1_LCPLA">生物可分解塑膠</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_LCOther"
                        v-model="BaseInfoData.UserInfo.P1_LCOther">
                      <label class="form-check-label" for="P1_LCOther">其他</label>
                      <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_LCOtherContent"
                        v-if="BaseInfoData.UserInfo.P1_LCOther"
                        :data-required="BaseInfoData.UserInfo.P1_LCOther ? '1' : '0'" data-required-msg="請填寫其他循環容器之材質"
                        aria-describedby="" placeholder="請填寫其他循環容器之材質">
                    </div>
                  </div>
                </div>
                <div class="check_group aa box_2"
                  :style="!BaseInfoData.UserInfo.P1_LoopContainer ? 'display: block;' : ''"
                  v-if="!BaseInfoData.UserInfo.P1_LoopContainer">
                  <label for="">目前使用之盛裝品材質為：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_NoLCRecyclable"
                        v-model="BaseInfoData.UserInfo.P1_NoLCRecyclable">
                      <label class="form-check-label" for="P1_NoLCRecyclable">回收標誌</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_NoLCSoyInk"
                        v-model="BaseInfoData.UserInfo.P1_NoLCSoyInk">
                      <label class="form-check-label" for="P1_NoLCSoyInk">使用大豆油墨</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_NoLCPehosphateFree"
                        v-model="BaseInfoData.UserInfo.P1_NoLCPehosphateFree">
                      <label class="form-check-label" for="P1_NoLCPehosphateFree">包裝無磷膜</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_NoLCEcoMaterials"
                        v-model="BaseInfoData.UserInfo.P1_NoLCEcoMaterials">
                      <label class="form-check-label" for="P1_NoLCEcoMaterials">使用紙類或木片、甘蔗、稻殼等環保材質</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_NoLCOther"
                        v-model="BaseInfoData.UserInfo.P1_NoLCOther">
                      <label class="form-check-label" for="P1_NoLCOther">其他</label>
                      <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_NoLCOtherContent"
                        v-if="BaseInfoData.UserInfo.P1_NoLCOther"
                        :data-required="BaseInfoData.UserInfo.P1_NoLCOther ? '1' : '0'" data-required-msg="請填寫其他盛裝品材質"
                        aria-describedby="" placeholder="請填寫其他盛裝品材質">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_34">
            <div class="bg" v-if="BaseInfoData.ColA.P1_35">
              <div class="form-group full counseling">
                <label for="">接受過政府輔導</label>
                <div class="check_group radio">
                  <div class="form-check form-check-inline btn_1">
                    <input class="form-check-input" type="radio" name="P1_HasGovGuidance" id="P1_HasGovGuidance1"
                      v-bind:value="false" v-model="BaseInfoData.UserInfo.P1_HasGovGuidance">
                    <label class="form-check-label" for="P1_HasGovGuidance1">
                      否
                    </label>
                  </div>
                  <div class="form-check form-check-inline btn_2">
                    <input class="form-check-input" type="radio" name="P1_HasGovGuidance" id="P1_HasGovGuidance2"
                      v-bind:value="true" v-model="BaseInfoData.UserInfo.P1_HasGovGuidance">
                    <label class="form-check-label" for="P1_HasGovGuidance2">
                      是
                    </label>
                  </div>
                </div>
                <div class="check_group aa box_1"
                  :style="BaseInfoData.UserInfo.P1_HasGovGuidance ? 'display: block;' : ''"
                  v-if="BaseInfoData.UserInfo.P1_HasGovGuidance">
                  <div class="form-group">
                    <label for="">曾參與</label>
                    <label for="">計畫名稱：</label>
                    <input type="text" class="form-control" id="" v-model="BaseInfoData.UserInfo.P1_GovProjectName"
                      :data-required="BaseInfoData.UserInfo.P1_HasGovGuidance ? '1' : '0'" data-required-msg="請填曾參與計畫名稱"
                      aria-describedby="" placeholder="請填曾參與計畫名稱">
                  </div>
                  <div class="form-group full">
                    <label for="">政府輔導經費：</label>
                    <input type="text" class="form-control" id="" v-model="BaseInfoData.UserInfo.P1_GovFunding"
                      :data-required="BaseInfoData.UserInfo.P1_HasGovGuidance ? '1' : '0'" data-required-msg="請填政府輔導經費"
                      aria-describedby="" placeholder="請填政府輔導經費">
                  </div>
                  <div class="form-group full">
                    <label for="">輔導成效：</label>
                    <textarea rows="5" v-model="BaseInfoData.UserInfo.P1_GovEffectiveness" aria-describedby=""
                      :data-required="BaseInfoData.UserInfo.P1_HasGovGuidance ? '1' : '0'" data-required-msg="請填寫輔導成效"
                      placeholder="請填寫輔導成效"></textarea>
                    <p>（請說明改善內容、效益與滿意度）</p>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_35">
            <div class="bg" v-if="BaseInfoData.ColA.P1_36">
              <div class="form-group full expand">
                <label for="">國際拓展狀況</label>
                <div class="check_group radio">
                  <div class="form-check form-check-inline btn_1">
                    <input class="form-check-input" type="radio" name="P1_InternationalExpansionStatus"
                      id="P1_InternationalExpansionStatus1" v-bind:value="false"
                      v-model="BaseInfoData.UserInfo.P1_InternationalExpansionStatus">
                    <label class="form-check-label" for="P1_InternationalExpansionStatus1">
                      否
                    </label>
                  </div>
                  <div class="form-check form-check-inline btn_2">
                    <input class="form-check-input" type="radio" name="P1_InternationalExpansionStatus"
                      id="P1_InternationalExpansionStatus2" v-bind:value="true"
                      v-model="BaseInfoData.UserInfo.P1_InternationalExpansionStatus">
                    <label class="form-check-label" for="P1_InternationalExpansionStatus2">
                      是
                    </label>
                  </div>
                </div>
                <div class="check_group aa box_1"
                  :style="BaseInfoData.UserInfo.P1_InternationalExpansionStatus ? 'display: block;' : ''"
                  v-if="BaseInfoData.UserInfo.P1_InternationalExpansionStatus">
                  <label for="">已具有以下經驗：</label>
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_IEOverseasStores"
                        v-model="BaseInfoData.UserInfo.P1_IEOverseasStores">
                      <label class="form-check-label" for="P1_IEOverseasStores">海外展店</label>
                      <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_IEOverseasStores">
                        <textarea rows="3" v-model="BaseInfoData.UserInfo.P1_IEOverseasStoresContent"
                          aria-describedby="" :data-required="BaseInfoData.UserInfo.P1_IEOverseasStores ? '1' : '0'"
                          data-required-msg="請填寫海外展店國家別" placeholder="請填寫海外展店國家別"></textarea>
                      </div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_IEProductExports"
                        v-model="BaseInfoData.UserInfo.P1_IEProductExports">
                      <label class="form-check-label" for="P1_IEProductExports">產品輸出</label>
                      <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_IEProductExports">
                        <textarea rows="3" v-model="BaseInfoData.UserInfo.P1_IEProductExportsContent"
                          aria-describedby="" :data-required="BaseInfoData.UserInfo.P1_IEProductExports ? '1' : '0'"
                          data-required-msg="請填寫產品輸出國家別" placeholder="請填寫產品輸出國家別"></textarea>
                      </div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_IETechLicense"
                        v-model="BaseInfoData.UserInfo.P1_IETechLicense">
                      <label class="form-check-label" for="P1_IETechLicense">技術授權</label>
                      <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_IETechLicense">
                        <textarea rows="3" v-model="BaseInfoData.UserInfo.P1_IETechLicenseContent" aria-describedby=""
                          :data-required="BaseInfoData.UserInfo.P1_IETechLicense ? '1' : '0'"
                          data-required-msg="請填寫技術授權國家別" placeholder="請填寫技術授權國家別"></textarea>
                      </div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P1_IEBrandLicense"
                        v-model="BaseInfoData.UserInfo.P1_IEBrandLicense">
                      <label class="form-check-label" for="P1_IEBrandLicense">品牌授權</label>
                      <div class="form-group full" v-if="BaseInfoData.UserInfo.P1_IEBrandLicense">
                        <textarea rows="3" v-model="BaseInfoData.UserInfo.P1_IEBrandLicenseContent" aria-describedby=""
                          :data-required="BaseInfoData.UserInfo.P1_IEBrandLicense ? '1' : '0'"
                          data-required-msg="請填寫品牌授權國家別" placeholder="請填寫品牌授權國家別"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_36">
            <div class="bg" v-if="BaseInfoData.ColA.P1_37">
              <div class="form-group">
                <label for=""><span>*</span>計劃主持人姓名</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_HostName" data-required="1"
                  data-required-msg="請填寫計劃主持人姓名" aria-describedby="" placeholder="請填寫計劃主持人姓名">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>計劃主持人職稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_HostTitle" data-required="1"
                  data-required-msg="請填寫計劃主持人職稱" aria-describedby="" placeholder="請填寫計劃主持人職稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>聯絡電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_HostPhone" data-required="1"
                  data-required-msg="請填寫聯絡電話" aria-describedby="" placeholder="請填寫聯絡電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>行動電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_HostMobile" data-required="1"
                  data-required-msg="請填寫行動電話" aria-describedby="" placeholder="請填寫行動電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>電子郵件</label>
                <input type="email" class="form-control" v-model="BaseInfoData.UserInfo.P1_HostEmail" data-required="1"
                  data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_37">
            <div class="bg" v-if="BaseInfoData.ColA.P1_38">
              <div class="form-group">
                <label for=""><span>*</span>聯絡人姓名</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactName" data-required="1"
                  data-required-msg="請填寫聯絡人姓名" aria-describedby="" placeholder="請填寫聯絡人姓名">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>聯絡人職稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactJobName"
                  data-required="1" data-required-msg="請填寫聯絡人職稱" aria-describedby="" placeholder="請填寫聯絡人職稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>聯絡電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactTelphone"
                  data-required="1" data-required-msg="請填寫聯絡電話" aria-describedby="" placeholder="請填寫聯絡電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>行動電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactPhone"
                  data-required="1" data-required-msg="請填寫行動電話" aria-describedby="" placeholder="請填寫行動電話">
              </div>
              <div class="form-group">
                <label for="">LineID</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactLineID"
                  placeholder="請填寫LineID">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>電子郵件</label>
                <input type="email" class="form-control" v-model="BaseInfoData.UserInfo.P1_ContactEmail"
                  data-required="1" data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P1_38">
            <div v-if="!BaseInfoData.ColAHasCol" style="text-align: center; width: 100%; margin: 10px 0;">
              <h1 style="border-bottom: 0px;">無需填寫，請點選下一步。</h1>
            </div>
            <hr v-if="!BaseInfoData.ColAHasCol">
            <div class="btn_box">
              <!-- <router-link v-if="BaseInfoData.UserInfo.URIId != -1"
                :to="{ name: 'EventItem', params: { EId: BaseInfoData.Event.EId } }" tag="button">前往品項管理</router-link> -->
              <button @click="gotoStep(2)">下一步</button>
              <button @click="save_temp()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">暫　　存</button>
              <button @click="save()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
          <div id="stet_2" class="box" :class="Step == 2 ? 'show' : ''">
            <div class="bg" v-if="BaseInfoData.ColB.P2_1 ||
              BaseInfoData.ColB.P2_2 ||
              BaseInfoData.ColB.P2_3 ||
              BaseInfoData.ColB.P2_4 ||
              BaseInfoData.ColB.P2_5 ||
              BaseInfoData.ColB.P2_6 ||
              BaseInfoData.ColB.P2_7 ||
              BaseInfoData.ColB.P2_8">
              <div class="form-group" v-if="BaseInfoData.ColB.P2_1">
                <label for="">統一編號</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_TaxID" aria-describedby=""
                  placeholder="請填寫統一編號">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColB.P2_2">
                <label for="">單位名稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_UnitName" aria-describedby=""
                  placeholder="請填寫單位名稱">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColB.P2_3">
                <label for="">創立時間</label>
                <input type="text" id="P2_FoundingTime" class="form-control"
                  v-model="BaseInfoData.UserInfo.P2_FoundingTime" aria-describedby="" placeholder="" v-once>
                <p>（立案或登記證明書日期）</p>
              </div>
              <div class="form-group" v-if="BaseInfoData.ColB.P2_4">
                <label for="">資本額</label>
                <input type="number" min="0" class="form-control"
                  v-model.number="BaseInfoData.UserInfo.P2_CapitalAmount" aria-describedby="" placeholder="請填寫資本額">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColB.P2_5">
                <label for="">在職人數</label>
                <input type="number" min="0" class="form-control"
                  v-model.number="BaseInfoData.UserInfo.P2_NumberOfEmployees" aria-describedby="" placeholder="請填寫在職人數">
              </div>
              <div class="form-group" v-if="BaseInfoData.ColB.P2_6">
                <label for="">負責人</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_Principal" aria-describedby=""
                  placeholder="請填寫負責人">
              </div>
              <div class="form-group full" v-if="BaseInfoData.ColB.P2_7">
                <label for="">網址</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P2_Url" aria-describedby=""
                  placeholder="請填寫網址"></textarea>
              </div>
              <div class="form-group full address_box" v-if="BaseInfoData.ColB.P2_8">
                <label for=""><span>*</span>通訊地址</label>
                <div class="address">
                  <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactAddressZipCode"
                    data-required="1" data-required-msg="請填寫郵遞區號" aria-describedby="" placeholder="請填寫郵遞區號">
                  <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactAddress"
                    data-required="1" data-required-msg="請填寫地址" aria-describedby="" placeholder="請填寫地址">
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColB.P2_1 ||
              BaseInfoData.ColB.P2_2 ||
              BaseInfoData.ColB.P2_3 ||
              BaseInfoData.ColB.P2_4 ||
              BaseInfoData.ColB.P2_5 ||
              BaseInfoData.ColB.P2_6 ||
              BaseInfoData.ColB.P2_7 ||
              BaseInfoData.ColB.P2_8">
            <div class="bg" v-if="BaseInfoData.ColB.P2_9">
              <div class="form-group full stronghold">
                <label for="">海外據點</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline btn_2">
                    <input class="form-check-input" type="radio" name="P2_HasOverseasBase" id="P2_HasOverseasBase1"
                      v-bind:value="false" v-model="BaseInfoData.UserInfo.P2_HasOverseasBase">
                    <label class="form-check-label" for="P2_HasOverseasBase1">
                      無海外據點
                    </label>
                  </div>
                  <div class="form-check form-check-inline btn_1">
                    <input class="form-check-input" type="radio" name="P2_HasOverseasBase" id="P2_HasOverseasBase2"
                      v-bind:value="true" v-model="BaseInfoData.UserInfo.P2_HasOverseasBase">
                    <label class="form-check-label" for="P2_HasOverseasBase2">
                      有海外據點
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group aa stronghold"
                :style="BaseInfoData.UserInfo.P2_HasOverseasBase ? 'display: block;' : ''"
                v-if="BaseInfoData.UserInfo.P2_HasOverseasBase">
                <div class="form-group">
                  <label for="">數量：</label>
                  <input type="number" class="form-control" aria-describedby="" placeholder="" min="0"
                    v-model.number="BaseInfoData.UserInfo.P2_OBAmount"
                    :data-required="BaseInfoData.UserInfo.P2_HasOverseasBase ? '1' : '0'" data-required-msg="請填寫海外據點數量">
                </div>
                <div class="form-group full bb">
                  <label for="">臚列所在國家及城市名稱：</label>
                  <div class="form-group">
                    <input type="text" class="form-control" id="" v-model="BaseInfoData.UserInfo.P2_OBCountry"
                      :data-required="BaseInfoData.UserInfo.P2_HasOverseasBase ? '1' : '0'"
                      data-required-msg="請填寫海外據點國家" aria-describedby="" placeholder="請填寫海外據點國家">
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" id="" v-model="BaseInfoData.UserInfo.P2_OBCity"
                      :data-required="BaseInfoData.UserInfo.P2_HasOverseasBase ? '1' : '0'"
                      data-required-msg="請填寫海外據點城市" aria-describedby="" placeholder="請填寫海外據點城市">
                  </div>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColB.P2_9">
            <div class="bg" v-if="BaseInfoData.ColB.P2_10">
              <div class="form-group">
                <label for=""><span>*</span>計劃主持人姓名</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_HostName" data-required="1"
                  data-required-msg="請填寫計劃主持人姓名" aria-describedby="" placeholder="請填寫計劃主持人姓名">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>計劃主持人職稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_HostTitle" data-required="1"
                  data-required-msg="請填寫計劃主持人職稱" aria-describedby="" placeholder="請填寫計劃主持人職稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>聯絡電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_HostPhone" data-required="1"
                  data-required-msg="請填寫聯絡電話" aria-describedby="" placeholder="請填寫聯絡電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>行動電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_HostMobile" data-required="1"
                  data-required-msg="請填寫行動電話" aria-describedby="" placeholder="請填寫行動電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>電子郵件</label>
                <input type="email" class="form-control" v-model="BaseInfoData.UserInfo.P2_HostEmail" data-required="1"
                  data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              </div>
            </div>
            <hr v-if="BaseInfoData.ColB.P2_10">
            <div class="bg" v-if="BaseInfoData.ColB.P2_11">
              <div class="form-group">
                <label for=""><span>*</span>計劃聯絡人姓名</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactName" data-required="1"
                  data-required-msg="請填寫計劃聯絡人姓名" aria-describedby="" placeholder="請填寫計劃聯絡人姓名">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>計劃聯絡人職稱</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactTitle"
                  data-required="1" data-required-msg="請填寫計劃聯絡人職稱" aria-describedby="" placeholder="請填寫計劃聯絡人職稱">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>聯絡電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactPhone"
                  data-required="1" data-required-msg="請填寫聯絡電話" aria-describedby="" placeholder="請填寫聯絡電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>行動電話</label>
                <input type="text" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactMobile"
                  data-required="1" data-required-msg="請填寫行動電話" aria-describedby="" placeholder="請填寫行動電話">
              </div>
              <div class="form-group">
                <label for=""><span>*</span>電子郵件</label>
                <input type="email" class="form-control" v-model="BaseInfoData.UserInfo.P2_ContactEmail"
                  data-required="1" data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              </div>
            </div>
            <hr v-if="BaseInfoData.ColB.P2_11">
            <div class="bg" v-if="BaseInfoData.ColA.P2_12">
              <div class="form-group full environment">
                <label for="">參與輔導項目</label>
                <br />
                <label for=""><span>*</span>參與輔導類別</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P2_GuidanceType" id="P2_GuidanceType1" value="1"
                      v-model="BaseInfoData.UserInfo.P2_GuidanceType">
                    <label class="form-check-label" for="P2_GuidanceType1">
                      組合型
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="P2_GuidanceType" id="P2_GuidanceType2" value="2"
                      v-model="BaseInfoData.UserInfo.P2_GuidanceType">
                    <label class="form-check-label" for="P2_GuidanceType2">
                      供應鏈型
                    </label>
                  </div>
                </div>
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P2_ProductDevGuidance"
                        v-model="BaseInfoData.UserInfo.P2_ProductDevGuidance">
                      <label class="form-check-label" for="P2_ProductDevGuidance">產品開發試銷輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P2_ProductDevGuidance">
                  <label for=""><span>*</span>預計開發之產品名稱</label>
                  <P>品項與數量須相符</P>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P2_ExpectedProductName" aria-describedby=""
                    placeholder="請填寫預計開發之產品名稱" :data-required="BaseInfoData.UserInfo.P2_ProductDevGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發之產品名稱"></textarea>
                  <label for=""><span>*</span>預計開發之產品國家/地區</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P2_ExpectedProductCountry" aria-describedby=""
                    placeholder="請填寫預計開發之產品國家/地區"
                    :data-required="BaseInfoData.UserInfo.P2_ChannelExpGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發之產品國家/地區"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P2_ChannelExpGuidance"
                        v-model="BaseInfoData.UserInfo.P2_ChannelExpGuidance">
                      <label class="form-check-label" for="P2_ChannelExpGuidance">通路拓展輔導</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P2_ChannelExpGuidance">
                  <label for=""><span>*</span>預計開發通路名稱</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P2_ExpectedChannelName" aria-describedby=""
                    placeholder="請填寫預計開發通路名稱" :data-required="BaseInfoData.UserInfo.P2_ChannelExpGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發通路名稱"></textarea>
                  <label for=""><span>*</span>預計開發通路之國家/地區</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P2_ExpectedChannelCountry" aria-describedby=""
                    placeholder="請填寫預計開發通路之國家/地區"
                    :data-required="BaseInfoData.UserInfo.P2_ChannelExpGuidance ? '1' : '0'"
                    data-required-msg="請填寫預計開發通路之國家/地區"></textarea>
                </template>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColA.P2_12">
            <div v-if="!BaseInfoData.ColBHasCol" style="text-align: center; width: 100%; margin: 10px 0;">
              <h1 style="border-bottom: 0px;">無需填寫，請點選下一步。</h1>
            </div>
            <hr v-if="!BaseInfoData.ColBHasCol">
            <div class="btn_box">
              <button @click="gotoStep(1)">上一步</button>
              <button @click="gotoStep(3)">下一步</button>
              <button @click="save_temp()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">暫　　存</button>
              <button @click="save()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
          <div id="stet_3" class="box" :class="Step == 3 ? 'show' : ''">
            <div class="bg" v-if="BaseInfoData.ColC.P3_1">
              <div class="form-group full">
                <label for=""><span>*</span>現況說明</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_CurrentStatus" aria-describedby=""
                  data-required="1" data-required-msg="請填寫現況說明" placeholder="請填寫現況說明"></textarea>
                <div class="file_box">
                  <label for="">現況說明附加檔案-1<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_CurrentStatusFile1"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P3_CurrentStatusFile1"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P3_CurrentStatusFile1_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P3_CurrentStatusFile1"
                      id="P3_CurrentStatusFile1_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
                <div class="file_box">
                  <label for="">現況說明附加檔案-2<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_CurrentStatusFile2"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P3_CurrentStatusFile2"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P3_CurrentStatusFile2_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P3_CurrentStatusFile2"
                      id="P3_CurrentStatusFile2_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
                <div class="file_box">
                  <label for="">現況說明附加檔案-3<span style="color: red;"
                      v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_CurrentStatusFile3"> (已上傳,
                      可更新)</span></label>
                  <div class="file">
                    <input type="file" id="P3_CurrentStatusFile3"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="P3_CurrentStatusFile3_name" data-namgtag="1" />
                    <input type="hidden" v-model="BaseInfoData.UserInfo.P3_CurrentStatusFile3"
                      id="P3_CurrentStatusFile3_data" />
                    <button>上傳</button>
                  </div>
                  <p>
                    上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColC.P3_1">

            <div class="bg" v-if="BaseInfoData.ColC.P3_4">
              <div class="form-group full environment">
                <label for="">申請業者面臨問題及改善需求</label>
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P3_BrandBuilding"
                        v-model="BaseInfoData.UserInfo.P3_BrandBuilding">
                      <label class="form-check-label" for="P3_BrandBuilding">品牌塑造</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P3_BrandBuilding">
                  <label for=""><span>*</span>品牌塑造問題說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_BrandBuildingIssues" aria-describedby=""
                    placeholder="請填寫品牌塑造問題說明" :data-required="BaseInfoData.UserInfo.P3_BrandBuilding ? '1' : '0'"
                    data-required-msg="請填寫品牌塑造問題說明"></textarea>
                  <div class="file_box">
                    <label for=""><span>*</span>品牌塑造問題說明附加檔案-1<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_BrandBuildingIssuesFile"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_BrandBuildingIssuesFile_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile"
                        id="P3_BrandBuildingIssuesFile_data"
                        :data-required="BaseInfoData.UserInfo.P3_BrandBuilding ? '1' : '0'"
                        data-required-msg="請上傳品牌塑造問題說明附加檔案-1" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">品牌塑造問題說明附加檔案-2<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile2">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_BrandBuildingIssuesFile2"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_BrandBuildingIssuesFile2_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile2"
                        id="P3_BrandBuildingIssuesFile2_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">品牌塑造問題說明附加檔案-3<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile3">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_BrandBuildingIssuesFile3"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_BrandBuildingIssuesFile3_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile3"
                        id="P3_BrandBuildingIssuesFile3_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <label for=""><span>*</span>品牌塑造改善需求</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_BrandBuildingImprovement" aria-describedby=""
                    placeholder="請填寫品牌塑造改善需求" :data-required="BaseInfoData.UserInfo.P3_BrandBuilding ? '1' : '0'"
                    data-required-msg="請填寫品牌塑造改善需求"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P3_EnvironmentOptimization"
                        v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimization">
                      <label class="form-check-label" for="P3_EnvironmentOptimization">環境優化</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P3_EnvironmentOptimization">
                  <label for=""><span>*</span>環境優化問題說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssues"
                    aria-describedby="" placeholder="請填寫環境優化問題說明"
                    :data-required="BaseInfoData.UserInfo.P3_EnvironmentOptimization ? '1' : '0'"
                    data-required-msg="請填寫環境優化問題說明"></textarea>
                  <div class="file_box">
                    <label for=""><span>*</span>環境優化問題說明附加檔案-1<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_EnvironmentOptimizationIssuesFile"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_EnvironmentOptimizationIssuesFile_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile"
                        id="P3_EnvironmentOptimizationIssuesFile_data"
                        :data-required="BaseInfoData.UserInfo.P3_EnvironmentOptimization ? '1' : '0'"
                        data-required-msg="請上傳環境優化問題說明附加檔案-1" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">環境優化問題說明附加檔案-2<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile2">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_EnvironmentOptimizationIssuesFile2"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_EnvironmentOptimizationIssuesFile2_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile2"
                        id="P3_EnvironmentOptimizationIssuesFile2_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">環境優化問題說明附加檔案-3<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile3">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_EnvironmentOptimizationIssuesFile3"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_EnvironmentOptimizationIssuesFile3_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile3"
                        id="P3_EnvironmentOptimizationIssuesFile3_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <label for=""><span>*</span>環境優化改善需求</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_EnvironmentOptimizationImprovement"
                    aria-describedby="" placeholder="請填寫環境優化改善需求"
                    :data-required="BaseInfoData.UserInfo.P3_EnvironmentOptimization ? '1' : '0'"
                    data-required-msg="請填寫環境優化改善需求"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P3_MultilingualMenuService"
                        v-model="BaseInfoData.UserInfo.P3_MultilingualMenuService" disabled>
                      <label class="form-check-label" for="P3_MultilingualMenuService">多語化菜單(必填)</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P3_MultilingualMenuService">
                  <label for=""><span>*</span>多語化菜單問題說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssues"
                    aria-describedby="" placeholder="請填寫多語化菜單問題說明"
                    :data-required="BaseInfoData.UserInfo.P3_MultilingualMenuService ? '1' : '0'"
                    data-required-msg="請填寫多語化菜單問題說明"></textarea>
                  <div class="file_box">
                    <label for=""><span>*</span>多語化菜單問題說明附加檔案-1<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_MultilingualMenuServiceIssuesFile"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_MultilingualMenuServiceIssuesFile_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile"
                        id="P3_MultilingualMenuServiceIssuesFile_data"
                        :data-required="BaseInfoData.UserInfo.P3_MultilingualMenuService ? '1' : '0'"
                        data-required-msg="請上傳多語化菜單問題說明附加檔案-1" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">多語化菜單問題說明附加檔案-2<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile2">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_MultilingualMenuServiceIssuesFile2"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_MultilingualMenuServiceIssuesFile2_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile2"
                        id="P3_MultilingualMenuServiceIssuesFile2_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">多語化菜單問題說明附加檔案-3<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile3">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_MultilingualMenuServiceIssuesFile3"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_MultilingualMenuServiceIssuesFile3_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile3"
                        id="P3_MultilingualMenuServiceIssuesFile3_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <label for=""><span>*</span>多語化菜單改善需求</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_MultilingualMenuServiceImprovement"
                    aria-describedby="" placeholder="請填寫多語化菜單改善需求"
                    :data-required="BaseInfoData.UserInfo.P3_MultilingualMenuService ? '1' : '0'"
                    data-required-msg="請填寫多語化菜單改善需求"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P3_SocialMediaDigitalMarketingOptimization"
                        v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimization">
                      <label class="form-check-label"
                        for="P3_SocialMediaDigitalMarketingOptimization">社群經營、數位行銷優化</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimization">
                  <label for=""><span>*</span>社群經營、數位行銷優化問題說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssues"
                    aria-describedby="" placeholder="請填寫社群經營、數位行銷優化問題說明"
                    :data-required="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimization ? '1' : '0'"
                    data-required-msg="請填寫社群經營、數位行銷優化問題說明"></textarea>
                  <div class="file_box">
                    <label for=""><span>*</span>社群經營、數位行銷優化問題說明附加檔案-1<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile_name"
                        data-namgtag="1" />
                      <input type="hidden"
                        v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile"
                        id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile_data"
                        :data-required="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimization ? '1' : '0'"
                        data-required-msg="請上傳社群經營、數位行銷優化問題說明附加檔案-1" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">社群經營、數位行銷優化問題說明附加檔案-2<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile2">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile2"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile2_name"
                        data-namgtag="1" />
                      <input type="hidden"
                        v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile2"
                        id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile2_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">社群經營、數位行銷優化問題說明附加檔案-3<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile3">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile3"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile3_name"
                        data-namgtag="1" />
                      <input type="hidden"
                        v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile3"
                        id="P3_SocialMediaDigitalMarketingOptimizationIssuesFile3_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <label for=""><span>*</span>社群經營、數位行銷優化改善需求</label>
                  <textarea rows="5"
                    v-model="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationImprovement"
                    aria-describedby="" placeholder="請填寫社群經營、數位行銷優化改善需求"
                    :data-required="BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimization ? '1' : '0'"
                    data-required-msg="請填寫社群經營、數位行銷優化改善需求"></textarea>
                </template>
              </div>
              <div class="form-group full environment">
                <div class="check_group aa box_2" style="display: block;">
                  <div class="check_group">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="P3_PrepackagedProducts"
                        v-model="BaseInfoData.UserInfo.P3_PrepackagedProducts">
                      <label class="form-check-label" for="P3_PrepackagedProducts">預製品</label>
                    </div>
                  </div>
                </div>
                <template v-if="BaseInfoData.UserInfo.P3_PrepackagedProducts">
                  <label for=""><span>*</span>預製品問題說明</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_PrepackagedProductsIssues" aria-describedby=""
                    placeholder="請填寫預製品問題說明" :data-required="BaseInfoData.UserInfo.P3_PrepackagedProducts ? '1' : '0'"
                    data-required-msg="請填寫預製品問題說明"></textarea>
                  <div class="file_box">
                    <label for=""><span>*</span>預製品問題說明附加檔案-1<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_PrepackagedProductsIssuesFile"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_PrepackagedProductsIssuesFile_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile"
                        id="P3_PrepackagedProductsIssuesFile_data"
                        :data-required="BaseInfoData.UserInfo.P3_PrepackagedProducts ? '1' : '0'"
                        data-required-msg="請上傳預製品問題說明附加檔案-1" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">預製品問題說明附加檔案-2<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile2">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_PrepackagedProductsIssuesFile2"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_PrepackagedProductsIssuesFile2_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile2"
                        id="P3_PrepackagedProductsIssuesFile2_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <div class="file_box">
                    <label for="">預製品問題說明附加檔案-3<span style="color: red;"
                        v-if="BaseInfoData.UserInfo.URIId != -1 && BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile3">
                        (已上傳,
                        可更新)</span></label>
                    <div class="file">
                      <input type="file" id="P3_PrepackagedProductsIssuesFile3"
                        accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                        @change="checkFiles">
                      <input type="hidden" id="P3_PrepackagedProductsIssuesFile3_name" data-namgtag="1" />
                      <input type="hidden" v-model="BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile3"
                        id="P3_PrepackagedProductsIssuesFile3_data" />
                      <button>上傳</button>
                    </div>
                    <p>
                      上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                    </p>
                  </div>
                  <label for=""><span>*</span>預製品改善需求</label>
                  <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_PrepackagedProductsImprovement"
                    aria-describedby="" placeholder="請填寫預製品改善需求"
                    :data-required="BaseInfoData.UserInfo.P3_PrepackagedProducts ? '1' : '0'"
                    data-required-msg="請填寫預製品改善需求"></textarea>
                </template>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColC.P3_4">

            <div class="bg" v-if="BaseInfoData.ColC.P3_2">
              <div class="form-group full">
                <label for=""><span>*</span>改善需求</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_ImprovementNeeds" aria-describedby=""
                  data-required="1" data-required-msg="請填寫改善需求" placeholder="請填寫改善需求"></textarea>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColC.P3_2">
            <div class="bg" v-if="BaseInfoData.ColC.P3_3">
              <div class="form-group full">
                <label for=""><span>*</span>預期效益</label>
                <textarea rows="5" v-model="BaseInfoData.UserInfo.P3_ExpectedBenefits" aria-describedby=""
                  data-required="1" data-required-msg="請填寫預期效益" placeholder="請填寫預期效益"></textarea>
              </div>
            </div>
            <hr v-if="BaseInfoData.ColC.P3_3">
            <div v-if="!BaseInfoData.ColCHasCol" style="text-align: center; width: 100%; margin: 10px 0;">
              <h1 style="border-bottom: 0px;">無需填寫，請點選儲存資料。</h1>
            </div>
            <hr v-if="!BaseInfoData.ColCHasCol">
            <div class="btn_box">
              <button @click="gotoStep(2)">上一步</button>
              <button @click="save_temp()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">暫　　存</button>
              <button @click="save()"
                v-if="BaseInfoData.Review.ReviewStatus == 0 || BaseInfoData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "BaseInfo",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      BaseInfoData: {},
      Step: 1,
      TotalStaff: 0,
      TotalYear: 0,
      InfieldPercent: 0,
      OutfieldPercent: 0,
      MalePercent: 0,
      FemalePercent: 0,
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/BaseInfo",
        {
          params: { EId: this.$route.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.BaseInfoData = response.data;
        this.BaseInfoData.UserInfo.P3_MultilingualMenuService = true;
        // if (this.BaseInfoData.UserInfo.P1_FoundingTime) {
        //   this.BaseInfoData.UserInfo.P1_FoundingTime = this.convertDatetimeString(
        //     this.BaseInfoData.UserInfo.P1_FoundingTime,
        //     "YYYY-MM-DD"
        //   );
        // } else {
        //   this.BaseInfoData.UserInfo.P1_FoundingTime = "";
        // }
        // if (this.BaseInfoData.UserInfo.P2_FoundingTime) {
        //   this.BaseInfoData.UserInfo.P2_FoundingTime = this.convertDatetimeString(
        //     this.BaseInfoData.UserInfo.P2_FoundingTime,
        //     "YYYY-MM-DD"
        //   );
        // } else {
        //   this.BaseInfoData.UserInfo.P2_FoundingTime = "";
        // }
        this.BaseInfoData.UserInfo.P1_SubmDate = this.BaseInfoData.P1_SubmDate;
        this.BaseInfoData.UserInfo.P1_FoundingTime = this.BaseInfoData.P1_FoundingTime;
        this.BaseInfoData.UserInfo.P2_FoundingTime = this.BaseInfoData.P2_FoundingTime;
        this.CalStaff();
        this.CalYear();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    if ($("#P1_SubmDate").attr("placeholder") != "000/00/00") {
      $("#P1_SubmDate").mask("000/00/00", { placeholder: "000/00/00" });
    }
    if ($("#P1_FoundingTime").attr("placeholder") != "000/00/00") {
      $("#P1_FoundingTime").mask("000/00/00", { placeholder: "000/00/00" });
    }
    if ($("#P2_FoundingTime").attr("placeholder") != "000/00/00") {
      $("#P2_FoundingTime").mask("000/00/00", { placeholder: "000/00/00" });
    }

    if (this.BaseInfoData.Review.ReviewStatus != 0 && this.BaseInfoData.Review.ReviewStatus != 3) {
      $("#stet_1 input").attr("disabled", "disabled").off('click');
      $("#stet_2 input").attr("disabled", "disabled").off('click');
      $("#stet_3 input").attr("disabled", "disabled").off('click');
      $("#stet_1 textarea").attr("disabled", "disabled").off('click');
      $("#stet_2 textarea").attr("disabled", "disabled").off('click');
      $("#stet_3 textarea").attr("disabled", "disabled").off('click');
    }
  },
  methods: {
    save_temp() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      var hasError = false;
      let that = this;
      $("#stet_1").find("[data-required='1']").each(function () {
        var input = $(this);
        if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 1;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 1;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (that.BaseInfoData.UserInfo.P1_HostPhone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_HostPhone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_HostMobile != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_HostMobile)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_ContactTelphone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_ContactTelphone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_ContactPhone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_ContactPhone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      $("#stet_2").find("[data-required='1']").each(function () {
        var input = $(this);
        if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 2;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 2;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
        if (that.BaseInfoData.UserInfo.P2_HostPhone != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_HostPhone)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_HostMobile != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_HostMobile)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_ContactPhone != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_ContactPhone)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_ContactMobile != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_ContactMobile)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
      });
      $("#stet_3").find("[data-required='1']").each(function () {
        var input = $(this);
        if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 3;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 3;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      // 營業時間        
      this.BaseInfoData.UserInfo.P1_WeekdayOpeningTime = this.BaseInfoData.P1_WeekdayOpeningTime_HH + ":" + this.BaseInfoData.P1_WeekdayOpeningTime_MM;
      this.BaseInfoData.UserInfo.P1_WeekdayClosingTime = this.BaseInfoData.P1_WeekdayClosingTime_HH + ":" + this.BaseInfoData.P1_WeekdayClosingTime_MM;
      this.BaseInfoData.UserInfo.P1_HolidayOpeningTime = this.BaseInfoData.P1_HolidayOpeningTime_HH + ":" + this.BaseInfoData.P1_HolidayOpeningTime_MM;
      this.BaseInfoData.UserInfo.P1_HolidayClosingTime = this.BaseInfoData.P1_HolidayClosingTime_HH + ":" + this.BaseInfoData.P1_HolidayClosingTime_MM;
      // 暫存
      this.BaseInfoData.UserInfo.IsSended = false;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/BaseInfo",
        type: "post",
        data: {
          UserInfoJson: JSON.stringify(this.BaseInfoData.UserInfo),
          AttachmentNames: AttachmentNames,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "暫存成功",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    save() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      var hasError = false;
      let that = this;
      $("#stet_1").find("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          that.Step = 1;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 1;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 1;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (that.BaseInfoData.UserInfo.P1_HostPhone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_HostPhone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_HostMobile != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_HostMobile)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_ContactTelphone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_ContactTelphone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      if (that.BaseInfoData.UserInfo.P1_ContactPhone != '' &&
        !Validate.reg_number(that.BaseInfoData.UserInfo.P1_ContactPhone)) {
        that.Step = 1;
        Swal.fire({
          heightAuto: false,
          title: "錯誤",
          text: "電話號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
        });
        return;
      }
      $("#stet_2").find("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          that.Step = 2;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 2;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 2;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
        if (that.BaseInfoData.UserInfo.P2_HostPhone != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_HostPhone)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_HostMobile != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_HostMobile)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_ContactPhone != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_ContactPhone)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
        if (that.BaseInfoData.UserInfo.P2_ContactMobile != '' &&
          !Validate.reg_number(that.BaseInfoData.UserInfo.P2_ContactMobile)) {
          that.Step = 2;
          Swal.fire({
            heightAuto: false,
            title: "錯誤",
            text: "電話號碼格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
          });
          return;
        }
      });
      $("#stet_3").find("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          that.Step = 3;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          that.Step = 3;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          that.Step = 3;
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      // 營業時間        
      this.BaseInfoData.UserInfo.P1_WeekdayOpeningTime = this.BaseInfoData.P1_WeekdayOpeningTime_HH + ":" + this.BaseInfoData.P1_WeekdayOpeningTime_MM;
      this.BaseInfoData.UserInfo.P1_WeekdayClosingTime = this.BaseInfoData.P1_WeekdayClosingTime_HH + ":" + this.BaseInfoData.P1_WeekdayClosingTime_MM;
      this.BaseInfoData.UserInfo.P1_HolidayOpeningTime = this.BaseInfoData.P1_HolidayOpeningTime_HH + ":" + this.BaseInfoData.P1_HolidayOpeningTime_MM;
      this.BaseInfoData.UserInfo.P1_HolidayClosingTime = this.BaseInfoData.P1_HolidayClosingTime_HH + ":" + this.BaseInfoData.P1_HolidayClosingTime_MM;
      // 儲存
      this.BaseInfoData.UserInfo.IsSended = true;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/BaseInfo",
        type: "post",
        data: {
          UserInfoJson: JSON.stringify(this.BaseInfoData.UserInfo),
          AttachmentNames: AttachmentNames,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            if (that.BaseInfoData.EventItems.length > 0) {
              Swal.fire({
                title: "儲存成功",
                text: "即將進入品項管理",
                icon: "success",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500,
                willClose: () => {
                  that.$router.push({
                    name: "EventItem",
                    params: { EId: that.BaseInfoData.Event.EId }
                  });
                },
              });
            } else {
              Swal.fire({
                title: "儲存成功",
                text: "即將進入應備文件",
                icon: "success",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500,
                willClose: () => {
                  that.$router.push({
                    name: "ReqDoc",
                    params: { EId: that.BaseInfoData.Event.EId }
                  });
                },
              });
            }
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    gotoStep(step) {
      this.Step = step;
      $('html,body').animate({ scrollTop: 0 }, 0);
    },
    CalStaff: function () {
      this.TotalStaff = this.BaseInfoData.UserInfo.P1_PermanentStaff + this.BaseInfoData.UserInfo.P1_PartTimeStaff
      if (this.TotalStaff > 0) {
        this.InfieldPercent = Math.round((this.BaseInfoData.UserInfo.P1_Infield / this.TotalStaff * 100) * 100) / 100;
        this.OutfieldPercent = Math.round((this.BaseInfoData.UserInfo.P1_Outfield / this.TotalStaff * 100) * 100) / 100;
        this.MalePercent = Math.round((this.BaseInfoData.UserInfo.P1_Male / this.TotalStaff * 100) * 100) / 100;
        this.FemalePercent = Math.round((this.BaseInfoData.UserInfo.P1_Female / this.TotalStaff * 100) * 100) / 100;
      } else {
        this.InfieldPercent = 0;
        this.OutfieldPercent = 0;
        this.MalePercent = 0;
        this.FemalePercent = 0;
      }
    },
    CalYear: function () {
      this.TotalYear = this.BaseInfoData.TodayYear - this.BaseInfoData.UserInfo.P1_EstablishmentYear
    },
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 104857600) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過100MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "P1_CertFile") {
              that.BaseInfoData.UserInfo.P1_CertFile = reader.result;
            } else if (id == "P1_FacadePhoto") {
              that.BaseInfoData.UserInfo.P1_FacadePhoto = reader.result;
            } else if (id == "P1_FacadePhoto2") {
              that.BaseInfoData.UserInfo.P1_FacadePhoto2 = reader.result;
            } else if (id == "P1_FacadePhoto3") {
              that.BaseInfoData.UserInfo.P1_FacadePhoto3 = reader.result;
            } else if (id == "P1_BoothSpacePhoto") {
              that.BaseInfoData.UserInfo.P1_BoothSpacePhoto = reader.result;
            } else if (id == "P1_BoothSpacePhoto2") {
              that.BaseInfoData.UserInfo.P1_BoothSpacePhoto2 = reader.result;
            } else if (id == "P1_BoothSpacePhoto3") {
              that.BaseInfoData.UserInfo.P1_BoothSpacePhoto3 = reader.result;
            } else if (id == "P1_DiningAreaPhoto") {
              that.BaseInfoData.UserInfo.P1_DiningAreaPhoto = reader.result;
            } else if (id == "P1_DiningAreaPhoto2") {
              that.BaseInfoData.UserInfo.P1_DiningAreaPhoto2 = reader.result;
            } else if (id == "P1_DiningAreaPhoto3") {
              that.BaseInfoData.UserInfo.P1_DiningAreaPhoto3 = reader.result;
            } else if (id == "P1_EnvironmentalPhoto") {
              that.BaseInfoData.UserInfo.P1_EnvironmentalPhoto = reader.result;
            } else if (id == "P1_EnvironmentalPhoto2") {
              that.BaseInfoData.UserInfo.P1_EnvironmentalPhoto2 = reader.result;
            } else if (id == "P1_EnvironmentalPhoto3") {
              that.BaseInfoData.UserInfo.P1_EnvironmentalPhoto3 = reader.result;
            } else if (id == "P1_MediaReportFile") {
              that.BaseInfoData.UserInfo.P1_MediaReportFile = reader.result;
            } else if (id == "P1_AwardRecordFile") {
              that.BaseInfoData.UserInfo.P1_AwardRecordFile = reader.result;
            } else if (id == "P1_BrandStoryFile") {
              that.BaseInfoData.UserInfo.P1_BrandStoryFile = reader.result;
            } else if (id == "P1_BrandStoryFile2") {
              that.BaseInfoData.UserInfo.P1_BrandStoryFile2 = reader.result;
            } else if (id == "P1_BrandStoryFile3") {
              that.BaseInfoData.UserInfo.P1_BrandStoryFile3 = reader.result;
            } else if (id == "P1_BrandStoryFile4") {
              that.BaseInfoData.UserInfo.P1_BrandStoryFile4 = reader.result;
            } else if (id == "P1_BrandStoryFile5") {
              that.BaseInfoData.UserInfo.P1_BrandStoryFile5 = reader.result;
            } else if (id == "P3_CurrentStatusFile1") {
              that.BaseInfoData.UserInfo.P3_CurrentStatusFile1 = reader.result;
            } else if (id == "P3_CurrentStatusFile2") {
              that.BaseInfoData.UserInfo.P3_CurrentStatusFile2 = reader.result;
            } else if (id == "P3_CurrentStatusFile3") {
              that.BaseInfoData.UserInfo.P3_CurrentStatusFile3 = reader.result;
            } else if (id == "P1_GovtPolicyComplianceProofFile") {
              that.BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile = reader.result;
            } else if (id == "P1_GovtPolicyComplianceProofFile2") {
              that.BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile2 = reader.result;
            } else if (id == "P1_GovtPolicyComplianceProofFile3") {
              that.BaseInfoData.UserInfo.P1_GovtPolicyComplianceProofFile3 = reader.result;
            } else if (id == "P3_BrandBuildingIssuesFile") {
              that.BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile = reader.result;
            } else if (id == "P3_BrandBuildingIssuesFile2") {
              that.BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile2 = reader.result;
            } else if (id == "P3_BrandBuildingIssuesFile3") {
              that.BaseInfoData.UserInfo.P3_BrandBuildingIssuesFile3 = reader.result;
            } else if (id == "P3_EnvironmentOptimizationIssuesFile") {
              that.BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile = reader.result;
            } else if (id == "P3_EnvironmentOptimizationIssuesFile2") {
              that.BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile2 = reader.result;
            } else if (id == "P3_EnvironmentOptimizationIssuesFile3") {
              that.BaseInfoData.UserInfo.P3_EnvironmentOptimizationIssuesFile3 = reader.result;
            } else if (id == "P3_MultilingualMenuServiceIssuesFile") {
              that.BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile = reader.result;
            } else if (id == "P3_MultilingualMenuServiceIssuesFile2") {
              that.BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile2 = reader.result;
            } else if (id == "P3_MultilingualMenuServiceIssuesFile3") {
              that.BaseInfoData.UserInfo.P3_MultilingualMenuServiceIssuesFile3 = reader.result;
            } else if (id == "P3_SocialMediaDigitalMarketingOptimizationIssuesFile") {
              that.BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile = reader.result;
            } else if (id == "P3_SocialMediaDigitalMarketingOptimizationIssuesFile2") {
              that.BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile2 = reader.result;
            } else if (id == "P3_SocialMediaDigitalMarketingOptimizationIssuesFile3") {
              that.BaseInfoData.UserInfo.P3_SocialMediaDigitalMarketingOptimizationIssuesFile3 = reader.result;
            } else if (id == "P3_PrepackagedProductsIssuesFile") {
              that.BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile = reader.result;
            } else if (id == "P3_PrepackagedProductsIssuesFile2") {
              that.BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile2 = reader.result;
            } else if (id == "P3_PrepackagedProductsIssuesFile3") {
              that.BaseInfoData.UserInfo.P3_PrepackagedProductsIssuesFile3 = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
