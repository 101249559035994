<template>
  <div>
    <Head />

    <div class="wrapper" v-if="HomeData">
      <section class="main">
        <div class="container">
          <ul class="btn_box">
            <li v-for="EventType in HomeData.EventTypes" :key="EventType.ETId">
              <button :class="EventType.ETId == TabIndex ? 'active' : ''" @click="TabIndex = EventType.ETId">{{
                EventType.Name }}</button>
            </li>
          </ul>
          <ul class="list_box show">
            <li v-for="Event in HomeData.Events" :key="Event.EId" v-if="Event.ETId == TabIndex">
              <a href="javascript:void(0)" @click="gotoEventByEId(Event.EId)">
                <div class="img_box">
                  <div class="img">
                    <img v-if="!Event.Image" src="img/sample-2.png" alt="">
                    <img v-if="Event.Image" :src="HomeData.SiteUrl + Event.Image" alt="">
                  </div>
                </div>
                <div class="text">
                  <h1>{{ Event.Name }}</h1>
                  <div class="content">
                    <template v-if="Event.ApplyStartTime && Event.ApplyEndTime">
                      <p>報名期間：{{ Event.ApplyStartTime | timeString("YYYY-MM-DD") }} 至 {{ Event.ApplyEndTime |
                        timeString("YYYY-MM-DD") }}</p>
                    </template>
                    <template v-if="!Event.ApplyStartTime && Event.ApplyEndTime">
                      <p>報名期間：{{ Event.ApplyEndTime | timeString("YYYY-MM-DD") }}截止</p>
                    </template>
                    <template v-if="Event.ApplyStartTime && !Event.ApplyEndTime">
                      <p>報名期間：{{ Event.ApplyStartTime | timeString("YYYY-MM-DD") }}起</p>
                    </template>
                    <template v-if="!Event.ApplyStartTime && !Event.ApplyEndTime">
                      <p>報名期間：無限制</p>
                    </template>
                    <p v-if="Event.SimpleContent">
                      {{ Event.SimpleContent }}
                    </p>
                  </div>
                  <div class="btn">MORE</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Home",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      HomeData: {},
      local: "zh_tw",
      TabIndex: 0,
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: { EId: to.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.HomeData = response.data;
        if (this.HomeData.User && this.HomeData.User.IsReviewer) {
          this.$router.push({
            name: "RatingHome",
          });
        }
        this.TabIndex = this.HomeData.DefaultETId;
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: {
            lang: $cookies.get("is_site_lang"), token: $cookies.get("is_login_token")
          },
        }
      )
      .then((response) => {
        this.HomeData = response.data;
        if (this.HomeData.User && this.HomeData.User.IsReviewer) {
          this.$router.push({
            name: "RatingHome",
          });
        }
        this.TabIndex = this.HomeData.DefaultETId;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId },
        });
      }
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoEventByEId: function (EId) {
      this.$router.push({
        name: "Event",
        params: { EId: EId },
      });
    },
  },
};
</script>

