export default {
    reg_phone(val) {
        const validate = /\d{4}\d{3}\d{3}/
        return validate.test(val)
    },
    reg_email(val) {
        const validate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return validate.test(val)
    },
    reg_number(val) {
        const validate = /\d{2,4}-?\d{3,4}-?\d{3,4}#?(\d+)?/
        return validate.test(val)
    }
}