<template>
  <div>
    <Head />

    <div class="breadcrumb_box" aria-label="breadcrumb" v-if="EventItemDetailData">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="EventItemDetailData.Event">{{ EventItemDetailData.Event.Name }}</li>
        <li class="breadcrumb-item active" v-if="EventItemDetailData.EventItem" aria-current="page">{{
          EventItemDetailData.EventItem.Name }}</li>
      </ol>
    </div>
    <div class="wrapper member_information member_information_1_main member_information_1_main_2" v-if="EventItemDetailData.EventItemDetail">
      <section class="main">
        <div class="container">
          <div class="step_box">
            <div class="" @click="goto('BaseInfo')" style="cursor: pointer;">基本資料</div>
            <div class="step">品項管理</div>
            <div class="" @click="goto('ReqDoc')" style="cursor: pointer;">應備文件</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 0" class="" @click="sendReview()" style="cursor: pointer;">送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 3" class="" @click="sendReview()" style="cursor: pointer;">重新送出審核</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="EventItemDetailData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <div id="stet_1" class="box show">
            <h1>
              拿手菜/手路菜
              <div class="btn_home">
                <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                  tag="button">返回列表</router-link>
              </div>
            </h1>
            <div class="bg">
              <div class="form-group full">
                <label for=""><span>*</span>業者名稱</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T2_VendorName"
                  data-required="1" data-required-msg="請填寫業者名稱" aria-describedby="" placeholder="請填寫業者名稱">
              </div>
              <div class="form-group full" v-if="false">
                <label for=""><span>*</span>菜餚類別</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T2_DishCategory"
                  data-required="1" data-required-msg="請填寫菜餚類別" aria-describedby="" placeholder="請填寫菜餚類別">
              </div>
              <div class="form-group project full" v-if="false">
                <label for="">是否已於通路上架販售</label>
                <div class="check_group radio radio_1">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="radio1" id="radio_1" v-bind:value="true"
                      v-model="EventItemDetailData.EventItemDetail.T2_OnShelfInOutlets">
                    <label class="form-check-label" for="radio_1">
                      是
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="radio1" id="radio_2" v-bind:value="false"
                      v-model="EventItemDetailData.EventItemDetail.T2_OnShelfInOutlets">
                    <label class="form-check-label" for="radio_2">
                      否
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for=""><span>*</span>編號</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T2_ID"
                  data-required="1" data-required-msg="請填寫編號" aria-describedby="" placeholder="請填寫編號">
              </div>
              <div class="form-group full">
                <label for=""><span>*</span>菜名</label>
                <input type="text" class="form-control" v-model="EventItemDetailData.EventItemDetail.T2_DishName"
                  data-required="1" data-required-msg="請填寫菜名" aria-describedby="" placeholder="請填寫菜名">
                <p>
                  請填寫拿手菜/手路菜菜名，該菜色可非餐廳內常備菜色，惟入選後請納入菜單供消費者可預約品嘗。
                </p>
              </div>
            </div>
            <h1>製作方法說明</h1>
            <div class="form-group full content">
              <h2 class=""><span>*</span>主食材</h2>
              <div class="form-group full">
                <label for="">內容</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_MainIngredientContent"
                  aria-describedby="" data-required="1" data-required-msg="請填寫主食材內容" placeholder="請填寫主食材內容"></textarea>
              </div>
              <div class="form-group full">
                <label for="">品牌/產地來源</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_MainIngredientBrandOrOrigin"
                  aria-describedby="" data-required="1" data-required-msg="請填寫主食材品牌/產地來源"
                  placeholder="請填寫主食材品牌/產地來源"></textarea>
              </div>
            </div>
            <hr>
            <div class="form-group full content">
              <h2 class=""><span>*</span>副材料</h2>
              <div class="form-group full">
                <label for="">內容</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_SecondaryIngredientContent"
                  aria-describedby="" data-required="1" data-required-msg="請填寫副材料內容" placeholder="請填寫副材料內容"></textarea>
              </div>
              <div class="form-group full">
                <label for="">品牌/產地來源</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_SecondaryIngredientBrandOrOrigin"
                  aria-describedby="" data-required="1" data-required-msg="請填寫副材料品牌/產地來源"
                  placeholder="請填寫副材料品牌/產地來源"></textarea>
              </div>
            </div>
            <hr>
            <div class="form-group full content">
              <h2 class=""><span>*</span>調味料</h2>
              <div class="form-group full">
                <label for="">內容</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_SeasoningContent" aria-describedby=""
                  data-required="1" data-required-msg="請填寫調味料內容" placeholder="請填寫調味料內容"></textarea>
              </div>
              <div class="form-group full">
                <label for="">品牌/產地來源</label>
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_SeasoningBrandOrOrigin"
                  aria-describedby="" data-required="1" data-required-msg="請填寫調味料品牌/產地來源"
                  placeholder="請填寫調味料品牌/產地來源"></textarea>
              </div>
            </div>
            <hr>
            <div class="form-group full content">
              <h2 class=""><span>*</span>菜餚製作手法說明</h2>
              <div class="form-group full">
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_CookingMethodDescription"
                  aria-describedby="" data-required="1" data-required-msg="請填寫菜餚製作手法說明"
                  placeholder="請填寫菜餚製作手法說明"></textarea>
              </div>
              <p>
                請簡述本道菜餚如何呈現製作手法，例如：擺盤排列、料理手法、食材處理等，100字內
              </p>
            </div>
            <div class="form-group full content">
              <!-- <h2 class=""><span>*</span>菜餚製作手法說明</h2> -->
              <h2 class=""><span>*</span>調味重點</h2>
              <div class="form-group full">
                <div class="check_group seasoning">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_SesameOilGingerFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_SesameOilGingerFlavor">
                    <label class="form-check-label" for="T2_SesameOilGingerFlavor">麻油(薑)味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_PorkOilScallionFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_PorkOilScallionFlavor">
                    <label class="form-check-label" for="T2_PorkOilScallionFlavor">豬油(蔥)味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_ChickenOilGarlicFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_ChickenOilGarlicFlavor">
                    <label class="form-check-label" for="T2_ChickenOilGarlicFlavor">雞油(蒜)味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_BraisedFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_BraisedFlavor">
                    <label class="form-check-label" for="T2_BraisedFlavor">紅燒味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_ThreeCupsFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_ThreeCupsFlavor">
                    <label class="form-check-label" for="T2_ThreeCupsFlavor">三杯味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_SweetAndSourFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_SweetAndSourFlavor">
                    <label class="form-check-label" for="T2_SweetAndSourFlavor">糖醋味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_HoneyGlazedFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_HoneyGlazedFlavor">
                    <label class="form-check-label" for="T2_HoneyGlazedFlavor">蜜汁味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_BraisedFlavor2"
                      v-model="EventItemDetailData.EventItemDetail.T2_BraisedFlavor2">
                    <label class="form-check-label" for="T2_BraisedFlavor2">滷味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_FiveSpicesFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_FiveSpicesFlavor">
                    <label class="form-check-label" for="T2_FiveSpicesFlavor">五柳味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_SourAndFreshFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_SourAndFreshFlavor">
                    <label class="form-check-label" for="T2_SourAndFreshFlavor">酸鮮味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_RedLeesFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_RedLeesFlavor">
                    <label class="form-check-label" for="T2_RedLeesFlavor">紅糟味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_RiceWineLeesFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_RiceWineLeesFlavor">
                    <label class="form-check-label" for="T2_RiceWineLeesFlavor">酒釀味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_FermentedBeanCurdFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_FermentedBeanCurdFlavor">
                    <label class="form-check-label" for="T2_FermentedBeanCurdFlavor">腐乳味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_PickledFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_PickledFlavor">
                    <label class="form-check-label" for="T2_PickledFlavor">醃漬味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_RiceWineFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_RiceWineFlavor">
                    <label class="form-check-label" for="T2_RiceWineFlavor">米酒味</label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="T2_VinegarFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_VinegarFlavor">
                    <label class="form-check-label" for="T2_VinegarFlavor">醋味</label>
                  </div>
                  <div class="form-check other_btn">
                    <input type="checkbox" class="form-check-input" id="T2_OtherFlavor"
                      v-model="EventItemDetailData.EventItemDetail.T2_OtherFlavor">
                    <label class="form-check-label" for="T2_OtherFlavor">其他</label>
                  </div>
                  <div class="other" style="display: block;" v-if="EventItemDetailData.EventItemDetail.T2_OtherFlavor">
                    <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_OtherFlavorContent"
                      aria-describedby="" :data-required="EventItemDetailData.EventItemDetail.T2_OtherFlavor ? '1' : '0'"
                      data-required-msg="請填寫其他調味重點" placeholder="請填寫其他調味重點"></textarea>
                    <p>
                      請說明本道菜餚呈現的主要味道，如：麻油薑味、紅燒味，可參酌附件8【臺灣香味】研究選取適合之味型，並補充說明，100字內
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h1>菜餚內容介紹(在地食材、料理刀工、風俗民情、調味醬汁)</h1>
            <div class="form-group full content">
              <h2 class=""><span>*</span>菜餚內容介紹(在地食材、料理刀工、風俗民情、調味醬汁)</h2>
              <div class="form-group full">
                <textarea rows="5" v-model="EventItemDetailData.EventItemDetail.T2_DishDescription" aria-describedby=""
                  data-required="1" data-required-msg="請填寫菜餚內容介紹" placeholder="請填寫菜餚內容介紹"></textarea>
              </div>
              <p>
                請簡述說明本道菜餚內容，以在地食材、刀工技法、風俗民情及調味醬汁四大面向簡述，並寫出建議應如何賞析這道菜餚，並提出是否有搭配餐酒、茶【搭配茶、酒的名稱介紹】，500字內
              </p>
            </div>
            <h1>菜餚照片</h1>
            <div class="bg">
              <div class="form-group file_box full">
                <label for=""><span>*</span>照片-1<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T2_DishPhoto">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T2_DishPhoto"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T2_DishPhoto_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T2_DishPhoto" id="T2_DishPhoto_data"
                    data-required="1" data-required-msg="請上傳照片-1" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">照片-2<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T2_DishPhoto2">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T2_DishPhoto2"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T2_DishPhoto2_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T2_DishPhoto2"
                    id="T2_DishPhoto2_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">照片-3<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T2_DishPhoto3">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T2_DishPhoto3"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T2_DishPhoto3_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T2_DishPhoto3"
                    id="T2_DishPhoto3_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">照片-4<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T2_DishPhoto4">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T2_DishPhoto4"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T2_DishPhoto4_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T2_DishPhoto4"
                    id="T2_DishPhoto4_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
              <div class="form-group file_box full">
                <label for="">照片-5<span style="font-size: 1.3rem;"
                    v-if="EventItemDetailData.EventItemDetail.EIDId != -1 && EventItemDetailData.EventItemDetail.T2_DishPhoto5">
                    (已上傳, 可更新)</span></label>
                <div class="file">
                  <input type="file" id="T2_DishPhoto5"
                    accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                    @change="checkFiles">
                  <input type="hidden" id="T2_DishPhoto5_name" data-namgtag="1" />
                  <input type="hidden" v-model="EventItemDetailData.EventItemDetail.T2_DishPhoto5"
                    id="T2_DishPhoto5_data" />
                  <button>上傳</button>
                </div>
                <p>
                  上傳檔案僅接受JPG或GIF或PNG檔，並且以100MB為限，圖片需300dpi/1000px以上
                </p>
              </div>
            </div>
            <hr>
            <div class="btn_box">
              <router-link :to="{ name: 'EventItem', params: { EId: EventItemDetailData.Event.EId } }"
                tag="button">返回列表</router-link>
              <button @click="save()" v-if="EventItemDetailData.Review.ReviewStatus == 0 || EventItemDetailData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "EventItemDetail_2",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      EIDId: -1,
      EventItemDetailData: {},
      SideDishes: [],
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    if (this.$route.params.EIDId) {
      this.EIDId = this.$route.params.EIDId;
    }
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/EventItemDetail",
        {
          params: { EId: this.$route.params.EId, EIId: this.$route.params.EIId, EIDId: this.EIDId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.EventItemDetailData = response.data;
        this.SideDishes = this.EventItemDetailData.SideDishes;
        if (!this.EventItemDetailData.UserInfo ||
          this.EventItemDetailData.UserInfo.UIId == -1) {
          Swal.fire({
            title: "請先填寫基本資料",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "BaseInfo",
                params: { EId: this.$route.params.EId },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    if (this.EventItemDetailData.Review.ReviewStatus != 0 && this.EventItemDetailData.Review.ReviewStatus != 3) {
      $("#stet_1 input").attr("disabled", "disabled").off('click');
      $("#stet_1 textarea").attr("disabled", "disabled").off('click');
    }
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 104857600) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過100MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "T2_DishPhoto") {
              that.EventItemDetailData.EventItemDetail.T2_DishPhoto = reader.result;
            } else if (id == "T2_DishPhoto2") {
              that.EventItemDetailData.EventItemDetail.T2_DishPhoto2 = reader.result;
            } else if (id == "T2_DishPhoto3") {
              that.EventItemDetailData.EventItemDetail.T2_DishPhoto3 = reader.result;
            } else if (id == "T2_DishPhoto4") {
              that.EventItemDetailData.EventItemDetail.T2_DishPhoto4 = reader.result;
            } else if (id == "T2_DishPhoto5") {
              that.EventItemDetailData.EventItemDetail.T2_DishPhoto5 = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    addSideDishe() {
      this.SideDishes.push({
        SideDisheContent: '',
        IngredientOrigin: '',
        LocalIngredientsRatio: 0,
        DomesticProductRecord: false,
        UseExtraIngredients: false,
      });
    },
    removeSideDishe() {
      if (this.SideDishes.length > 1) {
        this.SideDishes.splice(this.SideDishes.length - 1);
      }
    },
    save() {
      var hasError = false;
      $("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "number" &&
          input.val() < 0) {
          Swal.fire({
            title: "錯誤",
            text: "數字不可小於0",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      var SideDisheError = false;
      $.each(this.SideDishes, function (index, SideDishe) {
        if (SideDishe.SideDisheContent == "" ||
          SideDishe.IngredientOrigin == "") {
          SideDisheError = true;
          return;
        }
        if (SideDisheError) {
          Swal.fire({
            title: "錯誤",
            text: "請確實輸入配菜資料",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
      });
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/EventItemDetail",
        type: "post",
        data: {
          EventItemDetailJson: JSON.stringify(that.EventItemDetailData.EventItemDetail),
          AttachmentNames: AttachmentNames,
          SideDishes: JSON.stringify(that.SideDishes),
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "儲存成功",
              text: "即將返回列表",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                that.$router.push({
                  name: "EventItem",
                  params: { EId: that.EventItemDetailData.Event.EId }
                });
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>

