<template>
  <div>
    <Head v-if="ReqDocData" />

    <div class="breadcrumb_box" aria-label="breadcrumb">
      <ol class="breadcrumb container">
        <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
        <li class="breadcrumb-item" v-if="ReqDocData.Event">{{ ReqDocData.Event.Name }}</li>
        <li class="breadcrumb-item active" aria-current="page">應備文件</li>
      </ol>
    </div>
    <div class="wrapper member_information member_information_1_main member_information_1_main_2"
      v-if="ReqDocData.Review">
      <section class="main">
        <div class="container">
          <!-- <h1>2023年經濟部綠色盒餐徵選</h1> -->
          <div class="step_box">
            <div class="" @click="goto('BaseInfo')" style="cursor: pointer;">基本資料</div>
            <div class="" @click="goto('EventItem')" style="cursor: pointer;" v-if="ReqDocData.EventItems.length > 0">品項管理</div>
            <div class="step">應備文件</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 0" class="" @click="sendReview()" style="cursor: pointer;">送出審核</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 3" class="" @click="sendReview()" style="cursor: pointer;">重新送出審核</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 1" class="">審核通過</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 2" class="">審核失敗</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 99" class="">審核中</div>
            <div v-if="ReqDocData.Review.ReviewStatus == 999" class="">重審中</div>
          </div>
          <h1>
            {{ ReqDocData.Event.Name }}
            <div class="btn_home">
              <router-link to="/" tag="button">返回首頁</router-link>
            </div>
          </h1>
          <div id="stet_1" class="box show">
            <div class="bg">
              <div class="form-group full" v-if="ReqDocData.Event.GovernmentRegistrationProof">
                <div class="file_box">
                  <label for=""><span>*</span>政府立案證明文件
                    <span style="color: black;"
                      v-if="ReqDocData.Review.GovernmentRegistrationProof && ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.GovernmentRegistrationProof && ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.GovernmentRegistrationProof && ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.GovernmentRegistrationProof || ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus != 1">
                    <input type="file" id="GovernmentRegistrationProof"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="GovernmentRegistrationProof_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.GovernmentRegistrationProof"
                      id="GovernmentRegistrationProof_data"
                      :data-required="ReqDocData.Event.GovernmentRegistrationProof && ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳政府立案證明文件" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.GovernmentRegistrationProof || ReqDocData.Review.GovernmentRegistrationProof_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.BanquetMenuSelectionForm">
                <div class="file_box">
                  <label for=""><span>*</span>徵選宴席菜單表
                    <span style="color: black;"
                      v-if="ReqDocData.Review.BanquetMenuSelectionForm && ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.BanquetMenuSelectionForm && ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.BanquetMenuSelectionForm && ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.BanquetMenuSelectionForm || ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus != 1">
                    <input type="file" id="BanquetMenuSelectionForm"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="BanquetMenuSelectionForm_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.BanquetMenuSelectionForm"
                      id="BanquetMenuSelectionForm_data"
                      :data-required="ReqDocData.Event.BanquetMenuSelectionForm && ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳徵選宴席菜單表" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.BanquetMenuSelectionForm || ReqDocData.Review.BanquetMenuSelectionForm_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.RestaurantIndividualOrGroupMenu">
                <div class="file_box">
                  <label for=""><span>*</span>餐廳單人套餐或多人合菜菜單
                    <span style="color: black;"
                      v-if="ReqDocData.Review.RestaurantIndividualOrGroupMenu && ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.RestaurantIndividualOrGroupMenu && ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.RestaurantIndividualOrGroupMenu && ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.RestaurantIndividualOrGroupMenu || ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus != 1">
                    <input type="file" id="RestaurantIndividualOrGroupMenu"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="RestaurantIndividualOrGroupMenu_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.RestaurantIndividualOrGroupMenu"
                      id="RestaurantIndividualOrGroupMenu_data"
                      :data-required="ReqDocData.Event.RestaurantIndividualOrGroupMenu && ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳餐廳單人套餐或多人合菜菜單" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.RestaurantIndividualOrGroupMenu || ReqDocData.Review.RestaurantIndividualOrGroupMenu_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.PersonalDataCollectionAndConsentForm">
                <div class="file_box">
                  <label for=""><span>*</span>蒐集個人資料告知事項暨個人資料提供同意書正本
                    <span style="color: black;"
                      v-if="ReqDocData.Review.PersonalDataCollectionAndConsentForm && ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.PersonalDataCollectionAndConsentForm && ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.PersonalDataCollectionAndConsentForm && ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.PersonalDataCollectionAndConsentForm || ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus != 1">
                    <input type="file" id="PersonalDataCollectionAndConsentForm"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="PersonalDataCollectionAndConsentForm_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.PersonalDataCollectionAndConsentForm"
                      id="PersonalDataCollectionAndConsentForm_data"
                      :data-required="ReqDocData.Event.PersonalDataCollectionAndConsentForm && ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus ? '1' : '0'"
                      data-required-msg="請上傳蒐集個人資料告知事項暨個人資料提供同意書正本" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.PersonalDataCollectionAndConsentForm || ReqDocData.Review.PersonalDataCollectionAndConsentForm_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.MarketingPromotionCompliancePledge">
                <div class="file_box">
                  <label for=""><span>*</span>配合行銷推廣允諾書正本
                    <span style="color: black;"
                      v-if="ReqDocData.Review.MarketingPromotionCompliancePledge && ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.MarketingPromotionCompliancePledge && ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.MarketingPromotionCompliancePledge && ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.MarketingPromotionCompliancePledge || ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus != 1">
                    <input type="file" id="MarketingPromotionCompliancePledge"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="MarketingPromotionCompliancePledge_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.MarketingPromotionCompliancePledge"
                      id="MarketingPromotionCompliancePledge_data"
                      :data-required="ReqDocData.Event.MarketingPromotionCompliancePledge && ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳配合行銷推廣允諾書正本" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.MarketingPromotionCompliancePledge || ReqDocData.Review.MarketingPromotionCompliancePledge_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.DishPhotoCopyrightAuthorization">
                <div class="file_box">
                  <label for=""><span>*</span>菜餚照片著作授權同意書正本
                    <span style="color: black;"
                      v-if="ReqDocData.Review.DishPhotoCopyrightAuthorization && ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.DishPhotoCopyrightAuthorization && ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.DishPhotoCopyrightAuthorization && ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.DishPhotoCopyrightAuthorization || ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus != 1">
                    <input type="file" id="DishPhotoCopyrightAuthorization"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="DishPhotoCopyrightAuthorization_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.DishPhotoCopyrightAuthorization"
                      id="DishPhotoCopyrightAuthorization_data"
                      :data-required="ReqDocData.Event.DishPhotoCopyrightAuthorization && ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳菜餚照片著作授權同意書正本" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.DishPhotoCopyrightAuthorization || ReqDocData.Review.DishPhotoCopyrightAuthorization_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.CounselingPlanDocument">
                <div class="file_box">
                  <label for=""><span>*</span>輔導計畫書一份
                    <span style="color: black;"
                      v-if="ReqDocData.Review.CounselingPlanDocument && ReqDocData.Review.CounselingPlanDocument_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.CounselingPlanDocument && ReqDocData.Review.CounselingPlanDocument_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.CounselingPlanDocument && ReqDocData.Review.CounselingPlanDocument_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.CounselingPlanDocument || ReqDocData.Review.CounselingPlanDocument_ReviewStatus != 1">
                    <input type="file" id="CounselingPlanDocument"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="CounselingPlanDocument_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.CounselingPlanDocument"
                      id="CounselingPlanDocument_data"
                      :data-required="ReqDocData.Event.CounselingPlanDocument && ReqDocData.Review.CounselingPlanDocument_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳輔導計畫書一份" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.CounselingPlanDocument || ReqDocData.Review.CounselingPlanDocument_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.PromiseDoc">
                <div class="file_box">
                  <label for=""><span>*</span>申請表正本
                    <span style="color: black;"
                      v-if="ReqDocData.Review.PromiseDoc && ReqDocData.Review.PromiseDoc_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.PromiseDoc && ReqDocData.Review.PromiseDoc_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.PromiseDoc && ReqDocData.Review.PromiseDoc_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.PromiseDoc || ReqDocData.Review.PromiseDoc_ReviewStatus != 1">
                    <input type="file" id="PromiseDoc"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="PromiseDoc_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.PromiseDoc"
                      id="PromiseDoc_data"
                      :data-required="ReqDocData.Event.PromiseDoc && ReqDocData.Review.PromiseDoc_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳申請表正本" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.PromiseDoc || ReqDocData.Review.PromiseDoc_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.PrdLiabilityIns">
                <div class="file_box">
                  <label for=""><span>*</span>產品責任險
                    <span style="color: black;"
                      v-if="ReqDocData.Review.PrdLiabilityIns && ReqDocData.Review.PrdLiabilityIns_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.PrdLiabilityIns && ReqDocData.Review.PrdLiabilityIns_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.PrdLiabilityIns && ReqDocData.Review.PrdLiabilityIns_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.PrdLiabilityIns || ReqDocData.Review.PrdLiabilityIns_ReviewStatus != 1">
                    <input type="file" id="PrdLiabilityIns"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="PrdLiabilityIns_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.PrdLiabilityIns"
                      id="PrdLiabilityIns_data"
                      :data-required="ReqDocData.Event.PrdLiabilityIns && ReqDocData.Review.PrdLiabilityIns_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳產品責任險" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.PrdLiabilityIns || ReqDocData.Review.PrdLiabilityIns_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
              <div class="form-group full" v-if="ReqDocData.Event.ChefInfo">
                <div class="file_box">
                  <label for=""><span>*</span>主廚/廚藝總監資料
                    <span style="color: black;"
                      v-if="ReqDocData.Review.ChefInfo && ReqDocData.Review.ChefInfo_ReviewStatus == 0">(已上傳，審核中)</span>
                    <span style="color: blue;"
                      v-if="ReqDocData.Review.ChefInfo && ReqDocData.Review.ChefInfo_ReviewStatus == 1">(已上傳，符合)</span>
                    <span style="color: red;"
                      v-if="ReqDocData.Review.ChefInfo && ReqDocData.Review.ChefInfo_ReviewStatus == 2">(已上傳，不符合，請重新上傳)</span>
                  </label>
                  <div class="file" v-if="!ReqDocData.Review.ChefInfo || ReqDocData.Review.ChefInfo_ReviewStatus != 1">
                    <input type="file" id="ChefInfo"
                      accept=".zip,.rar,.7z,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                      @change="checkFiles">
                    <input type="hidden" id="ChefInfo_name" data-namgtag="1" />
                    <input type="hidden" v-model="ReqDocData.Review.ChefInfo"
                      id="ChefInfo_data"
                      :data-required="ReqDocData.Event.ChefInfo && ReqDocData.Review.ChefInfo_ReviewStatus != 1 ? '1' : '0'"
                      data-required-msg="請上傳主廚/廚藝總監資料" />
                    <button>上傳</button>
                  </div>
                  <p v-if="!ReqDocData.Review.ChefInfo || ReqDocData.Review.ChefInfo_ReviewStatus != 1">
                    上傳檔案僅接受JPG、GIF、PNG或PDF檔，並且以100MB為限，圖片需300dpi/1000px以上
                  </p>
                </div>
              </div>
            </div>
            <hr>
            <div class="btn_box">
              <button @click="save()"
                v-if="ReqDocData.Review.ReviewStatus == 0 || ReqDocData.Review.ReviewStatus == 3">儲存資料</button>
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ReqDoc",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      ReqDocData: {},
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "LoginForUser",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/ReqDoc",
        {
          params: { EId: this.$route.params.EId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.ReqDocData = response.data;
        if (!this.ReqDocData.UserInfo ||
          this.ReqDocData.UserInfo.UIId == -1) {
          Swal.fire({
            title: "請先填寫基本資料",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "BaseInfo",
                params: { EId: this.$route.params.EId },
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    if (this.ReqDocData.Review.ReviewStatus != 0 && this.ReqDocData.Review.ReviewStatus != 3) {
      $("#stet_1 input").attr("disabled", "disabled").off('click');
      $("#stet_1 textarea").attr("disabled", "disabled").off('click');
    }
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 104857600) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過100MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "GovernmentRegistrationProof") {
              that.ReqDocData.Review.GovernmentRegistrationProof = reader.result;
            } else if (id == "BanquetMenuSelectionForm") {
              that.ReqDocData.Review.BanquetMenuSelectionForm = reader.result;
            } else if (id == "RestaurantIndividualOrGroupMenu") {
              that.ReqDocData.Review.RestaurantIndividualOrGroupMenu = reader.result;
            } else if (id == "PersonalDataCollectionAndConsentForm") {
              that.ReqDocData.Review.PersonalDataCollectionAndConsentForm = reader.result;
            } else if (id == "MarketingPromotionCompliancePledge") {
              that.ReqDocData.Review.MarketingPromotionCompliancePledge = reader.result;
            } else if (id == "DishPhotoCopyrightAuthorization") {
              that.ReqDocData.Review.DishPhotoCopyrightAuthorization = reader.result;
            } else if (id == "CounselingPlanDocument") {
              that.ReqDocData.Review.CounselingPlanDocument = reader.result;
            } else if (id == "PromiseDoc") {
              that.ReqDocData.Review.PromiseDoc = reader.result;
            } else if (id == "PrdLiabilityIns") {
              that.ReqDocData.Review.PrdLiabilityIns = reader.result;
            } else if (id == "ChefInfo") {
              that.ReqDocData.Review.ChefInfo = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
    save() {
      var hasError = false;
      $("[data-required='1']").each(function () {
        var input = $(this);
        if (!input.val() || input.val() == '-1') {
          input.focus();
          Swal.fire({
            title: "錯誤",
            text: input.data("required-msg"),
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        } else if (input.attr("type") == "email" &&
          !Validate.reg_email(input.val())) {
          Swal.fire({
            title: "錯誤",
            text: "Email格式錯誤",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "{";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 1) { AttachmentNames += ", "; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ReqDoc",
        type: "post",
        data: {
          ReviewJson: JSON.stringify(that.ReqDocData.Review),
          AttachmentNames: AttachmentNames,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "儲存成功",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                // that.$router.push({
                //   name: "EventItem",
                //   params: { EId: that.ReqDocData.Event.EId }
                // });                
                location.reload();
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    goto(pageName) {
      this.$router.push({
        name: pageName,
        params: { EId: this.$route.params.EId },
      });
    },
    sendReview() {
      let that = this;
      Swal.fire({
        heightAuto: false,
        title: "請再次確認",
        text: "送出審核後將無法再進行修改，是否確認送出?",
        icon: "info",
        allowOutsideClick: false,
        confirmButtonText: "是",
        showCancelButton: true,
        cancelButtonText: "否",
      })
        .then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "請稍候...",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              willOpen: function () {
                Swal.showLoading();
              },
            });
            $.ajax({
              url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/SendReview",
              type: "post",
              data: {
                EId: this.$route.params.EId,
                token: $cookies.get("is_login_token")
              },
              dataType: "json",
              error: function () { },
              success: function (res) {
                if (res.ok) {
                  Swal.fire({
                    title: "送出成功",
                    icon: "success",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    willClose: () => {
                      location.reload();
                    },
                  });
                } else if (res.error) {
                  Swal.fire({
                    title: "錯誤",
                    text: res.error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                  });
                }
              }
            });
          }
        });
    }
  },
};
</script>
