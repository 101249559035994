import CryptoJS from 'crypto-js'

export default {
    // 加密
    encrypt(strOrignText) {
        var key = CryptoJS.enc.Utf8.parse('e92fb457d4374941');
        var iv = CryptoJS.enc.Utf8.parse('5fd403b9c0c04329');
        var encrypted = CryptoJS.AES.encrypt(strOrignText, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
    // 解密
    decrypt(strEncryptText) {
        var key = CryptoJS.enc.Utf8.parse('e92fb457d4374941');
        var iv = CryptoJS.enc.Utf8.parse('5fd403b9c0c04329');
        var decrypted = CryptoJS.AES.decrypt(strEncryptText, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    },
    getJWT(payload, key) {
        const header = JSON.stringify({
            alg: "HS256",
            typ: "JWT",
        });
        const headerBase64 = Buffer.from(header)
            .toString("base64")
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
        const payloadBase64 = Buffer.from(payload)
            .toString("base64")
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
        const signature = CryptoJS.HmacSHA256(headerBase64 + "." + payloadBase64, key).toString();
        const signatureBase64 = Buffer.from(signature, 'hex')
            .toString("base64")
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
        return headerBase64 + '.' + payloadBase64 + '.' + signatureBase64;
    }
}